import numeral from 'numeral';
import _ from 'lodash';
import '../../theme/variables.scss';
import { ENGAGEMENT_VALUE_LABELS, USER_SCORE_THRESHOLD_VALUES } from '../constants/constants';
import { ChipProps, ThresholdMapping, UserType } from '../types/types';
import classNames from 'classnames';
import moment from 'moment';

export type SvgReact = React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
>;

export const mapThreshold = (value: number): ThresholdMapping => {
    if (value <= 70) {
        return { label: 'Critical Risk', color: 'secondary-accent-red-2' };
    } else if (value <= 80) {
        return { label: 'High Risk', color: 'secondary-accent-orange-1' };
    } else if (value <= 90) {
        return { label: 'Medium Risk', color: 'secondary-accent-yellow-1' };
    } else {
        return { label: 'Low Risk', color: 'secondary-accent-green-1' };
    }
};

export const dateCalculation = (startDate: Date, currentDate: Date, dateType: 'short' | 'full' = 'full') => {
    let months = 0;
    months = (currentDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += currentDate.getMonth();
    months = months <= 0 ? 0 : months;

    if (dateType === 'short') {
        return months > 11 ? `${Math.floor(months / 12)}y` : `${months}m`;
    }

    const years = Math.floor(months / 12);

    if (years === 1) {
        return `${years} Year`;
    } else if (years > 1) {
        return `${years} Years`;
    } else {
        if (months > 1) {
            return `${months} Months`;
        } else {
            return `${months} Month`;
        }
    }
};

export const concatName = (firstName: string, lastName: string) => {
    return firstName + ' ' + lastName;
};

export const getColor = (score?: number) => {
    const scoreNumber = Number(score);
    if (scoreNumber <= USER_SCORE_THRESHOLD_VALUES.CRITICAL) {
        return '#DC3B3B';
    } else if (scoreNumber <= USER_SCORE_THRESHOLD_VALUES.HIGH) {
        return '#FF912C';
    } else if (scoreNumber <= USER_SCORE_THRESHOLD_VALUES.MEDIUM) {
        return '#F1DF3A';
    } else {
        return '#A1D730';
    }
};

export const chipRiskClassMap = (score: number) => {
    const variantClassName = classNames('d-flex', 'align-items-center', 'justify-content-center', {
        'chip__user-score-critical': score <= USER_SCORE_THRESHOLD_VALUES.CRITICAL,
        'chip__user-score-high':
            score > USER_SCORE_THRESHOLD_VALUES.CRITICAL && score <= USER_SCORE_THRESHOLD_VALUES.HIGH,
        'chip__user-score-medium':
            score > USER_SCORE_THRESHOLD_VALUES.HIGH && score <= USER_SCORE_THRESHOLD_VALUES.MEDIUM,
        'chip__user-score-low-risk': score > USER_SCORE_THRESHOLD_VALUES.MEDIUM,
        body2: true,
    });
    return variantClassName;
};

export const chipStatusClassMap = (status: string) => {
    const variantClassName = classNames('d-flex', 'align-items-center', 'justify-content-center', 'chip__status', {
        'chip__status-active': status === 'ACTIVE',
        'chip__status-draft': status !== 'ACTIVE',
    });

    return variantClassName;
};

export const severityMap = (severity: string) => {
    return classNames('d-flex', 'align-items-center', 'justify-content-center', 'text-uppercase caption4', {
        'chip__severity-critical': severity === 'CRITICAL',
        'chip__severity-high': severity === 'HIGH',
        'chip__severity-medium': severity === 'MEDIUM',
        'chip__severity-low': severity === 'LOW',
    });
};

const DATETIME_FORMAT = 'MM-DD-YYYY, HH:mm A';

export const DateTimeFormatter = (dateString: string): string => moment(dateString).format(DATETIME_FORMAT);
export const severityMapForResolvedIssue = (severity: string) => {
    const variantClassName = classNames('d-flex', 'align-items-center', 'justify-content-center', 'text-uppercase', {
        'chip__severity-resolved-critical': severity === 'CRITICAL',
        'chip__severity-resolved-high': severity === 'HIGH',
        'chip__severity-resolved-medium': severity === 'MEDIUM',
        'chip__severity-resolved-low': severity === 'LOW',
        caption4: true,
    });

    return variantClassName;
};

export const getColumnValues = <T, K extends keyof T>(data: T[], key: K): T[K][] => {
    const response = data.map((item) => item[key]);
    return _.uniq(response);
};

export const getTotalArrayLength = (selectedOptions: { [key: string]: string[] }) => {
    const length: number = _.reduce(
        selectedOptions,
        (sum, valueArray) => {
            const valueArrayLength = valueArray.length;
            return sum + valueArrayLength;
        },
        0,
    );
    return length;
};

export const opacityClassName = (row: UserType) =>
    classNames({
        'opacity-transparent': row.status === 'CLOSED',
        'opacity-1': row.status !== 'CLOSED',
    });

export const activeTabClassName = (isTrue: boolean) => classNames({ 'active-tab': isTrue });

export const getInitials = (name: string, height?: string, width?: string) => {
    const names = name.split(' ');
    let initials = '';

    if (height && width && parseInt(height) > 24 && parseInt(width) > 24) {
        if (names.length > 0) {
            initials += names[0].charAt(0);
        }

        if (names.length > 1) {
            initials += names[names.length - 1].charAt(0);
        }
    } else {
        initials += name.charAt(0);
    }

    return initials.toUpperCase();
};

export const getVariantClassName = (variant: ChipProps['variant'], value: string) => {
    switch (variant) {
        case 'user_score': {
            const score = parseInt(value);
            return chipRiskClassMap(score);
        }
        case 'top_risk_issue':
            return 'align-items-center justify-content-center chip__top-risk-issue-variant overline1';
        case 'severity':
            return severityMap(value);
        case 'employee_risk_variant':
            return 'd-flex align-items-center justify-content-center chip__employee-risk body2';
        case 'status':
            return chipStatusClassMap(value);
        default:
            return '';
    }
};
export const formatValue = (label: string, value: number): string => {
    // Format Time
    if (label === ENGAGEMENT_VALUE_LABELS[2]) {
        return `${value} hrs`;
    }

    // Format Dollars
    if (label === ENGAGEMENT_VALUE_LABELS[3]) {
        return numeral(value).format('$0.0a');
    }

    // Format other values
    return numeral(value).format('0.0a');
};

export const calculateNeedleRotation = (value: number) => {
    const inputMin = 0;
    const inputMax = 100;
    const rotationMin = -70.6;
    const rotationMax = 23.8;

    let rotation = ((value - inputMin) / (inputMax - inputMin)) * (rotationMax - rotationMin) + rotationMin;

    switch (true) {
        case value >= 0 && value <= 10:
            const scaledSpeed1 = 23.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed1;
            break;
        case value > 10 && value <= 20:
            const scaledSpeed2 = 29.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed2;
            break;
        case value > 20 && value <= 30:
            const scaledSpeed3 = 35.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed3;
            break;
        case value > 30 && value <= 40:
            const scaledSpeed4 = 38.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed4;
            break;
        case value > 40 && value <= 50:
            const scaledSpeed5 = 45.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed5;
            break;
        case value > 50 && value <= 55:
            const scaledSpeed6 = 50.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed6;
            break;
        case value > 55 && value <= 60:
            const scaledSpeed7 = 53.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed7;
            break;
        case value > 60 && value <= 65:
            const scaledSpeed8 = 58.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed8;
            break;
        case value > 65 && value <= 70:
            const scaledSpeed9 = 62.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed9;
            break;
        case value > 70 && value <= 75:
            const scaledSpeed10 = 67.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed10;
            break;
        case value > 75 && value <= 80:
            const scaledSpeed11 = 72.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed11;
            break;
        case value > 80 && value <= 85:
            const scaledSpeed12 = 76.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed12;
            break;
        case value > 85 && value <= 90:
            const scaledSpeed13 = 82.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed13;
            break;
        case value > 90 && value <= 95:
            const scaledSpeed14 = 87.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed14;
            break;
        case value > 95 && value <= 100:
            const scaledSpeed15 = 93.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed15;
            break;
    }

    rotation = Math.max(rotationMin, Math.min(rotation, rotationMax));

    return rotation;
};

export const calculateUserMetricsPosition = (value: number) => {
    let cx = 0;
    let cy = 0;

    switch (true) {
        case value >= 0 && value <= 5:
            cx = 29.5;
            cy = 97.5;
            break;
        case value >= 6 && value <= 10:
            cx = 32.5;
            cy = 96.5;
            break;
        case value >= 11 && value <= 14:
            cx = 32.5 + (value - 10);
            cy = 96.5 - (value - 10);
            break;
        case value >= 15 && value <= 19:
            cx = 36.5 + (value - 15);
            cy = 92.5 - (value - 15);
            break;
        case value >= 20 && value <= 25:
            cx = 40.5 + (value - 20);
            cy = 89.5 - (value - 20);
            break;
        case value >= 26 && value <= 29:
            cx = 45.5 + (value - 25);
            cy = 85.5 - (value - 25);
            break;
        case value >= 30 && value <= 34:
            cx = 53.5 + (value - 30);
            cy = 79.5 - (value - 30);
            break;
        case value >= 35 && value <= 39:
            cx = 61.5 + (value - 35);
            cy = 74.5 - (value - 35);
            break;
        case value >= 40 && value <= 44:
            cx = 71.5 + (value - 40) * 2;
            cy = 67.5 - (value - 40);
            break;
        case value >= 45 && value <= 49:
            cx = 84.5 + (value - 45) * 2;
            cy = 60.5 - (value - 45);
            break;
        case value >= 50 && value <= 54:
            cx = 97.5 + (value - 50) * 2;
            cy = 54.5 - (value - 50);
            break;
        case value >= 55 && value <= 59:
            cx = 113.5 + (value - 55) * 2;
            cy = 48.5 - (value - 55);
            break;
        case value >= 60 && value <= 64:
            cx = 131.5 + (value - 60) * 4;
            cy = 43.5 - (value - 60);
            break;
        case value >= 65 && value <= 69:
            cx = 152.5 + (value - 65) * 4;
            cy = 39.5 - (value - 65);
            break;
        case value >= 70 && value <= 74:
            cx = 175.5 + (value - 70) * 4;
            cy = 35.5;
            break;
        case value >= 75 && value <= 79:
            cx = 200.5 + (value - 75) * 4;
            cy = 36.5;
            break;
        case value >= 80 && value <= 84:
            cx = 226.5 + (value - 80) * 4;
            cy = 39.5 + (value - 80);
            break;
        case value >= 85 && value <= 89:
            cx = 255.5 + (value - 85) * 5;
            cy = 48.5 + (value - 85) * 2;
            break;
        case value >= 90 && value <= 94:
            cx = 286.5 + (value - 90) * 5;
            cy = 60.5 + (value - 90) * 3;
            break;
        case value >= 95 && value <= 100:
            cx = 315.5 + (value - 95) * 5;
            cy = 77.5 - (value - 95) * -4;
            break;
    }

    return { cx, cy };
};

// Ensure the value is rounded to the nearest integer and between 0 and 100 using Math.min and Math.max
export const capScoreValue = (value: number) => {
    return Math.min(Math.max(Math.round(value), 0), 100);
};

export const escapeRegexCharacters = (query: string) => {
    return query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const isValidEmail = (emailInput: string) => {
    const emailList = emailInput.split(',');
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailList.every((email) => regex.test(email.trim()));
};
