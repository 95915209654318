import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import { Button, Card } from 'react-bootstrap';
import { INTEGRATEDTOOLHEADERS } from '../../../core-utils/constants/constants';
import Typography from '../../atoms/Typography';
import './styles.scss';

interface IntegratedVendorCardProps {
    imageUrl: string;
    title: string;
    tool: string;
    handleCard: (_title: string) => void;
    variant: 'active' | 'disabled' | 'error' | 'sync';
}

const IntegratedVendorCard = ({ imageUrl, title, tool, handleCard, variant }: IntegratedVendorCardProps) => {
    const isActive = variant === 'active';
    const isDisabled = variant === 'disabled';
    const isError = variant === 'error';
    const isSync = variant === 'sync';

    const cardHeaderClasses = classnames('integrated-card__card-header', {
        'bg-secondary-accent-green-6': isActive,
        'bg-secondary-accent-orange-3': isDisabled,
        'bg-secondary-accent-red-4': isError,
        'bg-vizualization-yellow-200': isSync,
        'text-center': tool,
        'text-uppercase': tool,
    });

    const cardBodyClasses = classnames('integrated-card__card-body mt-4', {
        'd-flex': tool,
        'flex-column': tool,
        'align-items-center': tool,
    });

    return (
        <Card
            className={classnames('integrated-card__card-integrated bg-text-black rounded', {
                'border-secondary-accent-green-6': isActive,
                'border-secondary-accent-orange-3': isDisabled,
                'border-secondary-accent-red-4': isError,
                'border-vizualization-yellow-200': isSync,
            })}
        >
            <div className={classnames(cardHeaderClasses, { 'integrated-card--disabled': isDisabled })}>
                <Typography variant="body8" color="text-high-emphasis">
                    {title}
                </Typography>
            </div>
            <div className={cardBodyClasses}>
                <ReactSVG src={imageUrl} className="d-flex align-items-center justify-content-center" />
                <Typography variant="subtitle5" color="text-high-emphasis">
                    {tool}
                </Typography>
                <Button
                    onClick={() => handleCard(title)}
                    className="text-center text-uppercase border-text-high-emphasis mt-2 rounded"
                >
                    <Typography variant="subtitle6" color="text-high-emphasis">
                        {INTEGRATEDTOOLHEADERS[2]}
                    </Typography>
                </Button>
            </div>
        </Card>
    );
};

export default IntegratedVendorCard;
