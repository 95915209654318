import { USER_SECURITY_HEALTH } from '../../../core-utils/constants/constants';
import CustomCard from '../../molecules/CustomCard';
import IconWithTypography from '../../molecules/IconWithTypography';
import Speedometer from '../../molecules/Speedometer';

interface UserSecurityHealthCardProps {
    criticalRisk: number;
    orgScore: number;
    deptScore: number;
}

const UserSecurityHealthCard = ({ criticalRisk, orgScore, deptScore }: UserSecurityHealthCardProps) => {
    return (
        <div className="h-100">
            <CustomCard
                cardType="oneThird"
                title={<IconWithTypography isRow={true} label={USER_SECURITY_HEALTH} labelColor="primary" />}
                content={
                    <Speedometer
                        value={Math.round(criticalRisk)}
                        variant="user"
                        orgValue={Math.round(orgScore)}
                        deptValue={Math.round(deptScore)}
                    />
                }
            />
        </div>
    );
};

export default UserSecurityHealthCard;
