import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import classnames from 'classnames';
import ToastBody from './ToastBody';
import './styles.scss';

const ToastMessage = () => {
    const [showToast, setShowToast] = useState(true);

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const toastBodyClasses = classnames(
        'toast-message__toast-body bg-secondary-accent-grey-1 border border-structural-color-chip-BG rounded-2 overflow-x-hidden',
        {
            'custom-class': showToast,
        },
    );

    return (
        <div className="toast-message" data-testid="toast-message">
            <Toast className="w-100" show={showToast} onClose={handleCloseToast}>
                <Toast.Body className={toastBodyClasses}>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <ToastBody />
                        <AiOutlineClose onClick={handleCloseToast} />
                    </div>
                </Toast.Body>
            </Toast>
        </div>
    );
};

export default ToastMessage;
