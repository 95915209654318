import React from 'react';
import { Container } from 'react-bootstrap';
import Typography from '../../atoms/Typography';
import { Color } from '../../../core-utils/types/color';
import { TypographyVariant } from '../../../core-utils/types/Variant';
import './styles.scss';

interface HealthMetricsProps {
    metric: {
        header?: React.ReactNode;
        label: string;
        labelColor: Color;
        labelVariant?: TypographyVariant;
        value?: React.ReactNode;
        valueVariant?: 'body1' | 'caption2' | 'overline';
        valueColor?: Color;
    };
}

const HealthMetrics = ({ metric }: HealthMetricsProps) => {
    const { header, label, labelColor, labelVariant = 'h3', value, valueVariant = 'body1', valueColor } = metric;

    const renderHeader = () => {
        if (typeof header === 'string' && header) {
            return (
                <Typography variant="body1" color="primary-100">
                    <span className="text-uppercase">{header}</span>
                </Typography>
            );
        }
        return header || null;
    };

    const renderValue = () => {
        if (typeof value === 'string' && value) {
            return (
                <Typography variant={valueVariant} color={valueColor}>
                    <span className="text-uppercase">{value}</span>
                </Typography>
            );
        }
        return value || null;
    };

    return (
        <div className="health-metrics" data-testid="health-metrics">
            <Container className="health-metrics__styled-container">
                {renderHeader()}
                <Typography variant={labelVariant} color={labelColor}>
                    {label}
                </Typography>
                {renderValue()}
            </Container>
        </div>
    );
};

export default HealthMetrics;
