import { createModel } from '@rematch/core';
import type { RootModel } from './models';

const initialState = {
    token: null,
    user: {
        id: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        firstName: null,
        lastName: null,
        status: null,
        email: null,
        pictureUrl: null,
        role: null,
        group: [],
        entitlement: [],
    },
};

export const auth = createModel<RootModel>()({
    state: initialState,
    reducers: {
        resetAuth(state) {
            return { ...state, ...initialState };
        },
        setAuthToken(state, payload) {
            return { ...state, token: payload };
        },
        setUser(state, payload) {
            return { ...state, user: payload };
        },
    },
});

export default auth;
