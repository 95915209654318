import './styles.scss';
import classNames from 'classnames';

interface StatusBarProps {
    percentage: number;
}

const ProgressBar = ({ percentage }: StatusBarProps) => {
    const totalBars = 10;
    const filledBars = Math.round((percentage / 100) * totalBars);

    const renderBars = () => {
        const bars = [];
        for (let i = 0; i < totalBars; i++) {
            const isFilled = i < filledBars;
            const className = classNames({
                'status-bars__fill-color': isFilled,
                'status-bars__no-color': !isFilled,
            });

            bars.push(<div key={i} className={className} />);
        }
        return bars;
    };

    return <div className="d-flex justify-content-center status-bars">{renderBars()}</div>;
};

export default ProgressBar;
