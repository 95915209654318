import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { INVITE, INVITE_USER } from '../../../core-utils/constants/constants';
import './styles.scss';
import InviteUser from '../../organisms/InviteUserModal/index';

const InviteSubHeaderContent = () => {
    const [show, setShow] = useState<boolean>(false);
    const handleClick = () => {
        setShow(!show);
    };
    const handleClose = () => {
        setShow(false);
    };
    return (
        <div>
            <Button
                variant="primary"
                className="text-uppercase body1 bg-primary-500 text-structural-stroke-500 settings-page__invite-button px-3 py-2"
                onClick={handleClick}
            >
                {INVITE}
            </Button>
            <InviteUser
                handleClose={handleClose}
                show={show}
                invitedUserList={INVITE_USER}
                addUser={() => {}}
                revokeInvitation={() => {}}
            />
        </div>
    );
};

export default InviteSubHeaderContent;
