import { Form } from 'react-bootstrap';
import Typography from '../../atoms/Typography';
import './styles.scss';

interface ToggleTextPanelProps {
    header: string;
    subText?: string;
    onToggle: (_event: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
}

const ToggleTextPanel = (props: ToggleTextPanelProps) => {
    return (
        <div className="toggle-switch">
            <div className="d-flex justify-content-between">
                <Typography variant="body2" color="text-high-emphasis">
                    {props.header}
                </Typography>
                <Form.Check type="switch" checked={props.checked} onChange={props.onToggle} />
            </div>
            <Typography variant="body3" color="text-medium-emphasis">
                {props.subText}
            </Typography>
        </div>
    );
};

export default ToggleTextPanel;
