import ReactApexChart from 'react-apexcharts';
import { UserLineGraphData } from '../../../core-utils/constants/charts';
import './styles.scss';

interface Series {
    name?: string;
    data: Array<number | never>;
}

export interface UserLineGraphProps {
    series: Series[];
}

const UserLineGraph: React.FC<UserLineGraphProps> = (props: UserLineGraphProps) => {
    const chartData = UserLineGraphData(props);

    return (
        <div id="line-chart" className="linegraph">
            <ReactApexChart options={chartData.options} series={chartData.series} type="line" width="109%" />
        </div>
    );
};

export default UserLineGraph;
