import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import Typography from '../../atoms/Typography';
import SearchBar from '../../molecules/SearchBar';
import InviteUserDetails from '../../molecules/InviteUserDetails';
import { InvitedUserType } from '../../../core-utils/types/types';
import { INVITE, EMAIL } from '../../../core-utils/constants/constants';
import { isValidEmail } from '../../../core-utils/helpers/helpers';
import '../../../theme/scroll.scss';
import './styles.scss';

interface InviteUserModalProps {
    show: boolean;
    invitedUserList: InvitedUserType[];
    handleClose: () => void;
    addUser: (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    revokeInvitation: (_event: React.MouseEvent<SVGElement, MouseEvent>) => void;
}

const InviteUserModal = ({ show, invitedUserList, handleClose, addUser, revokeInvitation }: InviteUserModalProps) => {
    const [user, setUser] = useState('');

    const handleUser = (invitedUser: string) => {
        setUser(invitedUser);
    };

    return (
        <div data-testid="invite-users">
            <Modal show={show} centered className="invite-users w-100" onHide={handleClose}>
                <Modal.Header className="text-text-high-emphasis">
                    <Modal.Title className="subtitle1">{INVITE}</Modal.Title>
                    <IoClose className="invite-users__icon" size={20} onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column gap-4">
                        <div className="d-flex flex-row justify-content-between">
                            <SearchBar
                                value={user}
                                handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    handleUser(event.target.value)
                                }
                                placeholder={EMAIL}
                            />
                            <Button className="btn" onClick={addUser} disabled={!isValidEmail(user)}>
                                <Typography variant="body1" color="text-black">
                                    {INVITE}
                                </Typography>
                            </Button>
                        </div>
                        <div className="invite-users__user-details d-flex flex-column custom-scrollbar">
                            {invitedUserList.map((user: InvitedUserType) => (
                                <InviteUserDetails
                                    key={user.id}
                                    firstName={user.firstName}
                                    lastName={user.lastName}
                                    email={user.email}
                                    imageUrl={user.imageUrl}
                                    handleUser={revokeInvitation}
                                />
                            ))}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default InviteUserModal;
