import './styles.scss';
import { ReactSVG } from 'react-svg';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Typography from '../../atoms/Typography';
import {
    DASHBOARD_SIGN_IN,
    GET_IN_TOUCH,
    SIGN_IN_ERROR_TEXT,
    SignInButtonsMaps,
    SignInInfoText,
    TRY_AGAIN,
} from '../../../core-utils/constants/constants';
import AmplifierSignInLogo from '../../../../public/assets/icons/AmplifierSignInLogo.svg';
import { useEffect, useMemo, useState } from 'react';
import { ORG_DASHBOARD_ROUTE } from '../../../core-utils/routes';

type SsoError = 'Google' | 'Microsoft 365' | 'Okta' | null;

interface SignInProps {
    ssoError: SsoError;
    setSsoOption: (_key: string | null) => void;
}

interface SignInButtonsComponentProps {
    error: SsoError;
    errIndex: number | null;
    clickHandler: (_id: string) => void;
    tryAgainHandler: () => void;
}

const SignInErrorComponent = ({
    error,
    tryAgainHandler,
}: Omit<SignInButtonsComponentProps, 'errIndex' | 'clickHandler'>) => (
    <div className="sso-error d-flex flex-row justify-content-center text-secondary-accent-red-2">
        <Typography variant="body4">
            `${error} ${SIGN_IN_ERROR_TEXT}`
        </Typography>
        <Button variant="link" className="text-decoration-none" onClick={tryAgainHandler}>
            <Typography variant="body4">&nbsp;{TRY_AGAIN}</Typography>
        </Button>
    </div>
);

const SignInButtonsComponent = ({ error, errIndex, clickHandler, tryAgainHandler }: SignInButtonsComponentProps) => {
    const SignInButtons = useMemo(() => {
        return SignInButtonsMaps.map(({ Icon, text, id }, idx) => (
            <div key={idx} className="d-flex flex-column gap-3 align-items-center">
                <Button
                    variant="outline border border-primary-500 border-2"
                    className="text-text-high-emphasis d-flex align-items-center"
                    onClick={() => clickHandler(id)}
                >
                    <div className="icon-wrapper">{Icon}</div>
                    <Typography variant="subtitle2">{text}</Typography>
                </Button>
                {errIndex === idx && SignInErrorComponent({ error, tryAgainHandler })}
            </div>
        ));
    }, [error]);

    return (
        <div className="sign-in-page__sign-in bg-structural-CardBG rounded-end text-text-high-emphasis d-flex flex-column text-center justify-content-center align-items-center gap-3">
            <Typography variant="subtitle1">{DASHBOARD_SIGN_IN}</Typography>
            {SignInButtons}
        </div>
    );
};

const SignInInfoComponent = () => (
    <div className="sign-in-page__get-in-touch bg-secondary-accent-blue-2 text-text-high-emphasis d-flex flex-column text-center justify-content-center rounded-start">
        <ReactSVG src={AmplifierSignInLogo} />
        <Typography variant="subtitle2">{SignInInfoText}</Typography>
        <Button href="#" variant="link" className="text-text-high-emphasis text-decoration-none p-0">
            <Typography variant="body1">{GET_IN_TOUCH}</Typography>
        </Button>
    </div>
);

const SignIn = ({ ssoError = null, setSsoOption }: SignInProps) => {
    const [error, setError] = useState<SsoError>(ssoError);
    const [errIndex, setErrIndex] = useState<null | number>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (Boolean(ssoError)) {
            setError(ssoError);
            switch (ssoError) {
                case 'Google':
                    setErrIndex(0);
                    break;

                case 'Microsoft 365':
                    setErrIndex(1);
                    break;

                case 'Okta':
                    setErrIndex(2);
                    break;

                default:
                    setErrIndex(null);
                    break;
            }
        } else {
            setError(null);
        }
    }, [ssoError]);

    const tryAgainHandler = () => {
        setErrIndex(null);
        setError(null);
    };

    const clickHandler = (option: string | null) => {
        setSsoOption(option);
        if (option === 'Google') {
            const redirectUrl = `${window.location.origin}/auth/redirect`;
            window.location.href = `${
                process.env.PLATFORM_API_URL
            }/api/v1/auth/google/login?redirectUrl=${encodeURIComponent(redirectUrl)}`;
        } else {
            navigate(ORG_DASHBOARD_ROUTE);
        }
    };

    return (
        <div className="sign-in-page-container d-flex align-items-center justify-content-center bg-structural-BG">
            <div className="sign-in-page d-flex">
                <SignInInfoComponent />
                <SignInButtonsComponent
                    error={error}
                    errIndex={errIndex}
                    clickHandler={clickHandler}
                    tryAgainHandler={tryAgainHandler}
                />
            </div>
        </div>
    );
};

const mapState = (state: { ssoModel: { ssoOption: any } }) => ({
    ssoOption: state.ssoModel.ssoOption,
});

const mapDispatch = (dispatch: any) => ({
    setSsoOption: (option: any) => dispatch.ssoModel.setSsoOption(option),
});

export default connect(mapState, mapDispatch)(SignIn);
