import Button from 'react-bootstrap/Button';
import { Image } from 'react-bootstrap';
import { DUPLICATE, HISTORY, SAVE_START } from '../../../core-utils/constants/constants';
import Typography from '../../atoms/Typography';
import './styles.scss';
import { Link, useParams } from 'react-router-dom';
import BackButton from '../../molecules/BackButton';
import { FlowType } from '../../../core-utils/types/types';
import { getEngagementFlowsUrl } from '../../../core-utils/endPoints';
import { getApiData } from '../../../apis/library';
import PageErrorHandlerAndLoaderWrapper from '../../../wrappers/PageErrorHandlerAndLoaderWrapper';
import { useApiObjectdata } from '../../../hooks/useApiObjectdata';
import FlowImage from '../../../../public/assets/icons/flowBuilder.svg';

const renderButtons = () => {
    return (
        <div className="d-flex flex-row justify-content-between engagement-flow__buttons-cont">
            <BackButton />
            <div className="d-flex flex-row justify-content-end engagement-flow__button--outline-cont align-items-center">
                <Link
                    className="engagement-flow__link d-flex justify-content-center"
                    // TODO: to be integrated with redirect link to
                    to={''}
                >
                    <Typography variant="caption3" color="structural-stroke-100">
                        {DUPLICATE}
                    </Typography>
                </Link>
                <Link
                    className="engagement-flow__link d-flex justify-content-center"
                    // TODO: to be integrated with redirect link to
                    to={''}
                >
                    <Typography variant="caption3" color="structural-stroke-100">
                        {HISTORY}
                    </Typography>
                </Link>
                <Button
                    variant={'primary-500'}
                    className="engagement-flow__primary-btn caption3 d-flex justify-content-center"
                    disabled
                >
                    {SAVE_START}
                </Button>
            </div>
        </div>
    );
};

const EngagementFlowTabComponent = (workflowData: FlowType) => {
    return (
        <div className="d-flex flex-column engagement-flow">
            {renderButtons()}
            <div className="bg-structural-CardBG engagement-flow__workflow-cont d-flex justify-content-center">
                <div className="engagement-flow__cont d-flex flex-column justify-content-between align-items-center custom-scrollbar">
                    <div className="engagement-flow__typo-cont">
                        <Typography variant="body11" color="text-high-emphasis">
                            {workflowData.title}
                        </Typography>
                    </div>
                    <Image src={FlowImage} alt="flow-template" className="engagement-flow__img-cont" />
                </div>
            </div>
        </div>
    );
};

const EngagementFlowTab = () => {
    const { id: workflowId } = useParams();
    const { data: workflowData, isLoading, error } = useApiObjectdata(
        `${getEngagementFlowsUrl}/${workflowId}`,
        getApiData,
        (dataItem: FlowType) => {
            return { ...dataItem };
        },
    );
    if (!workflowData) {
        // TODO: handle empty state
        return null;
    }
    return (
        <PageErrorHandlerAndLoaderWrapper
            error={error}
            isLoading={isLoading}
            render={() => EngagementFlowTabComponent(workflowData)}
        />
    );
};
export default EngagementFlowTab;
