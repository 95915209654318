import { useState } from 'react';
import { ADD_INTEGRATION, GOOGLE_INTEGRATION_TABS } from '../../../core-utils/constants/constants';
import './styles.scss';
import { Button, Nav } from 'react-bootstrap';
import Typography from '../../atoms/Typography';
import { activeTabClassName } from '../../../core-utils/helpers/helpers';

interface IntegrationTabsProps {
    overviewTabContent: string;
    intallationInstructions: string;
    handleIntegration: () => void;
}

const IntegrationTabs = ({ overviewTabContent, intallationInstructions, handleIntegration }: IntegrationTabsProps) => {
    const [activeTab, setActiveTab] = useState(GOOGLE_INTEGRATION_TABS[0].eventKey);

    const handleTabChange = (eventKey: any) => {
        setActiveTab(eventKey);
    };

    const renderTabContent = () => {
        if (activeTab === 'overview') {
            return (
                <Typography variant="body2" color="text-medium-emphasis">
                    {overviewTabContent}
                </Typography>
            );
        } else if (activeTab === 'installation guide') {
            return (
                <Typography variant="body2" color="text-medium-emphasis">
                    {intallationInstructions}
                </Typography>
            );
        }
        return null;
    };

    return (
        <div className="d-flex justify-content-between flex-column bg-body-black text-text-high-emphasis tabs tabs__content">
            <div className="tabs__tab-cont ps-4">
                <Nav variant="tabs" activeKey={activeTab} onSelect={handleTabChange} className="tabs__custom-tabs">
                    {GOOGLE_INTEGRATION_TABS.map((tab) => (
                        <Nav.Item key={tab.eventKey}>
                            <Nav.Link
                                eventKey={tab.eventKey}
                                className={activeTabClassName(activeTab === tab.eventKey)}
                            >
                                <Typography variant="subtitle2">{tab.title}</Typography>
                            </Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
                <div className="content-container pt-4">{renderTabContent()}</div>
            </div>
            <div className="d-flex justify-content-end pb-3 pe-3">
                <Button
                    className="btn btn-primary-300 btn-lg body1 text-text-black tabs__button text-uppercase"
                    onClick={handleIntegration}
                >
                    {ADD_INTEGRATION}
                </Button>
            </div>
        </div>
    );
};

export default IntegrationTabs;
