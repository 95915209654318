import React from 'react';
import { ERROR_MSG } from '../core-utils/constants/constants';
import ErrorState from '../_components/molecules/ErorrState';
import PageLoader from '../_components/molecules/PageLoader';

type PageErrorHandlerAndLoaderWrapperProps = {
    error: any;
    isLoading: any;
    render: () => React.ReactNode;
    handleRefresh?: React.MouseEventHandler<HTMLButtonElement>;
};

const PageErrorHandlerAndLoaderWrapper = ({
    error,
    isLoading,
    render,
    handleRefresh,
}: PageErrorHandlerAndLoaderWrapperProps) => {
    if (isLoading) {
        return <PageLoader />;
    }
    if (error) {
        return (
            <ErrorState primaryMessage={ERROR_MSG[0]} secondaryMessage={ERROR_MSG[1]} onRefreshClick={handleRefresh} />
        );
    }

    return <>{render()}</>;
};

export default PageErrorHandlerAndLoaderWrapper;
