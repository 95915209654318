import './style.scss';
import { Container, Nav, Navbar, Dropdown, Image } from 'react-bootstrap';
import AmplifierLogoBlack from '../../../../public/assets/icons/Amplifier_Logo_Black.svg';
import Typography from '../../atoms/Typography';
import { ReactSVG } from 'react-svg';
import { FaUserCircle } from 'react-icons/fa';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import SignOutIcon from '../../../../public/assets/icons/SignOutIcon.svg';
import { SETTINGS_SPECIFIC_ROUTE, SIGN_IN } from '../../../core-utils/routes';
import { useNavigate } from 'react-router-dom';
import { navItems } from '../../../core-utils/constants/constants';
import { NavLink } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { FiSettings } from 'react-icons/fi';
import { Dispatch, RootState } from 'Rdx/store';
import { useDispatch, useSelector } from 'react-redux';

export interface SideNavProps {
    userImage?: string | React.ReactElement<any>;
    userEmail: string;
    setSsoOption: (_key: string | null) => void;
}

interface ProfileComponentProps {
    onClickSignOutHandler: () => void;
}

const UserAvatar = ({ userImage }: { userImage?: string | React.ReactElement<any> }) => {
    if (userImage) {
        if (typeof userImage === 'string') {
            return <Image src={userImage} roundedCircle={true} className="sideNav__user-avatar-element ms-1 me-1" />;
        } else {
            return userImage;
        }
    }
    return <FaUserCircle className="sideNav__user-avatar-element ms-1 me-1" />;
};

const ProfileComponent = ({ onClickSignOutHandler }: ProfileComponentProps) => {
    const user = useSelector((state: RootState) => state.auth.user);
    const { pictureUrl, email } = user;

    return (
        <Dropdown className="border border-structural-stroke-300 rounded sign-out mb-3" drop="up">
            <Dropdown.Toggle className="text-text-high-emphasis">
                <UserAvatar userImage={pictureUrl!} />
                <MdOutlineKeyboardArrowDown size={25} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="bg-structural-CardBG p-0 border border-structural-stroke-300 overflow-hidden">
                <Dropdown.Item className="text-text-low-emphasis mt-1 mb-1" disabled>
                    <Typography variant="overline">{email}</Typography>
                </Dropdown.Item>
                <Dropdown.Divider className="bg-structural-stroke-500 m-0" />
                <Dropdown.Item className="text-text-high-emphasis mt-1 mb-1" onClick={() => onClickSignOutHandler()}>
                    <div className="text-text-medium-emphasis w-100 ps-0 d-flex align-items-center gap-2 border-0">
                        <ReactSVG src={SignOutIcon} />
                        <Typography variant="body5">Sign Out</Typography>
                    </div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

const SideNav = () => {
    const dispatch = useDispatch<Dispatch>();
    const navigate = useNavigate();
    const { cache } = useSWRConfig();

    const onClickHandler = () => {
        const cacheKeysIterator = cache.keys();

        for (const key of cacheKeysIterator) {
            cache.delete(key);
        }

        dispatch.auth.resetAuth();
        navigate(SIGN_IN);
    };

    return (
        <div className="sideNav">
            <Navbar bg="dark" variant="dark" className="sideNav__navbar">
                <Container className="sideNav__container">
                    <Navbar.Brand className="sideNav__brand">
                        <ReactSVG src={AmplifierLogoBlack} className="brand-icon" />
                    </Navbar.Brand>
                    <Nav className="sideNav__nav align-items-center">
                        {navItems.map(({ Icon, link, text }, index) => {
                            return (
                                <Nav.Link as={NavLink} to={link} key={index.toString()} className="sideNav__link">
                                    {typeof Icon === 'string' ? (
                                        <ReactSVG src={Icon} className="sideNav__svg-icon" />
                                    ) : (
                                        <div className="sideNav__react-icon">{Icon}</div>
                                    )}
                                    <Typography variant="caption3">{text.toUpperCase()}</Typography>
                                </Nav.Link>
                            );
                        })}
                    </Nav>
                    <Nav className="sideNav__settings-nav">
                        <Nav.Link
                            as={NavLink}
                            to={SETTINGS_SPECIFIC_ROUTE}
                            className="sideNav__settings-icon d-flex align-items-center justify-content-center"
                        >
                            <FiSettings className="sideNav__fi-icon" />
                        </Nav.Link>
                    </Nav>
                    <ProfileComponent onClickSignOutHandler={onClickHandler} />
                </Container>
            </Navbar>
        </div>
    );
};

export default SideNav;
