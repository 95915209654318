import React from 'react';
import CustomCard from '../../molecules/CustomCard';
import IconWithTypography from '../../molecules/IconWithTypography';
import RadarChart from '../../molecules/RadarGraph';
import { TOP_RISK_CONTRIBUTORS } from '../../../core-utils/constants/constants';
import { UserTopRiskContributorsProps } from './UserTopRiskContributorsHelper';

const UserTopRiskContributors = () => {
    const UserRiskContributorsChart = () => {
        return (
            <RadarChart
                datasets={UserTopRiskContributorsProps} //will update one we have api for the User Top Risk Contributors
            />
        );
    };
    return (
        <div className="user-top-risk-contributors h-100">
            <CustomCard
                cardType="oneThird"
                title={<IconWithTypography isRow={true} label={TOP_RISK_CONTRIBUTORS} labelColor="primary" />}
                content={<UserRiskContributorsChart />}
            />
        </div>
    );
};

export default UserTopRiskContributors;
