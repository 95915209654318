import * as React from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import Typography from '../../atoms/Typography';
import { TOASTMESSAGE } from '../../../core-utils/constants/constants';

const ToastBody = () => {
    return (
        <div className="d-flex flex-row">
            <BsInfoCircle />
            <div className="d-flex flex-column">
                <Typography variant="body10" color="text-high-emphasis">
                    {TOASTMESSAGE[0]}
                </Typography>
                <Typography variant="body5" color="text-high-emphasis">
                    {TOASTMESSAGE[1]}
                </Typography>
            </div>
        </div>
    );
};

export default ToastBody;
