import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { CgArrowsExpandRight } from 'react-icons/cg';
import CustomCard from '../../molecules/CustomCard';
import HealthMetrics from '../../molecules/HealthMetrics';
import IconWithTypography from '../../molecules/IconWithTypography';
import { VULNERABILITIES, VulnerabilitiesLabelMap } from '../../../core-utils/constants/constants';
import { getApiData } from '../../../apis/library';
import { getVulnerabilitySlaUrl } from '../../../core-utils/endPoints';
import { Color } from '../../../core-utils/types/color';
import { useApiData } from '../../../hooks/useApiData';
import ErrorHandlerAndLoaderWrapper from '../../../wrappers/ErrorHandlerandLoaderWrapper';
import './styles.scss';
import DataGridModal from '../DataGridModal';

interface SLAData {
    label: string;
    value: number;
}
const Vulnerability = () => {
    const [show, setShow] = useState(false);
    const labelColorArr: Color[] = [
        'secondary-accent-red-2',
        'secondary-accent-orange-2',
        'secondary-accent-yellow-1',
        'secondary-accent-green-2',
    ];
    const { data, error, isLoading, refresh } = useApiData(getVulnerabilitySlaUrl, getApiData, (dataItem: SLAData) => {
        const updatedLabel = VulnerabilitiesLabelMap[dataItem.label] || dataItem.label;
        return { ...dataItem, label: updatedLabel };
    });

    if (!data) {
        return <div>Loading...</div>;
    }
    const buildHealthMetric = (healthMetric: SLAData, index: number) => {
        const label = healthMetric?.value === null ? '-' : healthMetric?.value?.toString();
        const valueColor: Color = labelColorArr[index];
        const value = healthMetric?.label;
        const valueVariant: 'body1' | 'caption2' = 'body1';
        const labelColor: Color = 'text-high-emphasis';
        return { label, valueColor, value, valueVariant, labelColor };
    };
    const renderVulnerabilityRows = () => {
        const totalRows = 2;
        const totalCols = 2;

        return Array.from({ length: totalRows }).map((_, rowIndex) => (
            <Row key={rowIndex} className="vulnerabilities__vulnerability-row">
                {Array.from({ length: totalCols }).map((_, colIndex) => {
                    const index = rowIndex * totalCols + colIndex;
                    const healthMetric = data[index];
                    const metrics = buildHealthMetric(healthMetric, index);

                    return (
                        <Col key={colIndex} data-testid="sla-vulnerability-elements">
                            <HealthMetrics metric={metrics} />
                        </Col>
                    );
                })}
            </Row>
        ));
    };

    const VulnerabilityMetrics = () => {
        return <div className="container">{renderVulnerabilityRows()}</div>;
    };

    const handleClose = () => {
        setShow(false);
    };
    const handleClick = () => {
        setShow(!show);
    };
    return (
        <div className="vulnerabilities h-100" data-testid="vulnerabilities">
            <CustomCard
                cardType="oneThird"
                title={
                    <>
                        <IconWithTypography isRow={true} label={VULNERABILITIES} labelColor="primary" />
                        <CgArrowsExpandRight className="vulnerabilities__icon" size={22} onClick={handleClick} />
                    </>
                }
                content={
                    <div className="vulnerabilities__error-container">
                        <ErrorHandlerAndLoaderWrapper
                            error={error}
                            isLoading={isLoading}
                            render={VulnerabilityMetrics}
                            handleRefresh={refresh}
                        />
                    </div>
                }
            />
            <DataGridModal title={VULNERABILITIES} handleClose={handleClose} show={show} />
        </div>
    );
};

export default Vulnerability;
