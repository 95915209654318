import classNames from 'classnames';
import { MdArrowRightAlt } from 'react-icons/md';

import './styles.scss';
import { SortComponentProps, SortType } from '../../../core-utils/types/types';
import Typography from '../../atoms/Typography';

const SortDropdown = ({ handleSort, sortDirection }: SortComponentProps) => {
    const toggleOrSetSort = (selectedSortDirection: SortType) => {
        const newSortDirection = sortDirection === selectedSortDirection ? null : selectedSortDirection;
        handleSort(newSortDirection);
    };

    const defaultClassName = 'data-grid-dropdown__dropdown-item dropdown-item d-flex align-items-center';

    const ascClassNames = classNames(defaultClassName, { active: sortDirection === 'asc' });
    const descClassNames = classNames(defaultClassName, { active: sortDirection === 'desc' });

    return (
        <>
            <li>
                <div className="dropdown-header">
                    <Typography variant="body5" color="text-high-emphasis">
                        Sort
                    </Typography>
                </div>
            </li>
            <li>
                <div className={ascClassNames} onClick={() => toggleOrSetSort('asc')}>
                    <Typography variant="body2">
                        Sort A <MdArrowRightAlt /> Z
                    </Typography>
                </div>
            </li>
            <li>
                <div className={descClassNames} onClick={() => toggleOrSetSort('desc')}>
                    <Typography variant="body2">
                        Sort Z <MdArrowRightAlt /> A
                    </Typography>
                </div>
            </li>
        </>
    );
};

export default SortDropdown;
