import * as React from 'react';
import Typography from '../../atoms/Typography';
import Avatar from '../../atoms/Avatar';
import { IoClose } from 'react-icons/io5';
import './styles.scss';

interface InviteUserDetailsProps {
    firstName: string;
    lastName: string;
    email: string;
    imageUrl?: string;
    handleUser: (_event: React.MouseEvent<SVGElement, MouseEvent>) => void;
}

const InviteUserDetails = ({ firstName, lastName, email, imageUrl, handleUser }: InviteUserDetailsProps) => {
    return (
        <div
            className="user-invited d-flex flex-row justify-content-between align-items-center"
            data-testid="user-invited"
        >
            <div className="d-flex flex-row gap-3">
                <Avatar variant="avatar" name={`${firstName} ${lastName}`} image={imageUrl} />
                <div className="d-flex flex-column ml-2">
                    <Typography variant="subtitle3" color="text-low-emphasis">
                        {`${firstName} ${lastName}`}
                    </Typography>
                    <Typography variant="body3" color="text-low-emphasis">
                        {email}
                    </Typography>
                </div>
            </div>
            <IoClose className="user-invited__icon" size={20} onClick={handleUser} />
        </div>
    );
};

export default InviteUserDetails;
