import { HEALTH_TRENDS_TEXT } from '../../../core-utils/constants/constants';
import CustomCard from '../../molecules/CustomCard';
import UserLineGraph from '../../molecules/UserLineGraph';
import './styles.scss';
import Typography from '../../atoms/Typography';

interface Series {
    data: number[];
    name: string;
}

interface UserLineGraphCardProps {
    series: Series[];
}

const UserLineGraphCard = (props: UserLineGraphCardProps) => {
    return (
        <div className="user-line-graph-card">
            <CustomCard
                cardType="oneThird"
                title={<Typography variant="subtitle4">{HEALTH_TRENDS_TEXT}</Typography>}
                content={
                    <div className="d-flex align-items-center justify-content-center user-line-graph-card__error-container">
                        <UserLineGraph series={props.series} />
                    </div>
                }
            />
        </div>
    );
};

export default UserLineGraphCard;
