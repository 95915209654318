import React, { useState } from 'react';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import ToastMessage from '../../molecules/ToastMessage';
import Typography from '../../atoms/Typography';
import { INTEGRATIONSTITLE } from '../../../core-utils/constants/constants';
import IntegratedCard, { IntegratedCardProps } from '../../molecules/IntegratedCard';
import AddIntegrationModal from '../AddIntegrationModal';
import { INTEGRATIONS_DATA } from '../../../core-utils/constants/integrations';
import Integrations, { IntegrationDataProps } from '../Integrations';
import IconWithTypography from '../../molecules/IconWithTypography';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

type IntegrationDataType = {
    id: string;
} & Omit<IntegratedCardProps, 'handleCard'>;

interface VendorsIntegrationProps {
    integratedData: IntegrationDataType[];
}

const VendorsIntegration = ({ integratedData }: VendorsIntegrationProps) => {
    const [modals, setModals] = useState({
        modal1: false,
        modal2: false,
    });
    const [selectVendor, setSelectVendor] = useState<IntegrationDataProps>({} as IntegrationDataProps);
    const [selectedcategory, setSelectedcategory] = useState<string>('');
    const [data, setData] = useState<IntegrationDataProps[]>(INTEGRATIONS_DATA);
    const [variant, setVariant] = useState<'all' | 'selected'>('selected');
    const navigate = useNavigate();

    const handleBack = () => {
        const modalObj = {
            ...modals,
            ['modal1']: true,
            ['modal2']: false,
        };
        setModals(modalObj);
    };
    const handleCardClick = (id: number) => {
        const filteredItem = data.find((dataItem: IntegrationDataProps) => {
            return dataItem.id === id;
        });
        setSelectVendor(filteredItem!);

        const modalObj = {
            ...modals,
            ['modal1']: false,
            ['modal2']: true,
        };
        setModals(modalObj);
    };
    const closeModal = (modalName: string) => {
        const modalObj = {
            ...modals,
            [modalName]: false,
        };
        setModals(modalObj);
    };
    const handleselectedcategoryTools = (selectedcategory: string) => {
        const modalObj = {
            ...modals,
            ['modal1']: true,
            ['modal2']: false,
        };
        setModals(modalObj);
        setSelectedcategory(selectedcategory);
        setVariant('selected');
    };
    const handleIntegration = () => {
        const modalObj = {
            ...modals,
            ['modal1']: false,
            ['modal2']: false,
        };
        setModals(modalObj);
        setData((vendorState) => {
            const updatedselectVendor = {
                ...selectVendor,
                isIntegrated: true,
            };
            setSelectVendor(updatedselectVendor);
            const itemIndex = data.findIndex((data) => data.id === updatedselectVendor.id);

            if (itemIndex !== -1) {
                const updateddata = [...data];
                updateddata[itemIndex] = updatedselectVendor;
                setData(updateddata);
            }
            return [...vendorState, updatedselectVendor];
        });
    };
    const handleConfig = (id: number) => {
        const selectedItem = data.find((data) => data.id === id);
        if (selectedItem) {
            navigate(`/vendor/${id}`, { state: { icon: selectedItem.imageUrl, label: selectedItem.vendor } });
        }
        console.log(id);
    };

    const handleNewTool = () => {
        const modalObj = {
            ...modals,
            ['modal1']: true,
            ['modal2']: false,
        };
        setModals(modalObj);
        setVariant('all');
        setSelectedcategory('ALL INTEGRATION');
    };

    const renderIntegratedCardWithConfig = (item: IntegrationDataProps, card: IntegrationDataType) => {
        if (item.tags.includes(card.title) && item.isIntegrated === true) {
            return (
                <div key={card.id} className="col-3 mb-3">
                    <IntegratedCard
                        vendor={item.vendor}
                        imageUrl={item.imageUrl}
                        title={card.title}
                        handleCard={() => handleConfig(item.id)}
                    />
                </div>
            );
        }
        return null;
    };
    const renderIntegratedCardWithoutConfig = (card: IntegrationDataType) => {
        return (
            <div key={card.id} className="col-3 mb-3">
                <IntegratedCard
                    imageUrl={card.imageUrl}
                    title={card.title}
                    handleCard={() => handleselectedcategoryTools(card.title)}
                />
            </div>
        );
    };
    return (
        <>
            <div className="vendors-integration w-100" data-testid="vendors-integration">
                <div className="vendors-integration__title-container d-flex flex-row justify-content-between">
                    <Typography variant="body9" color="text-high-emphasis">
                        {INTEGRATIONSTITLE}
                    </Typography>
                    {data.filter((dataItem) => dataItem.isIntegrated === true).length > 0 && (
                        <div className="vendors-integration__column vendors-integration__button-container d-flex flex-row justify-content-end align-items-center">
                            <Button className="btn" onClick={handleNewTool}>
                                <IconWithTypography
                                    startIcon={<FaPlus className="vendors-integration__plus-icon" />}
                                    isRow={true}
                                    label="ADD NEW"
                                    labelColor="structural-selected"
                                    labelVariant="body2"
                                />
                            </Button>
                        </div>
                    )}
                </div>
                <div className="d-flex flex-column vendors-integration__toast-container">
                    {_.isEmpty(data.filter((dataItem) => dataItem.isIntegrated === true)) && <ToastMessage />}
                    <div className="d-grid mt-3 gap-3">
                        <div className="row">
                            {integratedData.map((card) => {
                                if (
                                    _.flatten(
                                        data
                                            .filter((dataItem) => dataItem.isIntegrated === true)
                                            .map((item) => item.tags),
                                    ).includes(card.title)
                                ) {
                                    return data.map((item) => renderIntegratedCardWithConfig(item, card));
                                } else {
                                    return renderIntegratedCardWithoutConfig(card);
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <Integrations
                title={selectedcategory}
                data={
                    variant === 'all'
                        ? data
                        : data.filter((data: IntegrationDataProps) =>
                              data.tags.some((tag) => tag.toLowerCase() === selectedcategory.toLowerCase()),
                          )
                }
                show={modals.modal1}
                handleClose={() => closeModal('modal1')}
                handleCardClick={handleCardClick}
                variant={variant}
            />
            <AddIntegrationModal
                show={modals.modal2}
                handleClose={() => closeModal('modal2')}
                overviewTabContent={selectVendor?.integrationCopy ?? ''}
                intallationInstructions={selectVendor?.intallationInstructions ?? ''}
                imageUrl={selectVendor?.imageUrl}
                signInText={selectVendor?.vendor ?? ''}
                handleBack={handleBack}
                handleIntegration={handleIntegration}
            />
        </>
    );
};

export default VendorsIntegration;
