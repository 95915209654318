export const getVulnerabilitySlaUrl = 'api/v1/reports/vulnerability-sla/metrics';
export const getSecurityHealthUrl = 'api/v1/reports/health-score/metrics';
export const getEmployeeToolingCoverageUrl = 'api/v1/reports/workforce-tooling-employee-coverage/metrics';
export const getEndpointToolingCoverageUrl = 'api/v1/reports/workforce-tooling-endpoint-coverage/metrics';
export const getTrainingDeficienciesUrl = 'api/v1/reports/training-deficiencies/metrics';
export const getLineGraphUrl = 'api/v1/reports/health-trend/metrics';

export const getMfaCoverageUrl = 'api/v1/reports/mfa-application-coverage/metrics';
export const getUsersListUrl = 'api/v1/users';
export const getTopRiskContributorsUrl = 'api/v1/reports/top-risk-contributors/metrics';
export const getEngagementFlowsUrl = 'api/v1/flows';
export const getEngagementValuesUrl = 'api/v1/reports/engagement_score/metrics';
