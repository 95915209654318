import { CgArrowsExpandRight } from 'react-icons/cg';
import { useState } from 'react';
import { MFA_COVERAGE_SUB_TITLE, MFA_COVERAGE_TITLE } from '../../../core-utils/constants/constants';
import CustomCard from '../../molecules/CustomCard';
import IconWithTypography from '../../molecules/IconWithTypography';
import Typography from '../../atoms/Typography';
import MfaCoverage from '../../molecules/MfaCoverage';
import { getApiData } from '../../../apis/library';
import { getMfaCoverageUrl } from '../../../core-utils/endPoints';
import { useApiData } from '../../../hooks/useApiData';
import { DataProp } from '../../../core-utils/types/types';
import ErrorHandlerAndLoaderWrapper from '../../../wrappers/ErrorHandlerandLoaderWrapper';
import './styles.scss';
import DataGridModal from '../DataGridModal';

const MfaCoverageCard = () => {
    const [show, setShow] = useState(false);
    const { data, error, isLoading, refresh } = useApiData(getMfaCoverageUrl, getApiData, (dataItem: DataProp) => {
        return { ...dataItem };
    });

    const MfaCoverageMetrics = () => {
        return (
            <div className="mfacoverage-card__mfacoverage-style">
                <MfaCoverage total={data[0]?.value} unsafe={data[2]?.value} />
            </div>
        );
    };
    const handleClose = () => {
        setShow(false);
    };
    const handleClick = () => {
        setShow(!show);
    };
    return (
        <div data-testid="mfa-card" className="mfacoverage-card h-100">
            <CustomCard
                cardType="oneThird"
                title={
                    <>
                        <IconWithTypography isRow={true} label={MFA_COVERAGE_TITLE} labelColor="primary" />
                        <CgArrowsExpandRight size={22} className="mfacoverage-card__icon" onClick={handleClick} />
                    </>
                }
                subTitle={
                    <Typography
                        variant="subtitle3"
                        children={data[0]?.value + ' ' + MFA_COVERAGE_SUB_TITLE}
                        color="text-medium-emphasis"
                    />
                }
                content={
                    <div className="mfacoverage-card__error-container">
                        <ErrorHandlerAndLoaderWrapper
                            error={error}
                            isLoading={isLoading}
                            render={MfaCoverageMetrics}
                            handleRefresh={refresh}
                        />
                    </div>
                }
            />
            <DataGridModal title={MFA_COVERAGE_TITLE} handleClose={handleClose} show={show} />
        </div>
    );
};

export default MfaCoverageCard;
