import React from 'react';
import CustomCard from '../../molecules/CustomCard';
import IconWithTypography from '../../molecules/IconWithTypography';
import RadarChart from '../../molecules/RadarGraph';
import { TOP_RISK_CONTRIBUTORS } from '../../../core-utils/constants/constants';
import ErrorHandlerAndLoaderWrapper from '../../../wrappers/ErrorHandlerandLoaderWrapper';
import { getApiData } from '../../../apis/library';
import { getTopRiskContributorsUrl } from '../../../core-utils/endPoints';
import { topRiskContributors } from '../../../core-utils/types/types';
import { useApiData } from '../../../hooks/useApiData';
import './styles.scss';

const TopRiskContributors = () => {
    const { data, error, isLoading, refresh } = useApiData(
        getTopRiskContributorsUrl,
        getApiData,
        (dataItem: topRiskContributors) => {
            return { ...dataItem };
        },
    );
    const TopRiskContributorsCard = () => {
        return (
            <RadarChart
                datasets={[
                    {
                        data: [
                            (data[2]?.value ?? 0) * 100,
                            (data[1]?.value ?? 0) * 100,
                            (data[3]?.value ?? 0) * 100,
                            (data[0]?.value ?? 0) * 100,
                        ],
                        backgroundColor: 'rgba(39, 32, 54, 0.4)',
                        borderColor: '#7358BB',
                    },
                ]}
            />
        );
    };
    return (
        <div className="top-risk-contributors h-100" data-testid="top-risk-contributors">
            <CustomCard
                cardType="oneThird"
                title={<IconWithTypography isRow={true} label={TOP_RISK_CONTRIBUTORS} labelColor="primary" />}
                content={
                    <div className="top-risk-contributors__error-container">
                        <ErrorHandlerAndLoaderWrapper
                            error={error}
                            isLoading={isLoading}
                            render={TopRiskContributorsCard}
                            handleRefresh={refresh}
                        />
                    </div>
                }
            />
        </div>
    );
};

export default TopRiskContributors;
