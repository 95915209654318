import './styles.scss';
import React, { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import DataTable, { TableColumn, Alignment } from 'react-data-table-component';
import { ReactSVG } from 'react-svg';
import { Dropdown } from 'react-bootstrap';
import SearchBar from '../../molecules/SearchBar';
import { SelectCallback } from '@restart/ui/esm/types';
import { PaginationChangePage, PaginationChangeRowsPerPage } from 'react-data-table-component/dist/src/DataTable/types';
import EmptyTableIcon from '../../../../public/assets/icons/EmptyTableIcon.svg';
import { EMPTY_TABLE_MESSAGE, DEFAULT_TABLE_BODY_HEIGHT } from '../../../core-utils/constants/constants';
import Typography from '../../atoms/Typography';
import { IoChevronDownCircle } from 'react-icons/io5';
import FilterDropdown from '../FilterDropdown';
import { flatMap, compact } from 'lodash';

export interface DataTableProps {
    columns: TableColumn<any>[];
    data: Object[];
    onRowClicked?: (_row: any, _e: React.MouseEvent) => void;
    defaultSortId?: string | undefined;
    fixedHeaderScrollHeight?: string | undefined;
    rowsPerPageOptions: number[];
    tableBodyHeight?: string | undefined;
    isLoading?: boolean;
    subHeader?: boolean;
    dropdownOptions?: {
        [key: string]: string[];
    };
    titleComponent?: JSX.Element;
    onSelectedRowsChange?: (_selected: { allSelected: boolean; selectedCount: number; selectedRows: any[] }) => void;
    subHeaderVariant?: 'with-button' | 'filter-with-actions' | 'filter-without-actions';
    selectableRows?: boolean;
    filterColumns?: string[];
    subHeaderContent?: React.ReactNode;
    selectedRows?: any[];
    pagination?: boolean;
}

interface PaginationComponentProps {
    handlePageNumber: React.MouseEventHandler<HTMLButtonElement> | undefined;
    handleNextButtonClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
    handleBackButtonClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
    previousDisabled: boolean;
    nextDisabled: boolean;
    pageItems: number[];
    currentPage: number;
}

type CustomPaginationProps = {
    rowsPerPage: number;
    rowCount: number;
    onChangePage: PaginationChangePage;
    onChangeRowsPerPage: PaginationChangeRowsPerPage;
    currentPage: number;
    rowsPerPageOptions?: number[];
};

export interface FilterDropdownProps {
    columns: TableColumn<any>[];
    dropdownOptions: {
        [key: string]: string[];
    };
    subHeaderVariant?: 'with-button' | 'filter-with-actions' | 'filter-without-actions';
    filterColumns?: string[];
    selectedRows?: any[];
    subHeaderContent?: React.ReactNode;
}

const getColumnNames = (columns: TableColumn<any>[]) => {
    return compact(
        flatMap(columns, (column) => {
            if (
                column.name &&
                React.isValidElement(column.name) &&
                React.Children.count(column.name.props.children) > 0
            ) {
                return React.Children.toArray(column.name.props.children)[0];
            }
            return null;
        }),
    );
};

export const SubHeaderComponent = (props: FilterDropdownProps) => {
    const [selectedOptions, setSelectedOptions] = useState<{
        [key: string]: string[];
    }>({});
    const columns = getColumnNames(props.columns);
    const filterWithAction = props.subHeaderVariant === 'filter-with-actions' ? true : false;

    // TODO: Handler functions needs to integrated with api
    const handleClearClick = (dropdownKey: string): MouseEventHandler<HTMLButtonElement> => () => {
        setSelectedOptions((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            [dropdownKey.toLowerCase()]: [],
        }));
    };

    const handleResetClick = () => {
        setSelectedOptions({});
    };

    const handleSaveClick = () => {};

    const handleApplyClick = () => {};

    const handleCheckboxChange = (option: string, isChecked: boolean, text: string) => {
        setSelectedOptions((prevSelectedOptions) => {
            const dropdownKey = text.toLowerCase();

            if (isChecked) {
                return {
                    ...prevSelectedOptions,
                    [dropdownKey]: [...(prevSelectedOptions[dropdownKey] || []), option],
                };
            }
            return {
                ...prevSelectedOptions,
                [dropdownKey]: prevSelectedOptions[dropdownKey].filter(
                    (selectedOption: string) => selectedOption !== option,
                ),
            };
        });
    };
    if (props.subHeaderVariant === 'filter-with-actions' || props.subHeaderVariant === 'filter-without-actions') {
        return (
            <div className="table-subheader d-flex gap-3 p-0">
                <SearchBar value={''} />
                <FilterDropdown
                    items={props.filterColumns ? props.filterColumns : columns}
                    options={props.dropdownOptions}
                    selectedOptions={selectedOptions}
                    handleCheckboxChange={handleCheckboxChange}
                    handleClearClick={handleClearClick}
                    handleResetClick={handleResetClick}
                    handleSaveClick={handleSaveClick}
                    handleApplyClick={handleApplyClick}
                    filterWithActions={filterWithAction}
                />
            </div>
        );
    } else {
        return (
            <div className="d-flex justify-content-between bg-red w-100">
                <SearchBar value={''} />
                {props.subHeaderContent}
            </div>
        );
    }
};
const TableRowDropdown = ({
    onSelectHandler,
    numberOfRows,
    rowsPerPageOptions,
}: {
    onSelectHandler: SelectCallback | undefined;
    numberOfRows: number;
    rowsPerPageOptions: number[];
}) => (
    <Dropdown className="border border-structural-stroke-300 rounded" onSelect={onSelectHandler}>
        <Dropdown.Toggle className="text-text-high-emphasis">{numberOfRows}</Dropdown.Toggle>
        <Dropdown.Menu className="bg-structural-CardBG table-row-menu p-0 border border-structural-stroke-300 overflow-hidden">
            {rowsPerPageOptions.map((rowsPerPageOption, idx) => (
                <Dropdown.Item
                    className="text-text-high-emphasis table-row-item"
                    eventKey={rowsPerPageOption}
                    key={idx}
                    tabIndex={idx}
                >
                    {rowsPerPageOption}
                </Dropdown.Item>
            ))}
        </Dropdown.Menu>
    </Dropdown>
);

const PaginationComponent = ({
    handleBackButtonClick,
    handleNextButtonClick,
    previousDisabled,
    nextDisabled,
    pageItems,
    currentPage,
    handlePageNumber,
}: PaginationComponentProps) => (
    <div className="d-flex flex-row align-items-center justify-content-evenly gap-3">
        <div className="page-item">
            <button className="page-link" onClick={handleBackButtonClick} disabled={previousDisabled}>
                {'<'}
            </button>
        </div>
        {pageItems.map((page) => {
            let className = 'page-item';
            if (page === currentPage) {
                className = `${className} active`;
            } else if (page > currentPage && nextDisabled) {
                className = `${className} disabled`;
            }

            return (
                <div key={page} className={className}>
                    <button className="page-link rounded-1" onClick={handlePageNumber} value={page}>
                        {page}
                    </button>
                </div>
            );
        })}
        <div className="page-item">
            <button className="page-link" onClick={handleNextButtonClick} disabled={nextDisabled}>
                {'>'}
            </button>
        </div>
    </div>
);

export const CustomPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage,
    currentPage,
    rowsPerPageOptions,
}: CustomPaginationProps) => {
    const handleBackButtonClick = () => {
        onChangePage(currentPage - 1, rowCount);
    };

    const handleNextButtonClick = () => {
        onChangePage(currentPage + 1, rowCount);
    };

    const handlePageNumber = (e: React.MouseEvent<HTMLElement>) => {
        onChangePage(Number((e.target as HTMLButtonElement).value), rowCount);
    };

    function getNumberOfPages(rowCount: number, rowsPerPage: number) {
        return Math.ceil(rowCount / rowsPerPage);
    }

    function toPages(pages: number, currentPage: number) {
        const results: number[] = [];

        if (currentPage === 1) {
            results.push(1, 2, 3);
        } else if (currentPage >= 3) {
            if (currentPage === pages) {
                results.push(currentPage - 2, currentPage - 1, currentPage);
            } else {
                results.push(currentPage - 1, currentPage, currentPage + 1);
            }
        } else {
            results.push(1, 2, 3);
        }

        return results;
    }

    const pages = getNumberOfPages(rowCount, rowsPerPage);
    const pageItems = toPages(pages, currentPage);
    const nextDisabled = currentPage === pages;
    const previousDisabled = currentPage === 1;

    const [numberOfRows, setNumberOfRows] = useState(rowsPerPage);

    const onSelectHandler = (eventKey: any, _event: Object) => {
        setNumberOfRows(eventKey);
        onChangeRowsPerPage(eventKey, currentPage);
    };

    return (
        <nav className="bg-structural-CardBG d-flex flex-row justify-content-between align-items-center text-text-high-emphasis p-2 ps-3 pe-3 table-skeleton__pagination">
            <div className="rows-options d-flex justify-content-center align-items-center gap-2">
                <Dropdown className="border border-structural-stroke-300 rounded" onSelect={onSelectHandler}>
                    <TableRowDropdown
                        onSelectHandler={onSelectHandler}
                        numberOfRows={numberOfRows}
                        rowsPerPageOptions={rowsPerPageOptions || []}
                    />
                </Dropdown>
                <Typography variant="body4">of {rowCount}</Typography>
            </div>
            <PaginationComponent
                handlePageNumber={handlePageNumber}
                handleNextButtonClick={handleNextButtonClick}
                handleBackButtonClick={handleBackButtonClick}
                previousDisabled={previousDisabled}
                nextDisabled={nextDisabled}
                pageItems={pageItems}
                currentPage={currentPage}
            />
        </nav>
    );
};

function EmptyTableComponent({ height = undefined }: { height?: number | string | undefined }) {
    return (
        <div
            className="d-flex flex-column gap-4 text-center text-uppercase align-items-center justify-content-center bg-structural-CardBG text-text-medium-emphasis sub-table"
            style={{ height }}
        >
            <ReactSVG src={EmptyTableIcon} className="empty-table__icon" />
            <Typography variant="body5">{EMPTY_TABLE_MESSAGE}</Typography>
        </div>
    );
}

const customStyle = {
    subHeader: {
        style: {
            backgroundColor: '$structural-CardBG',
        },
    },
    table: {
        style: {
            minHeight: DEFAULT_TABLE_BODY_HEIGHT,
            backgroundColor: '$structural-CardBG',
        },
    },
};

function Table({
    columns,
    data,
    dropdownOptions,
    defaultSortId,
    fixedHeaderScrollHeight,
    rowsPerPageOptions,
    tableBodyHeight,
    isLoading,
    subHeader = true,
    selectableRows,
    titleComponent,
    subHeaderVariant = 'filter-with-actions',
    filterColumns,
    subHeaderContent,
    onSelectedRowsChange,
    selectedRows,
    pagination = true,
}: DataTableProps) {
    if (tableBodyHeight) {
        customStyle.table.style.minHeight = tableBodyHeight;
    }

    const [sortedColumnId, setSortedColumnId] = useState<TableColumn<any>['id']>(defaultSortId);

    const onSortHandler = useCallback((sortedColumn: TableColumn<any>) => setSortedColumnId(sortedColumn.id), [
        columns,
    ]);

    const updatedColumns = useMemo(
        () =>
            columns.map((column) => {
                const tempColumn = { ...column };
                if (column.id === sortedColumnId) {
                    tempColumn.name = <div className="sorted-column w-100">{column.name}</div>;
                    tempColumn.conditionalCellStyles = [
                        {
                            when: (_row: any) => true,
                            classNames: ['sorted-column-cell'],
                        },
                    ];
                }
                return tempColumn;
            }),
        [sortedColumnId, columns],
    );

    return (
        <div className="d-flex flex-column p-0 table-skeleton">
            <DataTable
                columns={updatedColumns}
                data={data}
                subHeader={subHeader}
                subHeaderComponent={
                    <SubHeaderComponent
                        columns={columns}
                        dropdownOptions={dropdownOptions || {}}
                        subHeaderVariant={subHeaderVariant}
                        selectedRows={selectedRows}
                        filterColumns={filterColumns}
                        subHeaderContent={subHeaderContent}
                    />
                }
                subHeaderAlign={Alignment.LEFT}
                className="custom-scrollbar"
                pagination={pagination}
                paginationComponent={(props) => <CustomPagination {...props} rowsPerPageOptions={rowsPerPageOptions} />}
                paginationPerPage={rowsPerPageOptions[0]}
                paginationRowsPerPageOptions={rowsPerPageOptions}
                sortIcon={<IoChevronDownCircle />}
                defaultSortFieldId={defaultSortId}
                fixedHeader
                title={titleComponent}
                fixedHeaderScrollHeight={fixedHeaderScrollHeight}
                customStyles={customStyle}
                progressPending={isLoading}
                noDataComponent={<EmptyTableComponent height={fixedHeaderScrollHeight} />}
                onSort={onSortHandler}
                selectableRows={selectableRows ?? false}
                onSelectedRowsChange={onSelectedRowsChange}
            />
        </div>
    );
}

export default Table;
