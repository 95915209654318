import Table from '../Table';
import './styles.scss';
import { getApiData } from '../../../apis/library';
import { useApiData } from '../../../hooks/useApiData';
import { useParams } from 'react-router-dom';
import {
    FINDINGS_TABLE_PAGINATION,
    HEADER_SCROLL_HEIGHT,
    ISSUE_TYPE_TEXT,
    SEVERITY_TEXT,
} from '../../../core-utils/constants/constants';
import ErrorHandlerAndLoaderWrapper from '../../../wrappers/ErrorHandlerandLoaderWrapper';
import { getUsersListUrl } from '../../../core-utils/endPoints';
import { FINDINGS_TABLE_COLUMNS, FINDINGS_TABLE_HEADER } from '../../../core-utils/constants/findingsTable';
import Typography from '../../atoms/Typography';
import { getColumnValues } from '../../../core-utils/helpers/helpers';

export interface findingsTableProps {
    createdAt: string;
    status: string;
    numberOfEngagements: number;
    severity: string;
    displayValue: string;
}

export interface UserSpecificId {
    userSpecificId: string;
}

const HeaderComponent = <Typography variant="subtitle1">{FINDINGS_TABLE_HEADER}</Typography>;

const FindingsTable = () => {
    const { id: userId } = useParams();

    const { data, isLoading, error } = useApiData(
        `${getUsersListUrl}/${userId}/issues`,
        getApiData,
        (dataItem: findingsTableProps) => {
            return { ...dataItem };
        },
    );
    const severityOptions = getColumnValues(data, 'severity');
    const FindingsTableComponent = () => {
        return (
            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <ErrorHandlerAndLoaderWrapper
                    error={error}
                    isLoading={isLoading}
                    render={() => (
                        <div className="findings-table">
                            <Table
                                columns={FINDINGS_TABLE_COLUMNS}
                                titleComponent={HeaderComponent}
                                data={data}
                                fixedHeaderScrollHeight={HEADER_SCROLL_HEIGHT}
                                rowsPerPageOptions={FINDINGS_TABLE_PAGINATION}
                                tableBodyHeight={HEADER_SCROLL_HEIGHT}
                                subHeaderVariant="filter-without-actions"
                                dropdownOptions={{
                                    severity: severityOptions,
                                }}
                                filterColumns={[SEVERITY_TEXT, ISSUE_TYPE_TEXT]}
                            />
                        </div>
                    )}
                />
            </div>
        );
    };

    return <FindingsTableComponent />;
};

export default FindingsTable;
