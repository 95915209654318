import React from 'react';
import { ReactSVG } from 'react-svg';
import Typography from '../Typography';
import { getInitials } from '../../../core-utils/helpers/helpers';
import { AvatarWithNameType, AvatarWithImageType, AvatarWithImageAndHeightType } from '../../../core-utils/types/types';
import './avatar.scss';

interface AvatarProps {
    height?: string;
    width?: string;
    variant: 'avatar' | 'name_avatar';
    image?: string;
    name: string;
}

const AvatarInitials = ({ name, height, width }: AvatarWithNameType) => (
    <div
        className={`avatar avatar__initials d-flex align-items-center justify-content-center`}
        style={{ height, width }}
    >
        <Typography variant="body2">{getInitials(name, height, width)}</Typography>
    </div>
);

const AvatarWithImage = ({ image }: AvatarWithImageType) => (
    <ReactSVG src={image} className="avatar__avatar-with-name" />
);

const AvatarWithNameAndInitials = ({ name, height, width }: AvatarWithNameType) => (
    <div className="avatar__avatar-with-name avatar__avatar-with-name--initials d-flex align-items-center justify-content-center">
        <Typography variant="body2">{getInitials(name, height, width)}</Typography>
    </div>
);

const AvatarImage = ({ image, height }: AvatarWithImageAndHeightType) => (
    <ReactSVG src={image} style={{ height }} data-testid="avatar-image" />
);

const Avatar = ({ variant, image, name, height = '', width = '' }: AvatarProps) => {
    const AvatarContent = () => {
        return (
            <>
                {variant === 'avatar' ? (
                    <>
                        {image ? (
                            <AvatarImage image={image} height={height} />
                        ) : (
                            <AvatarInitials name={name} height={height} width={width} />
                        )}
                    </>
                ) : (
                    <>
                        {image ? (
                            <AvatarWithImage image={image} />
                        ) : (
                            <AvatarWithNameAndInitials name={name} height={height} width={width} />
                        )}
                        <div>
                            <Typography variant="body5">{name}</Typography>
                        </div>
                    </>
                )}
            </>
        );
    };

    return (
        <div className="avatar d-flex align-items-center justify-content-center">
            <AvatarContent />
        </div>
    );
};

export default Avatar;
