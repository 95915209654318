import { Container, Row, Col } from 'react-bootstrap';
import SideNav from '../../organisms/SideNav';
import './styles.scss';

interface MainTemplateProps {
    children: React.ReactNode;
}

const MainTemplate = (props: MainTemplateProps) => (
    <Container fluid className="main-template bg-structural-BG">
        <Row className="main-template__row">
            <Col lg="auto" sm="auto" xs="auto" md="auto" className="main-template__Col">
                <SideNav />
            </Col>
            <Col className="main-template__table-col custom-scrollbar w-100 h-100 overflow-auto">{props.children}</Col>
        </Row>
    </Container>
);

export default MainTemplate;
