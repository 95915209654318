import { Routes, Route, Navigate } from 'react-router-dom';
import {
    DASHBOARD_ROUTE,
    USER_SPECIFIC_ROUTE,
    HOME_ROUTE,
    WORKFLOW_ROUTE,
    POLICIES_ROUTE,
    DEFAULT_ROUTE,
    WORKFLOW_SPECIFIC_ROUTE,
    SECURITY_ROUTE,
    SETTINGS_SPECIFIC_ROUTE,
} from './core-utils/routes';
import EngagementFlowTab from './_components/organisms/EngagementFlowDetailTab';
import VendorsIntegration from './_components/organisms/VendorsIntegration';
import { INTEGRATIONSDATA } from './core-utils/constants/constants';
import SecurityFindingsContainer from '../public/assets/icons/SecurityFindingsContainer.svg';
import { Image } from 'react-bootstrap';
import VendorDetailsPage from './_components/pages/VendorDetailsPage';
import './theme/scroll.scss';
import ComingSoon from './_components/molecules/IminentCard';
import {
    AuthRedirectPage,
    EngagementFlowPage,
    DashboardPage,
    UserDetailPage,
    NotFoundPage,
    SettingsPage,
} from './_components/pages';
import './theme/scroll.scss';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/auth/redirect" element={<AuthRedirectPage />} />
            <Route path={DASHBOARD_ROUTE} element={<DashboardPage />} />
            <Route path={USER_SPECIFIC_ROUTE} element={<UserDetailPage />} />
            <Route path={WORKFLOW_ROUTE} element={<EngagementFlowPage />} />
            <Route path={WORKFLOW_SPECIFIC_ROUTE} element={<EngagementFlowTab />} />
            <Route path={POLICIES_ROUTE} element={<VendorsIntegration integratedData={INTEGRATIONSDATA} />} />
            <Route path={SECURITY_ROUTE} element={<Image src={SecurityFindingsContainer} width="99.5%" />} />
            <Route path={SETTINGS_SPECIFIC_ROUTE} element={<SettingsPage />} />
            {/* TODO Needs to be attached with Policies Component */}
            <Route path={POLICIES_ROUTE} element={<ComingSoon />} />
            <Route path={DEFAULT_ROUTE} element={<Navigate to={HOME_ROUTE} replace />} />
            <Route path="/vendor/:id" element={<VendorDetailsPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};

export default AppRoutes;
