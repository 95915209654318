import React, { useEffect, useState } from 'react';
import { CgArrowsExpandRight } from 'react-icons/cg';
import CustomCard from '../../molecules/CustomCard';
import HealthMetrics from '../../molecules/HealthMetrics';
import IconWithTypography from '../../molecules/IconWithTypography';
import {
    ToolingCoverageCtxLabelMap,
    ToolingCoverageLabelMap,
    TOOLING_COVERAGE,
} from '../../../core-utils/constants/constants';
import './styles.scss';
import { getApiData } from '../../../apis/library';
import { getEmployeeToolingCoverageUrl, getEndpointToolingCoverageUrl } from '../../../core-utils/endPoints';
import Typography from '../../atoms/Typography';
import { Color } from '../../../core-utils/types/color';
import { useApiData } from '../../../hooks/useApiData';
import ErrorHandlerAndLoaderWrapper from '../../../wrappers/ErrorHandlerandLoaderWrapper';
import DataGridModal from '../DataGridModal';

interface EmployeeToolingCoverage {
    label: string;
    value: number;
    ctxLabel: string;
    ctxValue: number;
}
const ToolingCoverage = () => {
    const [show, setShow] = useState(false);
    const labelColors: Color[] = [
        'text-high-emphasis',
        'secondary-accent-orange-2',
        'text-high-emphasis',
        'secondary-accent-yellow-1',
        'secondary-accent-red-1',
    ];
    const {
        data: employeeToolingData,
        error: employeeToolingDataError,
        isLoading: employeeToolingDataisLoading,
        refresh,
    } = useApiData(getEmployeeToolingCoverageUrl, getApiData, (dataItem: EmployeeToolingCoverage) => {
        const updatedLabel = ToolingCoverageLabelMap[dataItem.label] || dataItem.label;
        const updatedCtxLabel = ToolingCoverageCtxLabelMap[dataItem.ctxLabel] || dataItem.ctxLabel;
        return {
            ...dataItem,
            label: updatedLabel,
            ctxLabel: updatedCtxLabel,
        };
    });

    const {
        data: endpointToolingData,
        error: endpointToolingDataError,
        isLoading: endpointToolingDataisLoading,
    } = useApiData(getEndpointToolingCoverageUrl, getApiData, (dataItem: EmployeeToolingCoverage) => {
        const updatedLabel = ToolingCoverageLabelMap[dataItem.label] || dataItem.label;
        const updatedCtxLabel = ToolingCoverageCtxLabelMap[dataItem.ctxLabel] || dataItem.ctxLabel;
        return {
            ...dataItem,
            label: updatedLabel,
            ctxLabel: updatedCtxLabel,
        };
    });

    const [ToolingCoverageData, setToolingCoverageData] = useState<EmployeeToolingCoverage[]>([]);
    useEffect(() => {
        setToolingCoverageData([...employeeToolingData, ...endpointToolingData]);
    }, [employeeToolingData, endpointToolingData]);

    const handleClose = () => {
        setShow(false);
    };
    const handleClick = () => {
        setShow(!show);
    };
    const buildHealthMetric = (healthMetric: EmployeeToolingCoverage, index: number) => {
        const header = healthMetric.label;
        const label = healthMetric.value === null ? '-' : healthMetric?.value?.toString();
        const labelColor = labelColors[index];

        const value = (
            <div className="row-container-style" style={{ display: 'flex' }}>
                <div>
                    <Typography variant="caption2" children={healthMetric.ctxLabel} color="text-high-emphasis" />
                </div>
                {index === 0 || index === 2 ? (
                    <div className="tooling-coverage__styled-space">
                        <Typography
                            variant="caption2"
                            children={healthMetric?.ctxValue?.toString()}
                            color="secondary-accent-green-2"
                        />
                    </div>
                ) : null}
            </div>
        );

        const valueVariant: 'caption2' | 'body2' = 'caption2';

        return { header, label, labelColor, value, valueVariant };
    };
    const ToolingCoverageMetrics = () => {
        return (
            <div className="container d-flex flex-column" data-testid="tooling-coverage-content">
                <div className="tooling-coverage__styled-container">
                    {ToolingCoverageData.map((healthMetric, index) => {
                        const metric = buildHealthMetric(healthMetric, index);
                        return (
                            <React.Fragment key={index}>
                                <div>
                                    <HealthMetrics metric={metric} />
                                </div>
                                {index === 1 && <div className="vr" />}
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className="tooling-coverage h-100" data-testid="tooling-coverage">
            <CustomCard
                cardType="twoThird"
                title={
                    <>
                        <IconWithTypography isRow={true} label={TOOLING_COVERAGE} labelColor="primary" />
                        <CgArrowsExpandRight size={22} className="tooling-coverage__icon" onClick={handleClick} />
                    </>
                }
                content={
                    <div className="tooling-coverage__error-container">
                        <ErrorHandlerAndLoaderWrapper
                            error={employeeToolingDataError || endpointToolingDataError}
                            isLoading={employeeToolingDataisLoading || endpointToolingDataisLoading}
                            render={ToolingCoverageMetrics}
                            handleRefresh={refresh}
                        />
                    </div>
                }
            />
            <DataGridModal title={TOOLING_COVERAGE} handleClose={handleClose} show={show} />
        </div>
    );
};
export default ToolingCoverage;
