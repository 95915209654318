import HealthDashboardIcon from '../../../public/assets/icons/HealthDashboardIcon.svg';
import FlowIcon from '../../../public/assets/icons/FlowIcon.svg';
import PoliciesIntegrationIcon from '../../../public/assets/icons/PoliciesIntegrationIcon.svg';
import MicrosoftSignInIcon from '../../../public/assets/icons/MicrosoftSignInIcon.svg';
import OktaSignInIcon from '../../../public/assets/icons/OktaSignInIcon.svg';
import Jakob from '../../../public/assets/icons/Jakob.svg';
import { BsGoogle } from 'react-icons/bs';
import { ReactSVG } from 'react-svg';
import Slack from '../../../public/assets/icons/Slack1.svg';
import {
    ACTIVE_WORKFLOWS_ROUTE,
    DEPT_DASHBOARD_ROUTE,
    ORG_DASHBOARD_ROUTE,
    TEMPLATES_WORKFLOWS_ROUTE,
    USERS_DASHBOARD_ROUTE,
} from '../routes';
import IdentityTools from '../../../public/assets/icons/IdentityTools.svg';
import EndPointTools from '../../../public/assets/icons/EndPointTools.svg';
import VulnerabilityTools from '../../../public/assets/icons/VulnerabilityTools.svg';
import WebGateWayTools from '../../../public/assets/icons/WebGateWayTools.svg';
import TrainingTools from '../../../public/assets/icons/TrainingTools.svg';
import AssetMgmtTools from '../../../public/assets/icons/assetMgmtTools.svg';
import MessagingTools from '../../../public/assets/icons/messagingTools.svg';
import { BsFillShieldLockFill } from 'react-icons/bs';

export const tabs = [
    {
        eventKey: 'org',
        title: 'Organization',
        link: ORG_DASHBOARD_ROUTE,
    },
    {
        eventKey: 'dept',
        title: 'Departments',
        link: DEPT_DASHBOARD_ROUTE,
    },
    { eventKey: 'users', title: 'Users', link: USERS_DASHBOARD_ROUTE },
];

export const ENGAGEMENT_FLOW_TABS = [
    {
        eventKey: 'active-flows',
        title: 'Active Flows',
        link: ACTIVE_WORKFLOWS_ROUTE,
    },
    {
        eventKey: 'templates',
        title: 'Templates',
        link: TEMPLATES_WORKFLOWS_ROUTE,
    },
];

export const TOOLING_COVERAGE = 'TOOLING COVERAGE';
export const VULNERABILITIES = 'OUT OF SLA VUNERABLITIES ';
export const CRITICAL_LABEL = 'health_critical_vulnerability_label';
export const HIGH_LABEL = 'health_high_vulnerability_label';
export const MEDIUM_LABEL = 'health_medium_vulnerability_label';
export const LOW_LABEL = 'health_low_vulnerability_label';
export const FISHING_LABEL = 'health_training_failed_phishing_label';
export const OVERDUE_TRAINING_LABEL = 'health_training_missed_training_label';
export const MTTT_LABEL = 'health_training_mttt_days_label';
export const AVG_FREQ_LABEL = 'health_training_frequency_days_label';
export const TOTAL_EMPLOYEE_LABEL = 'report_tooling_total_employees_label';
export const ZSCALER_LABEL = 'provider_zscaler_name';
export const TOTAL_ENDPOINT_LABEL = 'report_tooling_total_endpoints_label';
export const CROWDSTRIKE_LABEL = 'provider_crowdstrike_name';
export const TENABLE_LABEL = 'provider_tenable_name';
export const TOTAL_EMPLOYEE_CTXLABEL = 'trend_increased_by';
export const ZSCALER_CTXLABEL = 'report_tooling_coverage_label';
export const TOTAL_ENDPOINT_CTXLABEL = 'trend_increased_by';
export const CROWDSTRIKE_CTXLABEL = 'report_tooling_coverage_label';
export const TENABLE_CTXLABEL = 'report_tooling_coverage_label';
export const TOTAL_FINDINGS_LABEL = 'engagement_total_findings';
export const TOTAL_ENGAGEMENTS_LABEL = 'engagement_total_engagements';
export const TIME_SAVED_LABEL = 'engagement_time_saved_hours';
export const DOLLARS_SAVED_LABEL = 'engagement_dollars_saved';

export const navItems = [
    {
        Icon: HealthDashboardIcon,
        text: 'Health Dashboard',
        link: 'dashboard/org',
    },
    {
        Icon: <BsFillShieldLockFill style={{ width: '1.5rem', height: '1.5rem' }} />,
        text: 'Security Findings',
        link: 'security-findings',
    },
    {
        Icon: FlowIcon,
        text: 'Flow Builder',
        link: 'workflow/active-flows',
    },
    {
        Icon: PoliciesIntegrationIcon,
        text: 'Integrations & Policies',
        link: 'policies',
    },
];

export const TEMP_USER_EMAIL = 'sam.maxwell@amplifiersecurity.com';

export const SEVERITY_TEXT = 'Severity';
export const DATE_TEXT = 'Date';
export const ISSUE_TYPE_TEXT = 'Issue Type';
export const NAME = 'Name';
export const ENGAGEMENT = 'Engagement';
export const STATUS = 'Status';
export const SECURITY_CONTEXT_TEXT = 'Security Training Deficiencies';
export const HEALTH_TRENDS_TEXT = 'HEALTH TREND';
export const FAILED_PHISHING = 'FAILED PHISHING';
export const MFA_COVERAGE_TITLE = 'MFA COVERAGE';
export const MFA_COVERAGE_SUB_TITLE = 'total applications';
export const SECURITY_HEALTH = 'SECURITY HEALTH';
export const SECURITY_HEALTH_SUBTITLE = '1,426 self-healed findings';
export const SECURITY_TRAINING_DEFICIENCIES = 'SECURITY TRAINING DEFICIENCIES';

export const ENGAGEMENT_VALUE_LABELS = ['TOTAL FINDINGS', 'TOTAL ENGAGEMENTS', 'TIME SAVED', 'DOLLARS SAVED'];
export const EngagementValuesLabelMap: Record<string, string> = {
    [TOTAL_FINDINGS_LABEL]: ENGAGEMENT_VALUE_LABELS[0],
    [TOTAL_ENGAGEMENTS_LABEL]: ENGAGEMENT_VALUE_LABELS[1],
    [TIME_SAVED_LABEL]: ENGAGEMENT_VALUE_LABELS[2],
    [DOLLARS_SAVED_LABEL]: ENGAGEMENT_VALUE_LABELS[3],
};

export const VulnerabilitiesLabelMap: Record<string, string> = {
    [CRITICAL_LABEL]: 'CRITICAL',
    [HIGH_LABEL]: 'HIGH',
    [MEDIUM_LABEL]: 'MEDIUM',
    [LOW_LABEL]: 'LOW',
};
export const ToolingCoverageLabelMap: Record<string, string> = {
    [TOTAL_EMPLOYEE_LABEL]: 'TOTAL EMPLOYEES',
    [ZSCALER_LABEL]: 'ZSCALER',
    [TOTAL_ENDPOINT_LABEL]: 'TOTAL ENDPOINTS',
    [CROWDSTRIKE_LABEL]: 'CROWDSTRIKE',
    [TENABLE_LABEL]: 'TENABLE',
};
export const TOP_RISK_CONTRIBUTORS_TITLE = 'TOP RISK CONTRIBUTORS';
export const ToolingCoverageCtxLabelMap: Record<string, string> = {
    [TOTAL_EMPLOYEE_CTXLABEL]: 'increased by',
    [ZSCALER_CTXLABEL]: 'Not logged in',
    // [TOTAL_ENDPOINT_CTXLABEL]: 'increased by',
    // [CROWDSTRIKE_CTXLABEL]: 'Not logged in',
    // [TENABLE_CTXLABEL]: 'Not logged in',
};

export const TrainingDeficiencyLabelMap: Record<string, string> = {
    [FISHING_LABEL]: 'FAILED PHISHING',
    [OVERDUE_TRAINING_LABEL]: 'OVERDUE TRAININGS',
};

export const PHISHING = 'FAILED PHISHING';
export const TRAINING = 'OVERDUE TRAININGS';

export const ERROR_MSG = ['Error loading details. ', 'Refresh'];
export const LOADING_MSG = 'LOADING...';
export const TOP_RISK_CONTRIBUTORS = 'TOP RISK CONTRIBUTORS';
export const RADAR_LABELS = ['MFA Coverage', 'SLA Vulnerabilities', 'Training', 'Tooling Coverage'];
export const EMPTY_LABELS = ['', '', '', ''];
export const TOOLTIP_CONTENT =
    'This represents the security health score of the organization, highlighting specific risk zones';

export const SAVE = 'Save As Default Filter';
export const RESET = 'Reset Default Filter';
export const SignInButtonsMaps = [
    {
        Icon: <BsGoogle size={21} />,
        text: 'Sign in with Google',
        id: 'Google',
    },
    {
        Icon: <ReactSVG src={MicrosoftSignInIcon} />,
        text: 'Sign in with Microsoft 365',
        id: 'Microsoft',
    },
    {
        Icon: <ReactSVG src={OktaSignInIcon} />,
        text: 'Sign in with Okta',
        id: 'Okta',
    },
];

export const SignInInfoText = `Self-healing technology that will engage & empower employees to amplify an organization's security health.`;
export const NOT_COVERAGE_TEXT = 'Not protected';

export const USER_TABLE_ROWS_PER_PAGE_OPTIONS = [20, 30, 40, 50];
export const SEARCHBAR = 'Search';
export const USERHEADER = ['Organization', 'Tenure', 'Department', 'Manager'];
export const rowsPerPageOptions = [20, 30, 40, 50];
export const USER_SECURITY_HEALTH = 'USER SECURITY HEALTH';
export const ORGANIZATION_SCORE = 'ORG SCORE';
export const DEPARTMENT_SCORE = 'DEPT SCORE';
export const DropdownItems = [
    { id: 0, value: 'EVERYONE' },
    { id: 1, value: 'EMPLOYEES ONLY' },
    { id: 2, value: '3RD PARTIES' },
];
export const EMPTY_TABLE_MESSAGE = `Oops! It seems like there is no data available at the moment`;
export const FINDINGS_TABLE_PAGINATION = [10, 20, 30, 40, 50];
export const HEADER_SCROLL_HEIGHT = '442px';
export const BACK = 'BACK';
export const DUPLICATE = 'DUPLICATE';
export const HISTORY = 'HISTORY';
export const SAVE_START = 'SAVE & START';
export const EngagemntFlowData = [{ label: DUPLICATE }, { label: HISTORY }, { label: SAVE_START }];

export const DEFAULT_TABLE_BODY_HEIGHT = '832px';
export const AUTH_TOKEN_MAP = {
    Google: process.env.AMP_TOKEN_01,
    Microsoft: process.env.AMP_TOKEN_02,
    Okta: process.env.AMP_TOKEN_03,
};

export const SIGN_IN_ERROR_TEXT = 'Sign in authentication failed.';
export const TRY_AGAIN = 'Try Again';
export const DASHBOARD_SIGN_IN = 'DASHBOARD SIGN IN';
export const GET_IN_TOUCH = 'GET IN TOUCH';

export const USER_SCORE_THRESHOLD_VALUES = {
    CRITICAL: 70,
    HIGH: 80,
    MEDIUM: 90,
};
export const FLOW_HEADER_TEXTS = ['Name', 'Target Users', 'Last Triggered', 'Active', 'Total', 'Status'];
export const FLOW_TABLE_HEIGHT = '496px';

export const CLEAR_TEXT = 'Clear';
export const APPLY_TEXT = 'Apply';
export const COMINGSOON = 'Amplifier is in Alpha';
export const SUBTEXT = ['We’re working hard behind the scenes to make every', 'employee a part of your security team'];
export const ENGAGEMENTVALUE = 'ENGAGEMENT VALUE';
export const INTEGRATIONS_FILTER_LIST = [
    'Identity Tools',
    'End point Tools',
    'Training Tools',
    'Vulnerability Tools',
    'Web gateway Tools',
    'Asset Mgmt Tools',
    'Messaging Tools',
];
//TODO: links are to be added
export const SIDEBAR = [
    {
        title: 'Terms of use',
        link: '',
    },
    {
        title: 'Privacy statement',
        link: '',
    },
    {
        title: 'Support',
        link: '',
    },
];
export const GOOGLE_SIGNIN = 'Google SSO';
export const INTEGRATED = 'Integrated';
export const GOOGLE_INTEGRATION_TABS = [
    {
        eventKey: 'overview',
        title: 'OVERVIEW',
    },
    {
        eventKey: 'installation guide',
        title: 'INSTALLATION GUIDE',
    },
];

export const OVERVIEW_TAB_CONTENT = [
    'Simplify and secure user logins to Amplifier from a trusted provider. Validate that users are using Google supported MFA',
    'and engage with them to remediate issues.',
];

export const INSTALLATION_GUIDE_CONTENT = ['PREREQUISITES', 'INSTRUCTIONS'];
export const ADD_INTEGRATION = 'Add Integration';
export const INTEGRATEDTOOLHEADERS = ['No Tools Integrated', 'Add Integration', 'Configure'];
export const TOASTMESSAGE = [
    'Helping you integrate more security tools at Amplifier.',
    'Let’s get started with integrating your first security tool!',
];
export const INTEGRATIONSTITLE = 'INTEGRATIONS';
export const INTEGRATIONSDATA = [
    { id: '1', imageUrl: IdentityTools, title: 'Identity Tools' },
    { id: '2', imageUrl: EndPointTools, title: 'End point Tools' },
    { id: '3', imageUrl: VulnerabilityTools, title: 'Vulnerability Tools' },
    { id: '4', imageUrl: WebGateWayTools, title: 'Web gateway Tools' },
    { id: '5', imageUrl: TrainingTools, title: 'Training Tools' },
    { id: '6', imageUrl: AssetMgmtTools, title: 'Asset Mgmt Tools' },
    { id: '7', imageUrl: MessagingTools, title: 'Messaging Tools' },
];
export const NOTFOUNDPAGE = [
    'INTEGRATION',
    '404',
    '- Page not found',
    'The link you followed probably broken or the page has been removed.',
];
export const ENGAGE_HEADER = 'ENGAGE WITH EMPLOYEES';
export const MEDIUM = 'Medium';
export const SEND = 'Send';
export const COPY_CISO = 'Copy CISO';
export const ENGAGE = 'Engage';
export const ADD = 'ADD';
export const ENGAGE_DROPDOWN = [
    {
        icon: Slack,
        text: 'Slack',
    },
];

export const DATA_GRID_SCROLL_HEIGHT = '45rem';
export const CONFIGURED = 'Configured';
export const NOT_CONFIGURED = 'Not Configured';
export const WARNING =
    'WARNING : Deleting this integration will stop syncing vulnerability data from tenable to the Amplifier platform.';
export const REMOVE = 'Remove';
export const REMOVE_INTEGRATION = 'Remove Integration';
export const GROUP_NAME = 'Group Name';
export const NUMBER_OF_EMPLOYEES = 'Number Of Employees';
export const DESCRIPTION = 'Description';
export const configurationTabs = [
    {
        eventKey: 'PRODUCTION ACCESS',
        title: 'PRODUCTION ACCESS',
        link: '/production-access',
    },
    {
        eventKey: 'PRIVILEGE ACCESS',
        title: 'PRIVILEGE ACCESS',
        link: '/privilege-access',
    },
    {
        eventKey: 'DEFINE EXECUTIVES',
        title: 'DEFINE EXECUTIVES',
        link: '/define-executives',
    },
];

export const IntegrationsDropdownItems = [
    { id: 0, value: 'User Groups' },
    { id: 1, value: 'Users' },
];
export const SOURCE_TYPE = 'source Type';
export const ADD_GROUPS = 'Add Groups';
export const ADD_USERS = 'Add Users';
export const DEPARTMENT = 'Department';
export const POSITION = 'Position';
export const ADD_DATA_GRID_HEIGHT = '28rem';
export const SELECTED_DATA_GRID_HEIGHT = '18.75rem';
export const INTEGRATIONHEADER = ['Status : ', 'Last time updated : ', ' minutes ago', ' ( Updates every 20 minutes )'];
export const INTEGRATIONSTATUSLABEL: Record<string, string> = {
    sync: 'Not Configured',
    disable: 'Disabled',
    error: 'Error',
    active: 'Active',
};
export const PAUSE = 'Pause All Notification';
export const TOGGLE_NOTIFICATION = 'Enable/Disable All Engagement Notifications for Your Organization';
export const COMPANY_PARAMETERS = 'Company Parameters';
export const SHARE_SECURITY_POSTURE = 'Share Security Posture';
export const SETTINGS = 'SETTINGS';
export const ADDED_DATE = 'Added Date';
export const EMAIL_ID = 'Email Id';
export const ORGANIZATION = 'Organization';
export const ACCESS = 'Access';
export const DATA_GRID_HEIGHT = '18.75rem';
export const SHARE_SECURITY_POSTURE_DATA = [
    {
        id: 1,
        name: 'Jakob Chrishop',
        addedDate: '2022-03-14T06:28:48Z',
        emailId: 'jchrishop0@amplifier.com',
        organization: 'Google',
        access: 'Admin',
        close: '',
    },
    {
        id: 2,
        name: 'Dosi Boodell',
        addedDate: '2022-03-14T06:28:48Z',
        emailId: 'jmarousekf@amplifier.com',
        organization: 'Microsoft',
        access: 'Admin',
        close: '',
    },
    {
        id: 3,
        name: 'Marlee Izzard',
        addedDate: '2022-03-14T06:28:48Z',
        emailId: 'dboodell1@amplifier.com',
        organization: 'Zemoso',
        access: 'Admin',
        close: '',
    },
    {
        id: 4,
        name: 'Jarred Ivatts',
        addedDate: '2022-03-14T06:28:48Z',
        emailId: 'mizzard2@amplifier.com',
        organization: 'Meta',
        access: 'Admin',
        close: '',
    },
    {
        id: 5,
        name: 'Dulsea Blackwood',
        addedDate: '2022-03-14T06:28:48Z',
        emailId: 'uroomeg@amplifier.com',
        organization: 'TCS',
        access: 'Admin',
        close: '',
    },
];

export const INVITE_USER = [
    { id: 1, imageUrl: Jakob, firstName: 'Jakob', lastName: 'Chrishop', email: 'jakobchrishop@amplifier.com' },
    {
        id: 2,
        imageUrl: Jakob,
        firstName: 'Savannah',
        lastName: 'Nguyen',
        email: 'savannah@amplifier.com',
    },
    { id: 3, firstName: 'Dianne', lastName: 'Russell', email: 'russell@amplifier.com' },
    { id: 4, imageUrl: Jakob, firstName: 'Esther', lastName: 'Howard', email: 'estherhoward@amplifier.com' },
    { id: 5, imageUrl: Jakob, firstName: 'Courtney', lastName: 'Henry', email: 'henry0@amplifier.com' },
];
export const INVITE = 'INVITE';
export const EMAIL = 'Email';
