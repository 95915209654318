import React from 'react';
import { Row, Col } from 'react-bootstrap';
import IconWithTypography from '../../molecules/IconWithTypography';
import CustomCard from '../../molecules/CustomCard';
import Typography from '../../atoms/Typography';
import ErrorHandlerAndLoaderWrapper from '../../../wrappers/ErrorHandlerandLoaderWrapper';
import { getApiData } from '../../../apis/library';
import { getEngagementValuesUrl } from '../../../core-utils/endPoints';
import { useApiData } from '../../../hooks/useApiData';
import {
    ENGAGEMENTVALUE,
    ENGAGEMENT_VALUE_LABELS,
    EngagementValuesLabelMap,
} from '../../../core-utils/constants/constants';
import { formatValue } from '../../../core-utils/helpers/helpers';
import './styles.scss';
import _ from 'lodash';

interface EngagementData {
    label: string;
    value?: number;
}

const EngagementValue = ({ label, value }: EngagementData) => (
    <div className="d-flex flex-column align-items-center engagement-card__vessel">
        <Typography variant="body1" color="text-medium-emphasis">
            {label}
        </Typography>
        <Typography variant="h3" color="text-high-emphasis">
            {value ? formatValue(label, value) : '-'}
        </Typography>
    </div>
);

const EngagementCard = () => {
    const { data, error, isLoading, refresh } = useApiData(
        getEngagementValuesUrl,
        getApiData,
        (dataItem: EngagementData) => {
            const updatedLabel = EngagementValuesLabelMap[dataItem.label] || dataItem.label;
            return { ...dataItem, label: updatedLabel };
        },
    );

    const renderEngagementData = (engagementData: EngagementData[]) => {
        return (
            <Row className="engagement-card__engagement-row d-flex justify-content-center">
                {ENGAGEMENT_VALUE_LABELS.map((label) => (
                    <Col key={label} xs={6} md={6} lg={6} data-testid="engagement-card-values">
                        <EngagementValue label={label} value={_.find(engagementData, { label: label })?.value} />
                    </Col>
                ))}
            </Row>
        );
    };
    return (
        <CustomCard
            cardType="oneThird"
            title={<IconWithTypography isRow label={ENGAGEMENTVALUE} labelColor="primary" />}
            content={
                <div className="engagement-card__error-container">
                    <ErrorHandlerAndLoaderWrapper
                        error={error}
                        isLoading={isLoading}
                        render={() => renderEngagementData(data)}
                        handleRefresh={refresh}
                    />
                </div>
            }
        />
    );
};

export default EngagementCard;
