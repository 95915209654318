import React from 'react';
import IconWithTypography from '../../molecules/IconWithTypography';
import Speedometer from '../../molecules/Speedometer';
import CustomCard from '../../molecules/CustomCard';
import ErrorHandlerAndLoaderWrapper from '../../../wrappers/ErrorHandlerandLoaderWrapper';
import { SECURITY_HEALTH, SECURITY_HEALTH_SUBTITLE } from '../../../core-utils/constants/constants';
import { getApiData } from '../../../apis/library';
import { getSecurityHealthUrl } from '../../../core-utils/endPoints';
import { useApiData } from '../../../hooks/useApiData';
import { securityHealth } from '../../../core-utils/types/types';
import './styles.scss';

const SecurityHealth = () => {
    const { data, error, isLoading, refresh } = useApiData(
        getSecurityHealthUrl,
        getApiData,
        (dataItem: securityHealth) => {
            return { ...dataItem };
        },
    );

    const SecurityHealthCard = () => {
        return <Speedometer value={Math.round(data[0]?.value || 0)} variant="organization" />;
    };

    return (
        <div className="security-health h-100" data-testid="security-health">
            <CustomCard
                cardType="oneThird"
                title={<IconWithTypography isRow={true} label={SECURITY_HEALTH} labelColor="primary" />}
                subTitle={SECURITY_HEALTH_SUBTITLE}
                content={
                    <div className="security-health__error-container d-flex align-items-center justify-content-center">
                        <ErrorHandlerAndLoaderWrapper
                            error={error}
                            isLoading={isLoading}
                            render={SecurityHealthCard}
                            handleRefresh={refresh}
                        />
                    </div>
                }
            />
        </div>
    );
};

export default SecurityHealth;
