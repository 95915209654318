import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

export const useApiObjectdata = <DataT, RenderT>(
    cacheKey: string,
    fetcher: any,
    // eslint-disable-next-line no-unused-vars
    transformer: (dataItem: DataT) => RenderT,
) => {
    const { data: apiData, error, isLoading, mutate } = useSWR(cacheKey, fetcher);
    const [data, setData] = useState<RenderT | null>(null);
    const computedData = useMemo(() => {
        if (!apiData) {
            return [];
        }

        if (!Array.isArray(apiData)) {
            return apiData;
        }

        const updatedData = apiData.map(
            (dataItem: DataT): RenderT => {
                return transformer(dataItem);
            },
        );

        return updatedData;
    }, [apiData]);

    useEffect(() => {
        setData(computedData);
    }, [apiData]);
    const refresh = () => {
        mutate();
    };
    return {
        data,
        error,
        isLoading,
        refresh,
    };
};
