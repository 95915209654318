import * as React from 'react';
import { Image } from 'react-bootstrap';
import Iminent from '../../../../public/assets/icons/Iminent.svg';
import Typography from '../../atoms/Typography';
import { COMINGSOON, SUBTEXT } from '../../../core-utils/constants/constants';
import './styles.scss';

const IminentCard = () => {
    return (
        <div
            className="iminent__iminent-container d-flex flex-column align-items-center justify-content-center bg-structural-CardBG"
            data-testid="iminent-card"
        >
            <Image src={Iminent} alt="Iminent" className="iminent__iminent-image" />
            <div className="iminent__iminent-banner">
                <Typography variant="h1" color="text-high-emphasis">
                    {COMINGSOON}
                </Typography>
                <Typography variant="body2" color="text-low-emphasis">
                    {SUBTEXT[0]}
                </Typography>
                <Typography variant="body2" color="text-low-emphasis">
                    {SUBTEXT[1]}
                </Typography>
            </div>
        </div>
    );
};

export default IminentCard;
