import { useMemo, useState } from 'react';
import Table from '../Table';
import { GridStateType, HandleTableFiltersProps } from '../../../core-utils/types/types';
import { getDataGridColumn } from '../../../core-utils/constants/dataGrid';
import {
    DATA_GRID_SCROLL_HEIGHT,
    ENGAGE,
    USER_TABLE_ROWS_PER_PAGE_OPTIONS,
} from '../../../core-utils/constants/constants';
import { getApiData } from '../../../apis/library';
import { getUsersListUrl } from '../../../core-utils/endPoints';
import { useApiData } from '../../../hooks/useApiData';
import { findingsTableProps } from '../FindingsTable';
import PageErrorHandlerAndLoaderWrapper from '../../../wrappers/PageErrorHandlerAndLoaderWrapper';
import EngageModal from '../EngageModal';
import { Button } from 'react-bootstrap';
import './styles.scss';

interface SubHeaderContentProps {
    isDisabled: boolean;
}

const SubHeaderContent = ({ isDisabled }: SubHeaderContentProps) => {
    const [show, setShow] = useState<boolean>(false);
    const handleClose = () => {
        setShow(false);
    };
    const handleEngageClick = () => {
        setShow(!show);
    };
    return (
        <div className="d-flex flex-column">
            <Button
                variant="primary"
                onClick={handleEngageClick}
                className="text-uppercase body1 data-grid__engage-button px-3 py-2"
                disabled={isDisabled}
            >
                {ENGAGE}
            </Button>
            <EngageModal show={show} handleClose={handleClose} handleSend={handleClose} />
        </div>
    );
};
const DataGrid = () => {
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const isDisabled = selectedRows?.length > 0 ? false : true;
    // Temporary implementation, will be removed when data-grid API is available
    const uid = '1';
    const transformer = (dataItem: findingsTableProps) => ({ ...dataItem });

    const [selectedFilters, setFilters] = useState<GridStateType>({
        sortColumn: 'severity',
        sortDirection: 'asc',
        filters: {},
    });

    const handleTableFilters = ({ sortColumn, sortDirection, filteredColumn, filters }: HandleTableFiltersProps) => {
        setFilters((prevFilters) => {
            const tempState = {
                ...prevFilters,
                filters: {
                    ...prevFilters.filters,
                    ...(filteredColumn ? { [filteredColumn]: filters } : {}),
                },
            };
            if (sortColumn === tempState.sortColumn) {
                tempState.sortDirection = sortDirection;
            } else if (sortColumn && sortDirection !== null) {
                tempState.sortColumn = sortColumn;
                tempState.sortDirection = sortDirection;
            }
            return tempState;
        });
    };

    const columns = useMemo(() => getDataGridColumn({ selectedFilters, handleTableFilters }), [selectedFilters]);

    const { data, isLoading, error } = useApiData(`${getUsersListUrl}/${uid}/issues`, getApiData, transformer);
    const onSelectedRowsChange = (selected: { allSelected: boolean; selectedCount: number; selectedRows: any[] }) => {
        setSelectedRows(selected.selectedRows);
    };
    return (
        <div className="d-flex align-items-center justify-content-center w-100 h-100 overflow-hidden">
            <PageErrorHandlerAndLoaderWrapper
                error={error}
                isLoading={isLoading}
                render={() => (
                    <Table
                        columns={columns}
                        rowsPerPageOptions={USER_TABLE_ROWS_PER_PAGE_OPTIONS}
                        selectableRows={true}
                        subHeader={true}
                        tableBodyHeight={DATA_GRID_SCROLL_HEIGHT}
                        fixedHeaderScrollHeight={DATA_GRID_SCROLL_HEIGHT}
                        data={data}
                        subHeaderVariant="with-button"
                        subHeaderContent={<SubHeaderContent isDisabled={isDisabled} />}
                        onSelectedRowsChange={onSelectedRowsChange}
                    />
                )}
            />
        </div>
    );
};

export default DataGrid;
