import Table from '../Table';
import { getApiData } from '../../../apis/library';
import { useApiData } from '../../../hooks/useApiData';
import { getUsersListUrl } from '../../../core-utils/endPoints';
import { USER_TABLE_ROWS_PER_PAGE_OPTIONS } from '../../../core-utils/constants/constants';
import { USERS_COLUMNS } from '../../../core-utils/constants/usersTable';
import { getColumnValues } from '../../../core-utils/helpers/helpers';
import PageErrorHandlerAndLoaderWrapper from '../../../wrappers/PageErrorHandlerAndLoaderWrapper';
import { capScoreValue } from '../../../core-utils/helpers/helpers';

const UserTableFixedHeaderScrollHeight = '854px';

interface UserData {
    id: number;
    firstName: string;
    lastName: string;
    metrics: {
        score: number;
        scoreDelta: number;
        rank: number;
    };
    department: string;
    position: string;
    startDate: string;
    riskContributors: string[];
    links: {
        _self: string;
        vulnerabilities: string;
        devices: string;
    };
}

const Users = () => {
    const { data, isLoading, error } = useApiData(getUsersListUrl, getApiData, (dataItem: UserData) => {
        return {
            id: dataItem.id,
            name: `${dataItem.firstName} ${dataItem.lastName} `,
            topRiskContributors: dataItem.riskContributors,
            score: capScoreValue(dataItem.metrics.score),
            department: dataItem.department,
            position: dataItem.position,
            tenure: new Date(dataItem.startDate),
            employeeType: 'Employee',
            image: undefined,
        };
    });

    const departmentOptions = getColumnValues(data, 'department');
    const positionOptions = getColumnValues(data, 'position');

    return (
        <div className="d-flex align-items-center justify-content-center w-100 h-100 overflow-hidden">
            <PageErrorHandlerAndLoaderWrapper
                error={error}
                isLoading={isLoading}
                render={() => (
                    <Table
                        columns={USERS_COLUMNS}
                        data={data}
                        defaultSortId={'score'}
                        fixedHeaderScrollHeight={UserTableFixedHeaderScrollHeight}
                        rowsPerPageOptions={USER_TABLE_ROWS_PER_PAGE_OPTIONS}
                        isLoading={isLoading}
                        tableBodyHeight={UserTableFixedHeaderScrollHeight}
                        //TODO: Needs to be integrated with api.
                        dropdownOptions={{
                            department: departmentOptions,
                            position: positionOptions,
                        }}
                    />
                )}
            />
        </div>
    );
};

export default Users;
