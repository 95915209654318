import persistPlugin from '@rematch/persist';
import { RematchDispatch, RematchRootState, init } from '@rematch/core';
import storage from 'redux-persist/lib/storage';
import { models } from './models';
import type { RootModel } from './models';

const persistStorage = {
    key: 'root',
    storage,
};

export const store = init<RootModel>({
    models: models,
    plugins: [persistPlugin(persistStorage)],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
