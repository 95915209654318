import { Col, Container, Row } from 'react-bootstrap';
import CustomTab from '../../molecules/CustomTab';
import { ReactNode } from 'react';
import { DropdownItems } from '../../../core-utils/constants/constants';
import DropdownComponent from '../../molecules/Dropdown';
import { TabProps } from '../../../core-utils/types/types';
import './styles.scss';

export interface DashboardProps {
    tabs: TabProps[];
    children: ReactNode;
}

const DashboardComponent = (props: DashboardProps) => {
    const DashboardTemplate = () => {
        return (
            <Container fluid className="d-grid gap-3 dashboard bg-structural-BG w-100">
                <Row className="dashboard__row">
                    <Col className="gap-3 dashboard__row">
                        <div className="dashboard__tab-row d-flex justify-content-between">
                            <CustomTab tabs={props.tabs} />
                            {/* TODO: To be integrated with API query param */}
                            <div className="dashboard__dropdown-container">
                                <DropdownComponent
                                    value={DropdownItems[0].value}
                                    dropDownItems={DropdownItems}
                                    variant="default"
                                />
                            </div>
                        </div>
                        <div className="dashboard__content-row w-100 pt-3 pb-3">{props.children}</div>
                    </Col>
                </Row>
            </Container>
        );
    };
    return <DashboardTemplate />;
};

export default DashboardComponent;
