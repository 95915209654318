import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { COPY_CISO, ENGAGE_DROPDOWN, ENGAGE_HEADER, MEDIUM, SEND } from '../../../core-utils/constants/constants';
import Typography from '../../atoms/Typography';
import './styles.scss';
import { useState, ChangeEvent } from 'react';
import { EngageDropdownObject } from '../../../core-utils/types/types';
import { ReactSVG } from 'react-svg';
import { FiChevronDown } from 'react-icons/fi';
interface EngageModalProps {
    show: boolean;
    handleClose: () => void;
    handleSend: (_text: string) => void;
}

interface DropdownSelectProps {
    value: EngageDropdownObject;
}

const DropdownSelect = (props: DropdownSelectProps) => {
    //This dropdown is disabled with medium as slack
    return (
        <Dropdown>
            <Dropdown.Toggle variant="primary" className="d-flex align-items-center engage-modal__dropdown">
                <ReactSVG src={props.value.icon} />
                <Typography variant="body5" color="text-low-emphasis">
                    {props.value.text}
                </Typography>
                <FiChevronDown className="text-text-low-emphasis ms-3" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="bg-structural-BG">
                {ENGAGE_DROPDOWN.map((item, index) => (
                    <div key={index} className="d-flex align-items-center">
                        <ReactSVG src={item.icon} />
                        <Typography variant="body5" color="text-low-emphasis">
                            {item.text}
                        </Typography>
                    </div>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

const EngageModal = (props: EngageModalProps) => {
    const [text, setText] = useState<string>('');

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };
    const handleSend = () => {
        props.handleSend(text);
        setText('');
    };
    return (
        <div>
            <Modal
                data-testid="modal"
                className="engage-modal"
                show={props.show}
                onHide={props.handleClose}
                aria-hidden="true"
            >
                <Modal.Header>
                    <Modal.Title>
                        <div className="d-flex align-items-center">
                            <Typography variant="h4" color="text-high-emphasis">
                                <span className="text-uppercase mb-0 ps-3 pt-1">{ENGAGE_HEADER}</span>
                            </Typography>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Typography variant="body3" color="text-high-emphasis">
                        {MEDIUM}
                    </Typography>
                    <DropdownSelect value={ENGAGE_DROPDOWN[0]} />
                    <Form.Control
                        as="textarea"
                        className="engage-modal__form mt-4 overflow-auto bg-body-color text-text-high-emphasis border-0 shadow-none custom-scrollbar"
                        onChange={handleTextChange}
                        value={text}
                    />
                    <div className="d-flex align-items-center justify-content-between">
                        <Form.Check
                            inline
                            type="checkbox"
                            label={COPY_CISO}
                            className="mt-3 text-text-high-emphasis body5 mb-2"
                        />
                        <div className="d-flex justify-content-end pt-1">
                            <Button
                                className="btn btn-primary-500 btn-lg body1 text-text-high-emphasis engage-modal__button text-uppercase"
                                disabled={text === ''}
                                onClick={handleSend}
                            >
                                {SEND}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default EngageModal;
