// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".custom-scrollbar{overflow:auto;scrollbar-width:thin;scrollbar-color:#67656e #30363d}.custom-scrollbar::-webkit-scrollbar{width:8px;height:8px}.custom-scrollbar::-webkit-scrollbar-track{background-color:#30363d !important}.custom-scrollbar::-webkit-scrollbar-thumb{background-color:#67656e !important;border-radius:5px}.custom-scrollbar::-webkit-scrollbar-thumb:hover{background-color:#67656e !important}.main-template{height:100vh}.main-template__row{height:100vh}.main-template__Col{padding-left:0rem;height:100%}.main-template__tab-row{padding-top:1.625rem}", "",{"version":3,"sources":["webpack://./src/theme/scroll.scss","webpack://./src/_components/templates/MainTemplate/styles.scss"],"names":[],"mappings":"AAEA,kBACI,aAAA,CACA,oBAAA,CACA,+BAAA,CAGJ,qCACI,SAAA,CACA,UAAA,CAGJ,2CACI,mCAAA,CAGJ,2CACI,mCAAA,CACA,iBAAA,CAGJ,iDACI,mCAAA,CCpBJ,eACI,YAAA,CACA,oBACI,YAAA,CAEJ,oBACI,iBAAA,CACA,WAAA,CAEJ,wBACI,oBAAA","sourcesContent":["@import './variables.scss';\n\n.custom-scrollbar {\n    overflow: auto;\n    scrollbar-width: thin;\n    scrollbar-color: $structural-stroke-200 $structural-stroke-500;\n}\n\n.custom-scrollbar::-webkit-scrollbar {\n    width: 8px;\n    height: 8px;\n}\n\n.custom-scrollbar::-webkit-scrollbar-track {\n    background-color: $structural-stroke-500 !important;\n}\n\n.custom-scrollbar::-webkit-scrollbar-thumb {\n    background-color: $structural-stroke-200 !important;\n    border-radius: 5px;\n}\n\n.custom-scrollbar::-webkit-scrollbar-thumb:hover {\n    background-color: $structural-stroke-200 !important;\n}\n","@import '../../../theme/variables.scss';\n@import '../../../theme/scroll.scss';\n\n.main-template {\n    height: 100vh;\n    &__row {\n        height: 100vh;\n    }\n    &__Col {\n        padding-left: 0rem;\n        height: 100%;\n    }\n    &__tab-row {\n        padding-top: 1.625rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
