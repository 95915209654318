import React from 'react';
import { Color } from '../../../core-utils/types/color';
import { TypographyVariant } from '../../../core-utils/types/Variant';
import classNames from 'classnames';

export type TypographyProps = {
    variant: TypographyVariant;
    children: React.ReactNode;
    color?: Color;
};

const Typography: React.FC<TypographyProps> = ({ variant, children, color }) => {
    const className = classNames(variant, {
        [`text-${color}`]: color,
    });
    return (
        <p className={className} data-testid="typography">
            {children}
        </p>
    );
};

export default Typography;
