import React from 'react';
import Card from 'react-bootstrap/Card';
import './styles.scss';
export interface Cardprops {
    cardType: 'oneThird' | 'twoThird';
    title: React.ReactNode;
    subTitle?: React.ReactNode;
    content?: React.ReactNode;
}

const CustomCard = (props: Cardprops) => {
    return props.cardType === 'oneThird' ? (
        <div className="card card--square" data-testid="card-variant1">
            <Card.Body className="card__body">
                <Card.Title className="card__title w-100">{props.title}</Card.Title>
                <Card.Subtitle className="card__subtitle">{props.subTitle}</Card.Subtitle>
                <Card.Text className="card__content">{props.content}</Card.Text>
            </Card.Body>
        </div>
    ) : (
        <Card className="card card--rectangle" data-testid="card-variant2">
            <Card.Body className="card__body">
                <Card.Title className="card__title">{props.title}</Card.Title>
                <Card.Text className="card__content">{props.content}</Card.Text>
            </Card.Body>
        </Card>
    );
};

export default CustomCard;
