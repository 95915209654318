import React, { useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { RiSearchLine } from 'react-icons/ri';
import classNames from 'classnames';
import './styles.scss';

interface SearchBarProps {
    placeholder?: string;
    handleChange?: (_event: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
}

const SearchBar = ({ placeholder = 'Search', handleChange, value }: SearchBarProps) => {
    const [isActive, setIsActive] = useState(false);

    const handleFocus = () => {
        setIsActive(true);
    };

    const handleBlur = () => {
        setIsActive(false);
    };

    const className = classNames('position-relative', 'search-container', {
        'bg-transparent text-primary': isActive,
    });

    return (
        <div className={className} data-testid="search-bar">
            <RiSearchLine className="search-container__search-icon position-absolute text-text-low-emphasis" />
            <FormControl
                className="search-container__search-input border-grey-50 rounded bg-transparent"
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
        </div>
    );
};

export default SearchBar;
