import './theme/main.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'Rdx/store';
import AppRoutes from './AppRoutes';
import MainTemplate from './_components/templates/MainTemplate';
import { PAGE_ROUTE, SIGN_IN } from './core-utils/routes';
import SignIn from './_components/pages/SignIn';
import { ProtectedRoute } from './core-utils/ProtectedRoutes';
import { SWRConfig } from 'swr';
import NotFoundPage from './_components/pages/NotFoundPage';

function App() {
    return (
        <SWRConfig value={{ provider: () => new Map() }}>
            <Provider store={store}>
                <BrowserRouter>
                    <div className="App d-flex">
                        <Routes>
                            <Route path={SIGN_IN} element={<SignIn />} />
                            <Route
                                path={PAGE_ROUTE}
                                element={
                                    <ProtectedRoute>
                                        <MainTemplate>
                                            <AppRoutes />
                                        </MainTemplate>
                                    </ProtectedRoute>
                                }
                            />

                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </div>
                </BrowserRouter>
            </Provider>
        </SWRConfig>
    );
}

export default App;
