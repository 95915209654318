import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { FiExternalLink } from 'react-icons/fi';
import Typography from '../../atoms/Typography';
import { TabProps } from '../../../core-utils/types/types';
import './styles.scss';

type SidebarTabProps = Omit<TabProps, 'eventKey'>;
interface SidebarProps {
    imageUrl: string;
    signInText: string;
    navLinks: SidebarTabProps[];
}

const SidebarNavLinks = (navLinksArr: SidebarTabProps[]) => {
    return (
        <>
            {navLinksArr.map((dataItem: SidebarTabProps) => (
                <div key={dataItem.title} className="d-flex gap-1 align-items-center">
                    <Link to={dataItem.link} className="sidebar__link">
                        <Typography variant="body1" color="primary-500">
                            {dataItem.title}
                        </Typography>
                    </Link>
                    <FiExternalLink className="sidebar__ext-icon" />
                </div>
            ))}
        </>
    );
};

const IntegrationSidebar = ({ imageUrl, signInText, navLinks }: SidebarProps) => (
    <div
        className="sidebar d-flex flex-column align-items-center justify-content-start pt-3 bg-structural-CardBG h-100 gap-4"
        data-testid="sidebar"
    >
        <div className="d-flex flex-column align-items-center gap-3 sidebar__icon">
            <ReactSVG src={imageUrl} className="d-flex align-items-center justify-content-center" />
            <Typography variant="subtitle4" color="text-high-emphasis">
                {signInText}
            </Typography>
        </div>
        <div className="d-flex gap-3 flex-column sidebar__typo-cont">{SidebarNavLinks(navLinks)}</div>
    </div>
);

export default IntegrationSidebar;
