import axios from 'axios';
import { AUTH_TOKEN_MAP } from '../core-utils/constants/constants';
import { store } from 'Rdx/store';
import { Navigate } from 'react-router-dom';

axios.defaults.baseURL = process.env.MOCK_PLATFORM_API_URL || 'http://localhost:8080';

export const getApiData = async (inputUrl: string) => {
    const token = process.env.REACT_MOCK_API_BEARER_TOKEN;
    // store.getState().auth.token;
    const ssoOption = store.getState().ssoModel.ssoOption;

    const state = ssoOption as keyof typeof AUTH_TOKEN_MAP | null;
    const tokenExists = token || state ? AUTH_TOKEN_MAP[state!] : '';
    if (!tokenExists) {
        <Navigate to="/signin" />;
    }

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const response = await axios.get(`/${inputUrl}`);
    return response.data.data;
};
