import { Models } from '@rematch/core';
import auth from './authModel';
import ssoModel from './ssoModel';

export interface RootModel extends Models<RootModel> {
    auth: typeof auth;
    ssoModel: typeof ssoModel;
}

const models: RootModel = { auth, ssoModel };

export default models;
