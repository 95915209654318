import Checkbox from '../../atoms/Checkbox';
import Typography from '../../atoms/Typography';
import { FilterComponentProps } from '../../../core-utils/types/types';

const CheckboxList = ({ options, handleOptions, selectedOptions }: FilterComponentProps) => {
    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id: option, checked: isChecked } = event.target;
        handleOptions((prevFilters: string[]) => {
            if (isChecked) {
                // Adding new filter to the filters state
                return [...prevFilters, option];
            }
            // Filtering unchecked filter from the filters state
            return prevFilters.filter((selectedOption) => selectedOption !== option);
        });
    };

    return (
        <>
            <div className="dropdown-header">
                <Typography variant="body5" color="text-high-emphasis">
                    Filter
                </Typography>
            </div>
            <form className="data-grid-dropdown__filters overflow-auto custom-scrollbar">
                {options.map((option) => (
                    <Checkbox
                        label={option}
                        key={option}
                        handleCheckbox={handleCheckbox}
                        checked={selectedOptions.includes(option)}
                    />
                ))}
            </form>
        </>
    );
};

export default CheckboxList;
