import React, { ReactNode } from 'react';
import IminentCard from './Iminent';

interface IminentContainerProps {
    children: ReactNode;
}

const IminentContainer = ({ children }: IminentContainerProps) => {
    return <div className="d-flex justify-content-center align-items-center iminent__iminent-vessel">{children}</div>;
};

const ComingSoon = () => {
    return (
        <IminentContainer>
            <div className="iminent__iminent-body">
                <IminentCard />
            </div>
        </IminentContainer>
    );
};

export default ComingSoon;
