import React from 'react';
import Typography from '../../atoms/Typography';
import { Button, Image } from 'react-bootstrap';
import IconWithTypography from '../../molecules/IconWithTypography';
import { INTEGRATIONHEADER, INTEGRATIONSTATUSLABEL } from '../../../core-utils/constants/constants';
import './styles.scss';

interface IntegrationHeaderProps {
    icon: string;
    label: string;
    updatedTime: string;
    buttonLabel?: string;
    status: 'disable' | 'error' | 'sync' | 'active';
    handleStatus?: () => void;
}

interface IntegrationHeaderButtonProps {
    buttonLabel: string;
    handleStatus?: () => void;
}

const IntegrationHeaderContent = ({ icon, label, updatedTime, status }: IntegrationHeaderProps) => (
    <div className="d-flex flex-row gap-3">
        <Image src={icon} />
        <div className="d-flex flex-column">
            <Typography variant="body11" color="text-high-emphasis">
                {label}
            </Typography>
            <div className="text-uppercase d-flex flex-row align-items-center gap-2">
                <Typography variant="body3" color="text-medium-emphasis">
                    {INTEGRATIONHEADER[0]}
                    {INTEGRATIONSTATUSLABEL[status]}
                </Typography>
                <div className={`integration-header__circle ${status}`}></div>
            </div>
            <div className="d-flex flex-row align-items-center gap-1">
                <Typography variant="subtitle3" color="text-medium-emphasis">
                    {INTEGRATIONHEADER[1]}
                </Typography>
                <Typography variant="body5" color="text-medium-emphasis">
                    {updatedTime}
                    {INTEGRATIONHEADER[2]}
                </Typography>
                <Typography variant="subtitle3" color="text-medium-emphasis">
                    {INTEGRATIONHEADER[3]}
                </Typography>
            </div>
        </div>
    </div>
);

const IntegrationHeaderButton = ({ buttonLabel, handleStatus }: IntegrationHeaderButtonProps) => (
    <div className="integration-header__column integration-header__button-container d-flex flex-row justify-content-end align-items-center">
        <Button className="btn" onClick={handleStatus}>
            <IconWithTypography
                isRow={true}
                label={buttonLabel}
                labelColor="structural-selected"
                labelVariant="body1"
            />
        </Button>
    </div>
);

const IntegrationHeader = (props: IntegrationHeaderProps) => {
    const { icon, label, updatedTime, buttonLabel, status, handleStatus } = props;

    return (
        <div className="integration-header d-flex flex-row justify-content-between align-items-center bg-text-black border border-structural-dark-stroke-400">
            <IntegrationHeaderContent icon={icon} label={label} updatedTime={updatedTime} status={status} />
            <IntegrationHeaderButton buttonLabel={buttonLabel!} handleStatus={handleStatus} />
        </div>
    );
};

export default IntegrationHeader;
