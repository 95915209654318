import { useState } from 'react';
import IntegrationHeader from '../../organisms/IntegrationHeader';
import BackButton from '../../molecules/BackButton';
import './styles.scss';
import { useLocation } from 'react-router-dom';

const VendorDetails = () => {
    const { icon, label } = useLocation().state;
    const [buttonLabel, setButtonLabel] = useState('DISABLE');
    const [status, setStatus] = useState<'active' | 'disable' | 'error' | 'sync'>('active');

    const handleStatus = () => {
        if (buttonLabel === 'ENABLE') {
            setButtonLabel('DISABLE');
            setStatus('active');
        } else {
            setButtonLabel('ENABLE');
            setStatus('disable');
        }
    };

    return (
        <div className="vendor-details-page">
            <BackButton />
            <div className="vendor-details-page__content d-flex flex-column g-3 bg-text-black">
                <IntegrationHeader
                    icon={icon}
                    label={label}
                    updatedTime="15"
                    status={status}
                    handleStatus={handleStatus}
                    buttonLabel={buttonLabel}
                />
            </div>
        </div>
    );
};

export default VendorDetails;
