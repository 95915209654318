import { Card } from 'react-bootstrap';
import Typography from '../../atoms/Typography';
import './styles.scss';
import Accordion from '../../atoms/Accordion';
import {
    COMPANY_PARAMETERS,
    TOGGLE_NOTIFICATION,
    PAUSE,
    SETTINGS,
    SHARE_SECURITY_POSTURE,
    DATA_GRID_HEIGHT,
    USER_TABLE_ROWS_PER_PAGE_OPTIONS,
    SHARE_SECURITY_POSTURE_DATA,
} from '../../../core-utils/constants/constants';
import Toggle from '../../molecules/ToggleTextPanel';
import { useState } from 'react';
import Table from '../../organisms/Table';
import { SHARE_SECURITY_POSTURE_TABLE } from '../../../core-utils/constants/shareSecurityPostureTable';
import InviteSubHeaderContent from './InvitesSubHeaderContent';

const SettingsPage = () => {
    const [isToggleOn, setIsToggleOn] = useState(false);

    const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsToggleOn(event.target.checked);
    };
    return (
        <div className="settings-page d-flex flex-column pt-4">
            <Typography variant="h4" color="text-high-emphasis">
                {SETTINGS}
            </Typography>
            <Card className="bg-text-black p-3 mt-4 d-flex">
                <Accordion title={COMPANY_PARAMETERS} isConfigurationRequired={false}>
                    <Toggle header={PAUSE} subText={TOGGLE_NOTIFICATION} checked={isToggleOn} onToggle={handleToggle} />
                </Accordion>
                <Accordion title={SHARE_SECURITY_POSTURE} isConfigurationRequired={false}>
                    <div className="w-100">
                        <Table
                            columns={SHARE_SECURITY_POSTURE_TABLE}
                            rowsPerPageOptions={USER_TABLE_ROWS_PER_PAGE_OPTIONS}
                            subHeader={true}
                            tableBodyHeight={DATA_GRID_HEIGHT}
                            fixedHeaderScrollHeight={DATA_GRID_HEIGHT}
                            data={SHARE_SECURITY_POSTURE_DATA}
                            subHeaderVariant="with-button"
                            subHeaderContent={<InviteSubHeaderContent />}
                            pagination={false}
                        />
                    </div>
                </Accordion>
            </Card>
        </div>
    );
};

export default SettingsPage;
