import { APPLY_TEXT, CLEAR_TEXT, RESET, SAVE } from '../../../core-utils/constants/constants';
import Typography from '../../atoms/Typography';
import { Color } from '../../../core-utils/types/color';
import './styles.scss';
import classNames from 'classnames';
import {
    FilterDropdownProps,
    FilterItemProps,
    ActionButtonProps,
    CheckboxProps,
    FilterButtonProps,
} from '../../../core-utils/types/types';
import { getTotalArrayLength } from '../../../core-utils/helpers/helpers';
import { Dropdown } from 'bootstrap';

const FilterDropdown = (props: FilterDropdownProps) => {
    const closeDropdown = () => {
        // Getting instance of the dropdown element to toggle/hide the dropdown menu.
        const dropdown = Dropdown.getOrCreateInstance(`#filter-dropdown`);
        dropdown.hide();
        // Destroys an element’s instance.
        dropdown.dispose();
    };
    return (
        <div className="menu">
            <FilterButton {...props} closeDropdown={closeDropdown} />
            {props.filterWithActions && <ActionButton {...props} />}
        </div>
    );
};

const FilterButton = ({
    items,
    options,
    selectedOptions,
    handleCheckboxChange,
    handleClearClick,
    handleApplyClick,
    closeDropdown,
}: FilterButtonProps) => {
    const backgroundColor: Color =
        selectedOptions && getTotalArrayLength(selectedOptions) > 0 ? 'structural-stroke-100' : 'text-black';

    const className = `btn btn-outline-primary dropdown-toggle no-after-content bg-${backgroundColor} menu__filter-button`;
    const dropdownMenuClasses = 'dropdown-menu menu__dropdown-filter-item bg-text-black';
    return (
        <>
            <button
                type="button"
                className={className}
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                id="filter-dropdown"
            >
                <div className="d-flex align-items-center justify-content-center">
                    <Typography variant="body4" color="primary-500">
                        Filter
                    </Typography>
                    {getTotalArrayLength(selectedOptions) > 0 && (
                        <div className="menu__count bg-text-interactive d-flex justify-content-center align-items-center caption2">
                            {getTotalArrayLength(selectedOptions)}
                        </div>
                    )}
                </div>
            </button>
            <ul className={dropdownMenuClasses}>
                {items.map(
                    (item: React.ReactNode, index: number) =>
                        item && (
                            <FilterItem
                                key={index}
                                text={item as string}
                                options={options[item.toString().toLowerCase()]}
                                selectedOptions={selectedOptions}
                                handleCheckboxChange={handleCheckboxChange}
                                handleClearClick={handleClearClick}
                                handleApplyClick={handleApplyClick}
                                closeDropdown={closeDropdown}
                            />
                        ),
                )}
            </ul>
        </>
    );
};

const Checkbox = ({ option, selectedOptions, handleCheckbox }: CheckboxProps) => {
    return (
        <div className="menu__submenu-style d-flex align-items-center justify-content-start text-align-center w-100">
            <input
                type="checkbox"
                className="form-check-input me-3 ms-3 custom-checkbox shadow-none"
                id={option}
                checked={Object.keys(selectedOptions).some((key) => {
                    return selectedOptions[key].includes(option);
                })}
                onChange={handleCheckbox}
            />
            <label
                htmlFor={option}
                className="form-label body2 menu__sub-label pt-2"
                onClick={(e) => e.stopPropagation()}
            >
                {option}
            </label>
        </div>
    );
};

const FilterItem = ({
    text,
    options,
    selectedOptions,
    handleCheckboxChange,
    handleClearClick,
    handleApplyClick,
    closeDropdown,
}: FilterItemProps) => {
    const handleApplyClickButton = () => {
        handleApplyClick();
        closeDropdown();
    };

    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const option = event.target.id;
        const isChecked = event.target.checked;

        handleCheckboxChange(option, isChecked, text);
    };

    const isDisabled = getTotalArrayLength(selectedOptions) === 0;

    if (options) {
        return (
            <li className="dropend body4">
                <div className="menu__dropdown-item w-100 no-after-content dropdown-toggle show">{text}</div>
                <div className="dropdown-menu bg-text-black menu__submenu" data-bs-toggle="hover">
                    <form className="menu__submenu-content custom-scrollbar">
                        {options.map((option: any) => (
                            <Checkbox
                                key={option}
                                option={option}
                                selectedOptions={selectedOptions}
                                handleCheckbox={handleCheckbox}
                            />
                        ))}
                    </form>
                    <div className="d-flex justify-content-around align-items-center rounded-bottom border-top border-structural-stroke-100 menu__buttons">
                        <button
                            type="button"
                            className={`btn bg-transparent border-0 d-flex align-items-center justify-content-center text-primary-500 body4 mb-3 menu__clear-button`}
                            onClick={handleClearClick(text)}
                            disabled={isDisabled}
                        >
                            {CLEAR_TEXT}
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary bg-primary-500 text-black body4 d-flex align-items-center justify-content-center mb-3 menu__apply-button"
                            onClick={handleApplyClickButton}
                            disabled={isDisabled}
                        >
                            {APPLY_TEXT}
                        </button>
                    </div>
                </div>
            </li>
        );
    }

    return (
        <li className={`menu__dropdown-item body4`} key={text}>
            {text}
        </li>
    );
};

const ActionButton = ({ selectedOptions, handleResetClick, handleSaveClick }: ActionButtonProps) => {
    const buttonsClassName = classNames({
        disabled: !Boolean(getTotalArrayLength(selectedOptions)),
    });
    return (
        <>
            <button
                type="button"
                className="btn btn-primary dropdown-toggle dropdown-toggle-split menu__arrow"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            />
            <ul className="dropdown-menu bg-text-black body2">
                <li className={`menu__arrow-item ${buttonsClassName}`} onClick={handleSaveClick}>
                    {SAVE}
                </li>
                <li className={`menu__arrow-item ${buttonsClassName}`} onClick={handleResetClick}>
                    {RESET}
                </li>
            </ul>
        </>
    );
};

export default FilterDropdown;
