import {
    ENGAGEMENT_FLOW_TABS,
    FINDINGS_TABLE_PAGINATION,
    HEADER_SCROLL_HEIGHT,
} from '../../../core-utils/constants/constants';
import CustomTab from '../../molecules/CustomTab';
import Table from '../../organisms/Table';
import { ENGAGEMENT_FLOW_TABLE_HEADER } from '../../../core-utils/constants/engagementFlowTable';
import { useApiData } from '../../../hooks/useApiData';
import { getApiData } from '../../../apis/library';
import { getEngagementFlowsUrl } from '../../../core-utils/endPoints';
import { FlowType } from '../../../core-utils/types/types';
import { Route, Routes } from 'react-router-dom';
import { ACTIVE_FLOWS_ROUTE, TEMPLATES_ROUTE } from '../../../core-utils/routes';
import NotFoundPage from '../NotFoundPage';
import PageErrorHandlerAndLoaderWrapper from '../../../wrappers/PageErrorHandlerAndLoaderWrapper';
import './styles.scss';

const renderTable = (data: FlowType[]) => {
    return (
        <Table
            columns={ENGAGEMENT_FLOW_TABLE_HEADER}
            data={data}
            rowsPerPageOptions={FINDINGS_TABLE_PAGINATION}
            subHeader={false}
            fixedHeaderScrollHeight={HEADER_SCROLL_HEIGHT}
            tableBodyHeight={HEADER_SCROLL_HEIGHT}
            defaultSortId="title"
        />
    );
};

function EngagementFlowPage() {
    const { data, error, isLoading, refresh } = useApiData(getEngagementFlowsUrl, getApiData, (dataItem: FlowType) => {
        return { ...dataItem };
    });

    const FlowBuilder = () => {
        return (
            <div className="d-grid flex-column engagement-flow__content">
                <div className="engagement-flow__tab-cont">
                    <CustomTab tabs={ENGAGEMENT_FLOW_TABS} />
                </div>
                <Routes>
                    <Route
                        path={ACTIVE_FLOWS_ROUTE}
                        element={
                            <PageErrorHandlerAndLoaderWrapper
                                error={error}
                                isLoading={isLoading}
                                render={() => renderTable(data)}
                                handleRefresh={refresh}
                            />
                        }
                    />
                    {/* TODO:To be changed with Templates tab */}
                    <Route path={TEMPLATES_ROUTE} element={<div>Templates</div>} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </div>
        );
    };

    return <FlowBuilder />;
}

export default EngagementFlowPage;
