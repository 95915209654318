import './styles.scss';

import { useMemo, useState } from 'react';
import { BsFilter } from 'react-icons/bs';
import { Dropdown } from 'bootstrap';

import FilterActionButtons from '../FilterActionButtons';
import CheckboxList from '../CheckboxList';
import Typography from '../../atoms/Typography';
import { DataGridHeaderFilterProps, SortType } from '../../../core-utils/types/types';
import SortDropdown from '../SortDropdown';

const DataGridHeaderFilter = (props: DataGridHeaderFilterProps) => {
    const { text, sortable, filterable, handleTableFilters, columnName, position = 'start' } = props;

    const [sortDirection, setSortDirection] = useState<SortType>(null);
    const [filters, setFilters] = useState<string[]>([]);

    const disabled = useMemo(() => {
        return !Boolean(filters.length || sortDirection);
    }, [sortDirection, filters]);

    const buttonTextVariant =
        (sortable && props.selectedSortDirection) || (filterable && props.selectedFilterOptions.length)
            ? 'body1'
            : 'body5';

    const closeDropdown = () => {
        const dropdown = Dropdown.getOrCreateInstance(`#grid-head-filter-dropdown-${columnName}`);
        dropdown.hide();
        dropdown.dispose();
    };

    const handleSort = (currentSortDirection: SortType) => setSortDirection(currentSortDirection);

    const handleClear = () => {
        setFilters([]);
        setSortDirection(null);
        handleTableFilters({
            sortColumn: sortable ? columnName : null,
            filteredColumn: filterable ? columnName : null,
            filters: [],
            sortDirection: null,
        });
        closeDropdown();
    };

    const handleApply = () => {
        handleTableFilters({
            sortColumn: sortable ? columnName : null,
            filteredColumn: filterable ? columnName : null,
            filters,
            sortDirection,
        });
        closeDropdown();
    };

    const handleToggle = () => {
        sortable && setSortDirection(props.selectedSortDirection);
        filterable && setFilters(props.selectedFilterOptions);
    };

    return (
        <div className={`grid-head-filter text-center d-flex justify-content-${position} w-100`}>
            <div className="dropdown" id={`grid-head-filter-dropdown-${columnName}`}>
                <button
                    className="btn dropdown-toggle no-after-content border-0 p-0"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    onClick={handleToggle}
                >
                    <Typography variant={buttonTextVariant}>
                        {text} <BsFilter />
                    </Typography>
                </button>

                <ul className="dropdown-menu data-grid-dropdown dropdown-menu-dark bg-structural-CardBG text-text-high-emphasis p-0">
                    {sortable && <SortDropdown sortDirection={sortDirection} handleSort={handleSort} />}
                    {sortable && filterable && <hr className="dropdown-divider bg-structural-badge-bg m-0" />}
                    {filterable === true && (
                        <CheckboxList
                            options={props.filterOptions}
                            handleOptions={setFilters}
                            selectedOptions={filters}
                        />
                    )}
                    {(sortable || filterable) && <hr className="dropdown-divider bg-structural-badge-bg m-0" />}
                    <FilterActionButtons handleClear={handleClear} handleApply={handleApply} disabled={disabled} />
                </ul>
            </div>
        </div>
    );
};

export default DataGridHeaderFilter;
