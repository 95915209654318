import { Modal } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import DataGrid from '../DataGrid';
import './styles.scss';

interface DataGridModalProps {
    title: string;
    handleClose: () => void;
    show: boolean;
}

const DataGridModal = ({ title, handleClose, show }: DataGridModalProps) => {
    return (
        <Modal show={show} className="datagrid-modal w-100" centered onHide={handleClose}>
            <Modal.Header className="text-text-high-emphasis">
                <Modal.Title className="h6">{title}</Modal.Title>
                <IoClose className="datagrid-modal__icon" size={20} onClick={handleClose} />
            </Modal.Header>
            <Modal.Body className="p-0 w-0">
                <DataGrid />
            </Modal.Body>
        </Modal>
    );
};

export default DataGridModal;
