import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { RiArrowDownSLine } from 'react-icons/ri';
import Typography from '../../atoms/Typography';
import classNames from 'classnames';
import './styles.scss';

interface MenuItem {
    id: number | string;
    value: string;
}

interface DropdownComponentProps {
    value: string;
    dropDownItems: MenuItem[];
    variant?: 'filled' | 'default';
    onChange?: (_eventKey: string | null) => void;
}

const DropdownComponent = ({ value, dropDownItems, variant = 'filled', onChange }: DropdownComponentProps) => {
    const [selectedItem, setSelectedItem] = useState(value);
    const isDefault = variant === 'default';

    const handleSelect = (selectedKey: string | null) => {
        if (selectedKey) {
            setSelectedItem(selectedKey);
            if (onChange) {
                onChange(selectedKey);
            }
        }
    };

    const typographyVariant = isDefault ? 'body1' : 'body10';
    const itemTypographyVariant = isDefault ? 'body2' : 'caption1';
    const arrowIconColor = isDefault ? 'text-black' : 'text-high-emphasis';

    return (
        <div className={classNames('dropdown', 'w-100', variant)} data-testid="dropdown">
            <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle
                    className={classNames(
                        'd-flex',
                        'flex-row',
                        'justify-content-between',
                        'align-items-center',
                        'w-100',
                    )}
                >
                    <Typography variant={typographyVariant} color={arrowIconColor}>
                        {selectedItem}
                    </Typography>
                    <RiArrowDownSLine className={classNames('dropdown__dropdown-icon', arrowIconColor)} />
                </Dropdown.Toggle>

                <Dropdown.Menu
                    className={classNames(
                        'p-0',
                        'w-100',
                        'bg-body-color',
                        'border-text-medium-emphasis',
                        'dropdown-menu',
                    )}
                >
                    {dropDownItems.map((item) => (
                        <Dropdown.Item
                            key={item.id}
                            eventKey={item.value}
                            className={classNames('dropdown__menu-item', 'd-flex', 'align-items-center')}
                            disabled={isDefault && item.value !== 'EVERYONE'}
                        >
                            <Typography variant={itemTypographyVariant}>{item.value}</Typography>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default DropdownComponent;
