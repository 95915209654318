import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Chip from '../../_components/atoms/Chip';
import Typography from '../../_components/atoms/Typography';
import { ACTIVE_WORKFLOWS_ROUTE } from '../routes';
import { FlowType } from '../types/types';
import { FLOW_HEADER_TEXTS } from './constants';

export const ENGAGEMENT_FLOW_TABLE_HEADER: TableColumn<FlowType>[] = [
    {
        id: 'title',
        name: (
            <Typography variant="body2" color="text-black">
                {FLOW_HEADER_TEXTS[0]}
            </Typography>
        ),
        sortable: true,
        sortFunction: (a, b) => {
            return a.title > b.title ? 1 : -1;
        },
        style: {
            cursor: 'pointer',
        },
        cell: (row: FlowType) => {
            return (
                <Link to={`${ACTIVE_WORKFLOWS_ROUTE}/${row.id}`} className="engagement-title text-decoration-none">
                    <Typography variant="body5" color="text-high-emphasis">
                        {row.title}
                    </Typography>
                </Link>
            );
        },
    },
    {
        id: 'target',
        name: (
            <Typography variant="body2" color="text-black">
                {FLOW_HEADER_TEXTS[1]}
            </Typography>
        ),
        sortable: true,
        sortFunction: (a, b) => {
            return a.target > b.target ? 1 : -1;
        },
        cell: (row: FlowType) => (
            <Typography variant="body5" color="text-high-emphasis">
                {row.target}
            </Typography>
        ),
    },
    {
        id: 'triggeredAt',
        name: (
            <Typography variant="body2" color="text-black">
                {FLOW_HEADER_TEXTS[2]}
            </Typography>
        ),
        sortable: true,
        sortFunction: (a, b) => {
            return a.metrics.triggeredAt > b.metrics.triggeredAt ? 1 : -1;
        },
        selector: (row: FlowType) => row.metrics.triggeredAt,
        cell: (row: FlowType) => (
            <Typography variant="body5" color="text-high-emphasis">
                {row.metrics.triggeredAt}
            </Typography>
        ),
    },
    {
        id: 'activeCount',
        name: (
            <div className="w-100 text-center">
                <Typography variant="body2" color="text-black">
                    {FLOW_HEADER_TEXTS[3]}
                </Typography>
            </div>
        ),
        center: true,
        sortable: true,
        sortFunction: (a, b) => {
            return a.metrics.activeCount > b.metrics.activeCount ? 1 : -1;
        },
        selector: (row: FlowType) => row.metrics.activeCount,
        cell: (row: FlowType) => (
            <Typography variant="body5" color="text-high-emphasis">
                {row.metrics.activeCount}
            </Typography>
        ),
    },
    {
        id: 'total',
        name: (
            <div className="w-100 text-center">
                <Typography variant="body2" color="text-black">
                    {FLOW_HEADER_TEXTS[4]}
                </Typography>
            </div>
        ),
        center: true,
        sortable: true,
        sortFunction: (a, b) => {
            return a.metrics.total > b.metrics.total ? 1 : -1;
        },
        selector: (row: FlowType) => row.metrics.total,
        cell: (row: FlowType) => (
            <Typography variant="body5" color="text-high-emphasis">
                {row.metrics.total}
            </Typography>
        ),
    },
    {
        id: 'status',
        name: (
            <Typography variant="body2" color="text-black">
                {FLOW_HEADER_TEXTS[5]}
            </Typography>
        ),
        sortable: true,
        sortFunction: (a, b) => {
            return a.status > b.status ? 1 : -1;
        },
        selector: (row: FlowType) => row.status,
        cell: (row: FlowType) => <Chip value={row.status} variant="status" />,
    },
];
