import React from 'react';
import { Button, Image } from 'react-bootstrap';
import arch from '../../../../public/assets/icons/arch.svg';
import clock from '../../../../public/assets/icons/clock.svg';
import departmentIcon from '../../../../public/assets/icons/department.svg';
import human from '../../../../public/assets/icons/person.svg';
import IconWithTypography from '../../molecules/IconWithTypography';
import Typography from '../../atoms/Typography';
import { USERHEADER } from '../../../core-utils/constants/constants';
import Chip from '../../atoms/Chip';
import Profile from '../../../../public/assets/icons/profile.svg';
import Avatar from '../../atoms/Avatar';
import Person from '../../../../public/assets/icons/person1.svg';
import Arrow from '../../../../public/assets/icons/arrow.svg';
import { dateCalculation } from '../../../core-utils/helpers/helpers';
import './styles.scss';

interface UserHeaderProps {
    userId?: string;
    firstName: string;
    lastName: string;
    department: string;
    position: string;
    startDate: string;
}
interface UserContentHeaderProps {
    firstName: string;
    lastName: string;
    position: string;
}
const UserContentHeader = ({ firstName, lastName, position }: UserContentHeaderProps) => {
    return (
        <div className="user-header__column d-flex flex-column">
            <div className="d-flex flex-row align-items-center">
                <div className="user-header__avatar-icon">
                    <Avatar
                        height="60px"
                        width="60px"
                        image={Person}
                        variant="avatar"
                        name={`${firstName} ${lastName}`}
                    />
                </div>
                <div className="user-header__column d-flex flex-column">
                    <div className="user-header__header-container d-flex">
                        <Typography variant="h4" color="text-high-emphasis">
                            {`${firstName} ${lastName}`}
                        </Typography>
                    </div>
                    <div className="d-flex">
                        <Typography variant="body4" color="text-medium-emphasis">
                            {position}
                        </Typography>
                    </div>
                    <div className="d-flex">
                        <div className="user-header__chip-style">
                            <Chip value="Critical Risk" variant="employee_risk_variant" />
                        </div>
                        <Chip value="Employee" image={Profile} variant="user_score" />
                    </div>
                </div>
            </div>
        </div>
    );
};
interface startDateProp {
    startDate: string;
}
const UserHeaderContentValues = ({ startDate }: startDateProp) => {
    const userHeaderValues = [
        {
            icon: <Image src={arch} className="user-header__icon" />,
            labelIndex: 0,
            value: 'Amplifier Security',
        },
        {
            icon: <Image src={clock} className="user-header__icon" />,
            labelIndex: 1,
            value: dateCalculation(new Date(startDate), new Date()),
        },
    ];

    return (
        <div className="user-header__column user-header__value-container d-flex flex-column">
            {userHeaderValues.map((userInfo, index) => (
                <div key={index} className="user-header__item d-flex flex-row align-items-center">
                    {renderUserInfo(userInfo.icon, userInfo.labelIndex, userInfo.value)}
                </div>
            ))}
        </div>
    );
};

const ButtonComponent = () => {
    return (
        <div className="user-header__column user-header__button-container d-flex flex-row justify-content-end align-items-center">
            <Button className="btn" disabled>
                <IconWithTypography
                    endIcon={<Image src={Arrow} alt="dropdown-arrow" className="user-header__dropdown-icon" />}
                    isRow={true}
                    label="ACTION"
                    labelColor="structural-selected"
                    labelVariant="body2"
                />
            </Button>
        </div>
    );
};

const renderUserInfo = (icon: React.ReactNode, labelIndex: number, value: string) => {
    return (
        <div className="user-header__item  d-flex flex-row align-items-center">
            <div className="user-header__styled-category d-flex justify-content-between">
                <IconWithTypography
                    isRow={true}
                    startIcon={icon}
                    label={USERHEADER[labelIndex]}
                    labelColor="text-medium-emphasis"
                    labelVariant="body3"
                />
                <div className="colon">
                    <Typography variant="body3" color="text-medium-emphasis" children=":" />
                </div>
            </div>
            <Typography variant="body2" color="text-medium-emphasis" children={value} />
        </div>
    );
};

interface departmentProp {
    department: string;
}
const UserDepartmentValues = ({ department }: departmentProp) => {
    const departmentValues = [
        {
            icon: <Image src={departmentIcon} className="user-header__icon" />,
            labelIndex: 2,
            value: department,
        },
        {
            icon: <Image src={human} className="user-header__icon" />,
            labelIndex: 3,
            value: 'Leslie Alexander',
        },
    ];
    return (
        <div className="user-header__column user-header__value-container d-flex flex-column">
            {departmentValues.map((userInfo, index) => (
                <div key={index} className="user-header__item d-flex flex-row align-items-center">
                    {renderUserInfo(userInfo.icon, userInfo.labelIndex, userInfo.value)}
                </div>
            ))}
        </div>
    );
};

const UserHeader = ({ firstName, lastName, department, position, startDate }: UserHeaderProps) => {
    return (
        <div className="user-header__container d-flex bg-text-black">
            <UserContentHeader firstName={firstName} lastName={lastName} position={position} />
            <div className="user-header__divider bg-structural-stroke-100" />
            <UserDepartmentValues department={department} />
            <div className="user-header__divider bg-structural-stroke-100" />
            <UserHeaderContentValues startDate={startDate} />
            <ButtonComponent />
        </div>
    );
};

export default UserHeader;
