import ReactApexChart from 'react-apexcharts';
import { lineGraphData } from '../../../core-utils/constants/charts';
import './styles.scss';
export interface LineGraphProps {
    series: number[];
}

const LineGraph = ({ series }: LineGraphProps) => {
    const roundedSeries = series.map((value) => Math.round(value));

    const chartData = lineGraphData({
        series: roundedSeries,
    });

    return (
        <div id="line-chart" className="linegraph">
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="line"
                width="100%"
                data-testid="line-graph"
            />
        </div>
    );
};

export default LineGraph;
