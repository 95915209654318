import React from 'react';
import { ERROR_MSG } from '../core-utils/constants/constants';
import ErrorState from '../_components/molecules/ErorrState';
import Loader from '../_components/molecules/Loader';

type ErrorHandlerAndLoaderWrapperProps = {
    error: any;
    isLoading: any;
    render: () => React.ReactNode;
    handleRefresh?: React.MouseEventHandler<HTMLButtonElement>;
};

const ErrorHandlerAndLoaderWrapper = ({
    error,
    isLoading,
    render,
    handleRefresh,
}: ErrorHandlerAndLoaderWrapperProps) => {
    if (isLoading) {
        return <Loader />;
    }
    if (error) {
        return (
            <ErrorState primaryMessage={ERROR_MSG[0]} secondaryMessage={ERROR_MSG[1]} onRefreshClick={handleRefresh} />
        );
    }

    return <>{render()}</>;
};

export default ErrorHandlerAndLoaderWrapper;
