import './styles.scss';
import Typography from '../../atoms/Typography';
import classNames from 'classnames';

interface IntegrationFilterDataProps {
    text: string;
    count: number;
}

interface IntegrationFilterProps {
    data: IntegrationFilterDataProps[];
    activeFilter: string;
    onClickHandler: (_filter: string) => void;
}

const IntegrationList = ({
    data,
    activeFilter,
    onClickHandler,
}: Pick<IntegrationFilterProps, 'data' | 'activeFilter'> & {
    onClickHandler: (_filter: string) => void;
}) => (
    <>
        {data?.map(({ text, count }, index) => {
            const listItemClassName = classNames('list-group-item bg-transparent border-0 text-text-high-emphasis', {
                active: activeFilter === text,
            });

            return (
                <li className={listItemClassName} onClick={() => onClickHandler(text)} key={`list-group-${index}`}>
                    <div className="d-flex justify-content-between">
                        <Typography variant="body2">{text}</Typography>
                        <Typography variant="body2">{count}</Typography>
                    </div>
                </li>
            );
        })}
    </>
);

const IntegrationsFilter = ({ data, onClickHandler: handler, activeFilter }: IntegrationFilterProps) => {
    const onClickHandler = (selectedFilter: string) => handler(selectedFilter);

    return (
        <ul className="list-group bg-structural-CardBG integration-filter">
            <IntegrationList data={data} activeFilter={activeFilter} onClickHandler={onClickHandler} />
        </ul>
    );
};

export default IntegrationsFilter;
