import { TableColumn } from 'react-data-table-component';
import Typography from '../../_components/atoms/Typography';
import { ShareSecurityPostureType } from '../types/types';
import { ACCESS, ADDED_DATE, EMAIL_ID, NAME, ORGANIZATION } from './constants';
import { IoClose } from 'react-icons/io5';

export const SHARE_SECURITY_POSTURE_TABLE: TableColumn<ShareSecurityPostureType>[] = [
    {
        id: 'name',
        name: <Typography variant="body2">{NAME}</Typography>,
        sortable: false,
        wrap: true,
        cell: (row: ShareSecurityPostureType) => {
            return <Typography variant="subtitle3">{row.name}</Typography>;
        },
    },
    {
        id: 'addedDate',
        name: <Typography variant="body2">{ADDED_DATE}</Typography>,
        sortable: false,
        wrap: true,
        cell: (row: ShareSecurityPostureType) => {
            return <Typography variant="subtitle3">{row.addedDate}</Typography>;
        },
    },
    {
        id: 'emailId',
        name: <Typography variant="body2">{EMAIL_ID}</Typography>,
        sortable: false,
        wrap: true,
        cell: (row: ShareSecurityPostureType) => {
            return <Typography variant="subtitle3">{row.emailId}</Typography>;
        },
    },
    {
        id: 'organization',
        name: <Typography variant="body2">{ORGANIZATION}</Typography>,
        sortable: false,
        wrap: true,
        cell: (row: ShareSecurityPostureType) => {
            return <Typography variant="subtitle3">{row.organization}</Typography>;
        },
    },
    {
        id: 'access',
        name: <Typography variant="body2">{ACCESS}</Typography>,
        sortable: false,
        wrap: true,
        cell: (row: ShareSecurityPostureType) => {
            return <Typography variant="subtitle3">{row.access}</Typography>;
        },
    },
    {
        id: 'close',
        name: '',
        sortable: false,
        wrap: true,
        width: '68px',
        cell: () => {
            return <IoClose size={20} />;
        },
    },
];
