import { AiOutlineClose } from 'react-icons/ai';
import { ADD_INTEGRATION, SIDEBAR } from '../../../core-utils/constants/constants';
import Typography from '../../atoms/Typography';
import { IoArrowBackOutline } from 'react-icons/io5';
import IntegrationTabs from '../IntegrationTabs';
import Sidebar from '../../molecules/IntegrationSidebar';
import { Modal } from 'react-bootstrap';
import './styles.scss';

interface AddIntegrationModalProps {
    show: boolean;
    handleClose: () => void;
    overviewTabContent: string;
    intallationInstructions: string;
    imageUrl: string;
    signInText: string;
    handleBack: () => void;
    handleIntegration: () => void;
}

const AddIntegrationModal = ({
    show,
    handleClose,
    overviewTabContent,
    intallationInstructions,
    imageUrl,
    signInText,
    handleBack,
    handleIntegration,
}: AddIntegrationModalProps) => {
    return (
        <Modal
            data-testid="modal"
            className="add-integration"
            show={show}
            aria-hidden="true"
            centered
            onHide={handleClose}
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="d-flex align-items-center">
                        <IoArrowBackOutline onClick={handleBack} className="text-text-high-emphasis" />
                        <Typography variant="h4" color="text-high-emphasis">
                            <span className="text-uppercase mb-0 ps-3 pt-1">{ADD_INTEGRATION}</span>
                        </Typography>
                    </div>
                </Modal.Title>
                <AiOutlineClose onClick={handleClose} className="text-text-high-emphasis add-integration__icon" />
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="d-flex">
                    <Sidebar imageUrl={imageUrl} signInText={signInText} navLinks={SIDEBAR} />
                    <IntegrationTabs
                        overviewTabContent={overviewTabContent}
                        intallationInstructions={intallationInstructions}
                        handleIntegration={handleIntegration}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddIntegrationModal;
