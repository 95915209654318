// import { Col, Row } from 'react-bootstrap';
import ToolingCoverage from '../../organisms/ToolingCoverage';
import { HealthMetrix } from '../../../core-utils/types/types';
import Vulnerability from '../../organisms/Vulnerabilities';
import SecurityTrainingDeficiencies from '../../organisms/SecurityTrainingDeficiencies';
import SecurityHealth from '../../organisms/SecurityHealth';
import MfaCoverageCard from '../../organisms/MfaCoverageCard';
import LineGraphCard from '../../organisms/LineGraphCard';
import TopRiskContributors from '../../organisms/TopRiskContributors';
import EngagementCard from '../EngagementValueCard';
import './style.scss';

export interface OrganisationProps {
    vulnerabilities: HealthMetrix[];
    toolingCoverage: HealthMetrix[];
    securityTrainingDeficiencies: HealthMetrix[];
}

const Organisation = () => {
    return (
        <div className="org-dash d-grid gap-3 h-100 w-100">
            <div className="org-dash__row row">
                <div className="col-4">
                    <SecurityHealth />
                </div>
                <div className="col-4">
                    <LineGraphCard />
                </div>
                <div className="col-4">
                    <TopRiskContributors />
                </div>
            </div>
            <div className="org-dash__row row">
                <div className="col-8">
                    <ToolingCoverage />
                </div>
                <div className="col-4">
                    <Vulnerability />
                </div>
            </div>
            <div className="org-dash__row row">
                <div className="col-4">
                    <MfaCoverageCard />
                </div>
                <div className="col-4">
                    <SecurityTrainingDeficiencies />
                </div>
                <div className="col-4">
                    <EngagementCard />
                </div>
            </div>
        </div>
    );
};

export default Organisation;
