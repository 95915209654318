import { Card } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import Typography from '../../atoms/Typography';
import Chip from '../../atoms/Chip';
import { INTEGRATED } from '../../../core-utils/constants/constants';
import classnames from 'classnames';
import './styles.scss';

interface VendorCardProps {
    id: number;
    imageUrl: string;
    title: string;
    subTitle?: string;
    subText: string;
    isIntegrated: boolean;
    handleCard: (_id: number) => void;
}

const VendorCard = ({ id, imageUrl, title, subTitle, subText, isIntegrated = false, handleCard }: VendorCardProps) => {
    const cardClassName = classnames('vendor-card', {
        disabled: isIntegrated,
    });

    return (
        <div className="vendor-card" data-testid="vendor-card">
            {isIntegrated && <Chip value={INTEGRATED} variant="integrated_card_variant" />}
            <Card className={cardClassName} onClick={() => handleCard(id)}>
                <div className="vendor-card d-flex flex-column align-items-center">
                    <div className="vendor-card__icon">
                        <ReactSVG src={imageUrl} className="d-flex align-items-center justify-content-center" />
                    </div>
                    <Typography variant="subtitle5" color="text-high-emphasis">
                        {title}
                    </Typography>
                    <Typography variant="body7" color="text-medium-emphasis">
                        {subTitle}
                    </Typography>
                    <div className="divider bg-structural-selected" />
                    <div className="text-center">
                        <Typography variant="body2" color="text-medium-emphasis">
                            {subText}
                        </Typography>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default VendorCard;
