import { tabs } from '../../../core-utils/constants/constants';
import Organisation from '../../organisms/OrganisationTab';
import Dashboard from '../../templates/Dashboard';
import Users from '../../organisms/Users';
import { Route, Routes } from 'react-router-dom';
import { DEPT_ROUTE, ORG_ROUTE, USERS_ROUTE } from '../../../core-utils/routes';
import ComingSoon from '../../molecules/IminentCard';
import NotFoundPage from '../NotFoundPage';

const DashboardPage = () => {
    const DashboardComponent = () => {
        return (
            <Dashboard tabs={tabs}>
                <Routes>
                    <Route path={ORG_ROUTE} element={<Organisation />} />
                    {/* TODO:To be changed with Department tab */}
                    <Route path={DEPT_ROUTE} element={<ComingSoon />} />
                    <Route path={USERS_ROUTE} element={<Users />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Dashboard>
        );
    };

    return <DashboardComponent />;
};

export default DashboardPage;
