import oktaIcon from '../../../public/assets/icons/okta.svg';
import googleIcon from '../../../public/assets/icons/google.svg';
import azureADIcon from '../../../public/assets/icons/azureAd.svg';
import crowdstrikeIcon from '../../../public/assets/icons/crowdstrike.svg';
import sentinelOneIcon from '../../../public/assets/icons/sentinelOne.svg';
import zscalerIcon from '../../../public/assets/icons/zscaler.svg';
import ciscoUmbrellaIcon from '../../../public/assets/icons/ciscoUmbrella.svg';
import TenableIcon from '../../../public/assets/icons/tenable.svg';
import rapid7Icon from '../../../public/assets/icons/rapid7.svg';
import JAMFIcon from '../../../public/assets/icons/jamf.svg';
import oomnitzaIcon from '../../../public/assets/icons/oomnitza.svg';
import intuneIcon from '../../../public/assets/icons/intune.svg';
import slackIcon from '../../../public/assets/icons/slack.svg';
import teamsIcon from '../../../public/assets/icons/teams.svg';
import knowBe4Icon from '../../../public/assets/icons/knowbe4.svg';

export const INTEGRATIONS_DATA = [
    {
        id: 1,
        vendor: 'Okta',
        imageUrl: oktaIcon,
        tile: 'Enable Okta login and user attribute identifcation.',
        integrationCopy:
            'Enhance user experience by providing seamless login capabilities through Okta, allowing individuals to securely access the system. Users are categorized as either third-party members, executives, authorized personnel with production access, or individuals possessing privileged access which help identify real-time risks to the organization.',
        intallationInstructions: '',
        configurations: [],
        tags: ['Identity Tools'],
        isIntegrated: false,
    },
    {
        id: 2,
        vendor: 'GoogleSSO',
        imageUrl: googleIcon,
        tile: 'Enable Google login and user attribute identifcation.',
        integrationCopy:
            'Elevate user experience by offering a streamlined login process through Google Single Sign-On (SSO), ensuring secure access to the system for individuals. Users are classified into distinct categories, including third-party members, executives, authorized personnel with production access, and individuals with privileged credentials, enabling tailored security health scores for users.',
        intallationInstructions: '',
        configurations: [],
        tags: ['Identity Tools'],
        isIntegrated: false,
    },
    {
        id: 3,
        vendor: 'AzureAD',
        imageUrl: azureADIcon,
        tile: 'Enable AzureAD login and user attribute identifcation.',
        integrationCopy:
            'Elevate the user experience by seamlessly integrating Azure Active Directory (AzureAD) for login capabilities, enabling individuals to securely access the system. Users are  categorized, distinguishing between third-party members, executives, authorized personnel with production access, and individuals with privileged credentials. This comprehensive categorization allows Amplifier to customize risk scoring of each user.',
        intallationInstructions: '',
        configurations: [],
        tags: ['Identity Tools'],
        isIntegrated: false,
    },
    {
        id: 4,
        vendor: 'Crowdstrike',
        imageUrl: crowdstrikeIcon,
        tile: 'Validate that Crowdstrike is installed and operational.',
        integrationCopy:
            'The application implements a validation mechanism to ensure the presence and optimal functionality of Crowdstrike, a leading cybersecurity solution. By meticulously verifying the installation and operational status of Crowdstrike, the application fortifies its security posture and safeguards against potential threats with confidence.',
        intallationInstructions: '',
        configurations: [],
        tags: ['End point Tools', 'Vulnerability Tools'],
        isIntegrated: false,
    },
    {
        id: 5,
        vendor: 'SentinelOne',
        imageUrl: sentinelOneIcon,
        tile: 'Validate that SentinelOne is installed and operational.',
        integrationCopy:
            'The application employs a validation mechanism to guarantee the presence and optimal performance of SentinelOne, an industry-leading cybersecurity solution. Through meticulous verification of the installation and operational status of SentinelOne, the application reinforces its security posture, instilling unwavering confidence in safeguarding against potential threats.',
        intallationInstructions: '',
        configurations: [],
        tags: ['End point Tools'],
        isIntegrated: false,
    },

    {
        id: 6,
        vendor: 'Zscaler',
        imageUrl: zscalerIcon,
        tile: 'Validate user login to Zscaler ZIA Gateway and initiate self-healing.',
        integrationCopy:
            'Implement a robust validation mechanism to verify that users are actively logged into the highly secure Zscaler ZIA Gateway. In addition, proactively engage in self-healing measures to promptly address and resolve any potential issues, thus ensuring seamless and uninterrupted security for users.',
        intallationInstructions: '',
        configurations: [],
        tags: ['Web gateway Tools'],
        isIntegrated: false,
    },
    {
        id: 7,
        vendor: 'Cisco Umbrella',
        imageUrl: ciscoUmbrellaIcon,
        tile: 'Validate user login to Cisco Umbrella and initiate self-healing.',
        integrationCopy:
            'Establish a resilient validation mechanism to validate the active login status of users within the renowned and secure Cisco Umbrella platform. Moreover, proactively initiate self-healing protocols to promptly detect, address, and resolve any potential issues, thereby ensuring an uninterrupted and seamless security experience for all users.',
        intallationInstructions: '',
        configurations: [],
        tags: ['Web gateway Tools'],
        isIntegrated: false,
    },
    {
        id: 8,
        vendor: 'Tenable',
        imageUrl: TenableIcon,
        tile: 'Identify new and out of SLA Vulnerabiities and allow users to self remediate.',
        integrationCopy:
            'Employ an advanced and comprehensive vulnerability identification system to swiftly detect newly discovered vulnerabilities and promptly flag any instances that fall outside the established Service Level Agreement (SLA) parameters. Furthermore, empower users with the capability to autonomously engage in self-remediation measures, enabling them to take immediate action to resolve identified vulnerabilities, ensuring a proactive and robust security posture.',
        intallationInstructions: '',
        configurations: [],
        tags: ['Vulnerability Tools'],
        isIntegrated: false,
    },
    {
        id: 9,
        vendor: 'Rapid7',
        imageUrl: rapid7Icon,
        tile: 'Identify new and out of SLA Vulnerabiities and allow users to self remediate.',
        integrationCopy:
            'Leverage the power of Rapid7 to unleash an advanced and robust vulnerability identification system that promptly identifies newly discovered vulnerabilities and diligently highlights any deviations from the defined Service Level Agreement (SLA) benchmarks. Additionally, empower users with self-remediation capabilities, enabling them to proactively address and rectify identified vulnerabilities, fortifying the security posture and ensuring a swift and effective response to potential threats.',
        intallationInstructions: '',
        configurations: [],
        tags: ['Vulnerability Tools'],
        isIntegrated: false,
    },
    {
        id: 10,
        vendor: 'JAMF',
        imageUrl: JAMFIcon,
        tile: 'Map users and devices to allow usrs to self heal device issues.',
        integrationCopy:
            'Implement a comprehensive mapping system that associates users with their respective devices, empowering them with the ability to autonomously resolve any device-related security issues through self-healing measures. This robust solution ensures a seamless and efficient user experience while effectively addressing device-related challenges.',
        intallationInstructions: '',
        configurations: [],
        tags: ['Asset Mgmt Tools'],
        isIntegrated: false,
    },
    {
        id: 11,
        vendor: 'Oomnitza',
        imageUrl: oomnitzaIcon,
        tile: 'Map users and devices to allow usrs to self heal device issues.',
        integrationCopy:
            'Leverage the power of Oomnitza to establish a comprehensive mapping system that seamlessly links users with their corresponding devices, enabling them to independently address any security issues related to their devices through self-healing capabilities. This robust solution not only ensures a streamlined and efficient user experience but also effectively mitigates device-related challenges, strengthening the overall security posture of the system.',
        intallationInstructions: '',
        configurations: [],
        tags: ['Asset Mgmt Tools'],
        isIntegrated: false,
    },
    {
        id: 12,
        vendor: 'Intune',
        imageUrl: intuneIcon,
        tile: 'Map users and devices to allow usrs to self heal device issues.',
        integrationCopy:
            'Utilize Intune to implement a sophisticated mapping system that connects users with their respective devices, granting them the autonomy to self-heal any security issues related to their devices. This powerful solution guarantees a seamless and user-friendly experience while proactively addressing device-related challenges and bolstering the overall security framework.',
        intallationInstructions: '',
        configurations: [],
        tags: ['Asset Mgmt Tools'],
        isIntegrated: false,
    },
    {
        id: 13,
        vendor: 'Slack',
        imageUrl: slackIcon,
        tile: 'Enage with users using Slack',
        integrationCopy:
            'Proactively engage with users via Slack, effectively communicating security findings, providing detailed instructions for issue remediation, and empowering them to autonomously resolve security concerns without relying on the security team. This streamlined approach enhances collaboration and facilitates rapid healing of security issues, fostering a more efficient and empowered user experience while bolstering overall security culture.',
        intallationInstructions: '',
        configurations: [],
        tags: ['Messaging Tools'],
        isIntegrated: false,
    },
    {
        id: 14,
        vendor: 'Teams',
        imageUrl: teamsIcon,
        tile: 'Enage with users using Teams',
        integrationCopy:
            'Proactively engage with users via Teams, effectively communicating security findings, providing detailed instructions for issue remediation, and empowering them to autonomously resolve security concerns without relying on the security team. This streamlined approach enhances collaboration and facilitates rapid healing of security issues, fostering a more efficient and empowered user experience while bolstering overall security culture.',
        intallationInstructions: '',
        configurations: [],
        tags: ['Messaging Tools'],
        isIntegrated: false,
    },
    {
        id: 15,
        vendor: 'KnowBe4',
        imageUrl: knowBe4Icon,
        tile: '',
        integrationCopy: '',
        intallationInstructions: '',
        configurations: [],
        tags: ['Training Tools'],
        isIntegrated: false,
    },
];
