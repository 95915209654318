// From react-router-dom docs: https://v5.reactrouter.com/web/example/query-parameters
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
};

export default useQuery;
