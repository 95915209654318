import Chip from '../../_components/atoms/Chip';
import DataGridHeaderFilter from '../../_components/molecules/DataGridHeaderFilter';
import { TableColumn } from 'react-data-table-component';
import Typography from '../../_components/atoms/Typography';
import { SEVERITY_TEXT, DATE_TEXT, NAME, ENGAGEMENT, ISSUE_TYPE_TEXT, STATUS } from './constants';
import { opacityClassName, DateTimeFormatter } from '../../core-utils/helpers/helpers';
import { UserType } from '../types/types';
import { HandleTableFiltersProps, GridStateType } from '../types/types';

type DataGridColumnProps = {
    selectedFilters: GridStateType;
    handleTableFilters: (_props: HandleTableFiltersProps) => void;
};

export const getDataGridColumn = ({
    selectedFilters,
    handleTableFilters,
}: DataGridColumnProps): TableColumn<UserType>[] => [
    {
        id: 'severity',
        name: (
            <DataGridHeaderFilter
                text={SEVERITY_TEXT}
                sortable={true}
                filterable={true}
                filterOptions={['Low', 'Medium', 'High', 'Critical']}
                selectedFilterOptions={selectedFilters?.filters?.['severity'] ?? []}
                selectedSortDirection={selectedFilters.sortColumn === 'severity' ? selectedFilters.sortDirection : null}
                columnName="severity"
                handleTableFilters={handleTableFilters}
                position="center"
            />
        ),
        center: true,
        cell: (row: UserType) => {
            return (
                <div className={`text-uppercase ${opacityClassName(row)}`}>
                    <Typography variant="body5">{row.severity}</Typography>
                </div>
            );
        },
    },
    {
        id: 'createdAt',
        name: <Typography variant="body2">{DATE_TEXT}</Typography>,
        cell: (row: UserType) => (
            <div className={opacityClassName(row)}>
                <Typography variant="body5">{DateTimeFormatter(row.createdAt)}</Typography>
            </div>
        ),
    },
    {
        id: 'issueType',
        name: (
            <DataGridHeaderFilter
                text={ISSUE_TYPE_TEXT}
                sortable={true}
                filterable={true}
                filterOptions={['VULN', 'MFA']}
                selectedFilterOptions={selectedFilters?.filters?.['issueType'] ?? []}
                selectedSortDirection={
                    selectedFilters.sortColumn === 'issueType' ? selectedFilters.sortDirection : null
                }
                columnName="issueType"
                handleTableFilters={handleTableFilters}
                position="center"
            />
        ),
        center: true,
        cell: (row: UserType) => (
            <div className={opacityClassName(row)}>
                <Chip value={'vuln'} variant={'top_risk_issue'} />
            </div>
        ),
    },
    {
        id: 'displayValue',
        name: <Typography variant="body2">{NAME}</Typography>,
        cell: (row: UserType) => (
            <div className={opacityClassName(row)}>
                <Typography variant="body5">{row.displayValue}</Typography>
            </div>
        ),
    },
    {
        id: 'numberOfEngagements',
        name: <Typography variant="body2">{ENGAGEMENT}</Typography>,
        center: true,
        cell: (row: UserType) => (
            <div className={`findings-table__engagement-cell d-flex justify-content-start ${opacityClassName(row)}`}>
                <div>
                    <Typography variant="body5">
                        {row.status === 'CLOSED' ? 100 + '%' : `${row.numberOfEngagements}%`}
                    </Typography>
                </div>
            </div>
        ),
    },
    {
        id: 'status',
        name: <Typography variant="body2">{STATUS}</Typography>,
        cell: (row: UserType) => (
            <div className={opacityClassName(row)}>
                <Typography variant="body5">{row.status}</Typography>
            </div>
        ),
    },
];
