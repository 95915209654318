import { Button } from 'react-bootstrap';

import { ActionButtonsProps } from '../../../core-utils/types/types';
import Typography from '../../atoms/Typography';
import { APPLY_TEXT, CLEAR_TEXT } from '../../../core-utils/constants/constants';

const FilterActionButtons = ({ handleClear, handleApply, disabled = true }: ActionButtonsProps) => {
    return (
        <div className="d-flex justify-content-between m-3">
            <Button className="border-0" onClick={handleClear} disabled={disabled}>
                <Typography variant="body4" color="primary-500">
                    {CLEAR_TEXT}
                </Typography>
            </Button>
            <Button className="bg-primary-500 text-uppercase border-0" onClick={handleApply} disabled={disabled}>
                <Typography variant="body4" color="text-black">
                    {APPLY_TEXT}
                </Typography>
            </Button>
        </div>
    );
};

export default FilterActionButtons;
