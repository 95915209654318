//will update one we have api for the User Top Risk Contributors
export const UserTopRiskContributorsProps = [
    {
        data: [10, 20, 60, 90],
        backgroundColor: 'rgba(71, 68, 76, 0.2)',
        borderColor: '#FFFFFF',
        name: 'Edwin',
    },
    {
        data: [60, 40, 80, 75],
        backgroundColor: 'rgba(112, 107, 123, 0.2',
        borderColor: '#706B7B',
        name: 'Orgainzation',
    },
    {
        data: [20, 90, 10, 15],
        backgroundColor: 'rgba(39, 35, 44, 0.2)',
        borderColor: '#7358BB',
        name: 'Department',
    },
];
