import { Accordion as BootstrapAccordion, Badge } from 'react-bootstrap';
import './styles.scss';
import { CONFIGURED, NOT_CONFIGURED } from '../../../core-utils/constants/constants';
import Typography from '../Typography';
import { BsChevronRight } from 'react-icons/bs';

export type AccordionProps = {
    children: React.ReactNode;
    title: string;
} & (
    | {
          isConfigurationRequired: true;
          isConfigured: boolean;
      }
    | {
          isConfigurationRequired: false;
      }
);
const Accordion = (props: AccordionProps) => {
    const { children, isConfigurationRequired, title } = props;
    return (
        <BootstrapAccordion>
            <BootstrapAccordion.Header>
                <BsChevronRight />
                <Typography variant="subtitle7" color="text-high-emphasis">
                    {title}
                </Typography>
                {isConfigurationRequired && (
                    <Badge
                        className="bg-structural-300 body3 text-text-medium-emphasis accordion__badge d-flex align-items-center justify-content-center"
                        pill
                    >
                        {props.isConfigured ? CONFIGURED : NOT_CONFIGURED}
                    </Badge>
                )}
            </BootstrapAccordion.Header>
            <BootstrapAccordion.Body className="bg-structural-400">{children}</BootstrapAccordion.Body>
        </BootstrapAccordion>
    );
};

export default Accordion;
