import classnames from 'classnames';
import CategoryCard from './CategoryCard';
import IntegratedVendorCard from './IntegratedVendorCard';
import './styles.scss';

export interface IntegratedCardProps {
    imageUrl: string;
    title: string;
    vendor?: string;
    handleCard: () => void;
    variant?: 'active' | 'disabled' | 'error' | 'sync';
}

const IntegratedCard = ({ imageUrl, title, vendor, handleCard, variant = 'active' }: IntegratedCardProps) => {
    const isActive = variant === 'active';
    const isDisabled = variant === 'disabled';
    const isError = variant === 'error';
    const isSync = variant === 'sync';

    const integratedCardClasses = classnames('integrated-card', {
        'integrated-card--integrated': vendor,
        'integrated-card--card-default': !vendor,
        'integrated-card--active': isActive,
        'integrated-card--disabled': isDisabled,
        'integrated-card--error': isError,
        'integrated-card--sync': isSync,
    });

    return (
        <div className={integratedCardClasses} data-testid="integrated-card">
            {vendor ? (
                <IntegratedVendorCard
                    imageUrl={imageUrl}
                    title={title}
                    handleCard={handleCard}
                    tool={vendor}
                    variant={variant}
                />
            ) : (
                <CategoryCard imageUrl={imageUrl} title={title} handleCard={handleCard} />
            )}
        </div>
    );
};

export default IntegratedCard;
