import './styles.scss';
import Typography from '../Typography';

type CheckboxProps = {
    label: string;
    handleCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
};

const Checkbox = ({ label: option, handleCheckbox, checked }: CheckboxProps) => (
    <div className="form-check data-grid-dropdown__dropdown-item dropdown-item">
        <input
            type="checkbox"
            className="form-check-input me-2 ms-1 bg-transparent border"
            checked={checked}
            id={option}
            onChange={handleCheckbox}
        />
        <label className="form-label m-0 w-100" htmlFor={option}>
            <Typography variant="body2">{option}</Typography>
        </label>
    </div>
);

export default Checkbox;
