import { TableColumn } from 'react-data-table-component';
import { ReactSVG } from 'react-svg';
import ProgressBar from '../../_components/atoms/EngagementBar';
import Chip from '../../_components/atoms/Chip';
import Typography from '../../_components/atoms/Typography';
import { UserType } from '../types/types';
import moreIcon from '../../../public/assets/icons/more.svg';
import redHeartIcon from '../../../public/assets/icons/redHeart.svg';
import greenHeartIcon from '../../../public/assets/icons/greenHeart.svg';
import { SEVERITY_TEXT, DATE_TEXT, ISSUE_TYPE_TEXT, NAME, ENGAGEMENT } from './constants';
import { DateTimeFormatter, opacityClassName } from '../helpers/helpers';

export const FINDINGS_TABLE_HEADER = 'Findings';

export const FINDINGS_TABLE_COLUMNS: TableColumn<UserType>[] = [
    {
        id: 'severity',
        name: (
            <div className="w-100 text-center">
                <Typography variant="body2">{SEVERITY_TEXT}</Typography>
            </div>
        ),
        center: true,
        sortable: false,
        width: '9.375rem',
        wrap: true,
        cell: (row: UserType) => {
            const variant = row.status === 'CLOSED' ? 'resolved_issue_variant' : 'severity';
            return (
                <div className={`text-uppercase ${opacityClassName(row)}`}>
                    <Chip value={row.severity} variant={variant} />
                </div>
            );
        },
    },
    {
        id: 'createdAt',
        name: <Typography variant="body2">{DATE_TEXT}</Typography>,
        sortable: true,
        cell: (row: UserType) => (
            <div className={opacityClassName(row)}>
                <Typography variant="body5">{DateTimeFormatter(row.createdAt)}</Typography>
            </div>
        ),
    },
    {
        id: 'issueType',
        name: (
            <div className="w-100 text-center">
                <Typography variant="body2">{ISSUE_TYPE_TEXT}</Typography>
            </div>
        ),
        center: true,
        sortable: false,
        cell: (row: UserType) => (
            <div className={opacityClassName(row)}>
                <Chip value={'vuln'} variant={'top_risk_issue'} />
            </div>
        ),
        width: '11.25rem',
    },
    {
        id: 'displayValue',
        name: <Typography variant="body2">{NAME}</Typography>,
        sortable: false,
        cell: (row: UserType) => (
            <div className={opacityClassName(row)}>
                <Typography variant="body5">{row.displayValue}</Typography>
            </div>
        ),
    },
    {
        id: 'numberOfEngagements',
        name: <Typography variant="body2">{ENGAGEMENT}</Typography>,
        sortable: false,
        cell: (row: UserType) => (
            <div
                className={`findings-table__engagement-cell d-flex align-center justify-content-between ${opacityClassName(
                    row,
                )}`}
            >
                <ProgressBar percentage={row.status === 'CLOSED' ? 100 : row.numberOfEngagements} />
                <div style={{ width: '36px', minWidth: '18px' }}>
                    <Typography variant="body5">
                        {row.status === 'CLOSED' ? 100 + '%' : `${row.numberOfEngagements}%`}
                    </Typography>
                </div>
                {row.status === 'CLOSED' ? <ReactSVG src={greenHeartIcon} /> : <ReactSVG src={redHeartIcon} />}
            </div>
        ),
    },
    {
        id: 'more',
        sortable: false,
        name: null,
        maxWidth: '78px',
        cell: () => <ReactSVG src={moreIcon} />,
    },
];
