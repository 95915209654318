import { TableColumn } from 'react-data-table-component';
import Avatar from '../../_components/atoms/Avatar';
import Chip from '../../_components/atoms/Chip';
import Typography from '../../_components/atoms/Typography';
import { dateCalculation } from '../helpers/helpers';
import { USERS_DASHBOARD_ROUTE } from '../routes';
import { useNavigate } from 'react-router-dom';

interface TopRiskContributor {
    createdAt?: string;
    deletedAt?: string;
    description?: string;
    displayValue: string;
    id: string;
    type: string;
    uid?: string;
    updatedAt?: string;
}

type TopRiskContributorsProps = {
    topRiskContributors: TopRiskContributor[];
};

interface Employee {
    id: number;
    name: string;
    score: number;
    topRiskContributors: TopRiskContributor[];
    department: string;
    position: string;
    tenure: Date;
    employeeType: string;
    image?: string;
}

const topRiskContributorMap = (topRiskContributors: TopRiskContributor[]) => {
    return topRiskContributors.length ? () => <TopRiskContributors topRiskContributors={topRiskContributors} /> : '-';
};

const TopRiskContributors = ({ topRiskContributors }: TopRiskContributorsProps) => (
    <div className="d-flex gap-1">
        {topRiskContributors.map((topRiskContributor, index) => (
            <>
                <Chip value={topRiskContributor.displayValue} variant={'top_risk_issue'} key={index} />
            </>
        ))}
    </div>
);

export const USERS_COLUMNS: TableColumn<Employee>[] = [
    {
        name: (
            <div className="w-100 text-center">
                <Typography variant="body2">User Score</Typography>
            </div>
        ),
        center: true,
        sortable: true,
        sortFunction: (a, b) => {
            return a.score > b.score ? 1 : -1;
        },
        maxWidth: '7.75rem',
        cell: (row: Employee) => <Chip variant="user_score" value={row.score.toString()} />,
        ignoreRowClick: true,
        id: 'score',
    },
    {
        id: 'name',
        name: <Typography variant="body2">Name</Typography>,
        sortable: true,
        style: { cursor: 'pointer' },
        sortFunction: (a, b) => {
            return a.name > b.name ? 1 : -1;
        },
        cell: (row: Employee) => {
            const navigate = useNavigate();
            const handleClick = () => {
                navigate(`${USERS_DASHBOARD_ROUTE}/${row.id}`, {
                    state: { userSpecificId: row.id },
                });
            };

            return (
                <div onClick={handleClick}>
                    <Avatar variant={'name_avatar'} name={row.name} image={row?.image} />
                </div>
            );
        },
    },
    {
        id: 'topRiskContributors',
        name: <Typography variant="body2">Top Risk Contributors</Typography>,
        ignoreRowClick: true,
        minWidth: '15rem',
        cell: (row: Employee) => {
            const TopRisks = topRiskContributorMap(row.topRiskContributors);
            if (typeof TopRisks === 'string') {
                return <div className="d-flex justify-content-start gap-1 w-100">{TopRisks}</div>;
            } else {
                return <TopRisks />;
            }
        },
    },
    {
        id: 'department',
        name: <Typography variant="body2">Department</Typography>,
        sortable: true,
        ignoreRowClick: true,
        sortFunction: (a, b) => {
            return a.department > b.department ? 1 : -1;
        },
        cell: (row: Employee) => <Typography variant="body5">{row.department}</Typography>,
    },
    {
        id: 'position',
        name: <Typography variant="body2">Position</Typography>,
        sortable: true,
        ignoreRowClick: true,
        sortFunction: (a, b) => {
            return a.position > b.position ? 1 : -1;
        },
        cell: (row: Employee) => <Typography variant="body5">{row.position}</Typography>,
    },
    {
        id: 'tenure',
        name: (
            <div className="w-100 text-center">
                <Typography variant="body2">Tenure</Typography>
            </div>
        ),
        sortable: true,
        center: true,
        ignoreRowClick: true,
        maxWidth: '8.75rem',
        sortFunction: (a, b) => {
            return a.tenure > b.tenure ? -1 : 1;
        },
        cell: (row: Employee) => (
            <Typography variant="body5">{dateCalculation(row.tenure, new Date(), 'short')}</Typography>
        ),
    },
    {
        id: 'employeeType',
        name: <Typography variant="body2">Employee Type</Typography>,
        sortable: true,
        ignoreRowClick: true,
        sortFunction: (a, b) => {
            return a.employeeType > b.employeeType ? 1 : -1;
        },
        cell: (row: Employee) => <Typography variant="body5">{row.employeeType}</Typography>,
    },
];
