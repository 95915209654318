import React, { useRef } from 'react';
import { Image } from 'react-bootstrap';
import Labels from '../../../../public/assets/icons/Labels.svg';
import { Dataset } from '../../../core-utils/types/types';
import { useRadarChartEffect } from '../../../core-utils/constants/charts';
import './styles.scss';

interface RadarChartProps {
    datasets: Dataset[];
}

const RadarChart: React.FC<RadarChartProps> = ({ datasets }) => {
    const chartRef = useRef<HTMLCanvasElement>(null);

    useRadarChartEffect(chartRef, datasets);

    return (
        <div data-testid="radar-chart" className="radar-chart">
            <Image src={Labels} className="radar-chart__labels" />
            <div className="radar-chart__canvas-container">
                <canvas ref={chartRef} />
            </div>
        </div>
    );
};

export default RadarChart;
