import { ReactSVG } from 'react-svg';
import { Button, Card } from 'react-bootstrap';
import { INTEGRATEDTOOLHEADERS } from '../../../core-utils/constants/constants';
import Typography from '../../atoms/Typography';
import classnames from 'classnames';
import './styles.scss';

interface CategoryCardProps {
    imageUrl: string;
    title: string;
    handleCard: (_title: string) => void;
}

const CategoryCard = ({ imageUrl, title, handleCard }: CategoryCardProps) => {
    const cardClasses = classnames('integrated-card__card-default bg-text-black p-1 rounded', 'justify-content-center');

    return (
        <Card className={cardClasses}>
            <div className="d-flex flex-column align-items-center text-uppercase">
                <ReactSVG src={imageUrl} className="d-flex align-items-center justify-content-center" />
                <Typography variant="body8" color="text-high-emphasis">
                    {title}
                </Typography>
                <Typography variant="body5" color="text-low-emphasis">
                    {INTEGRATEDTOOLHEADERS[0]}
                </Typography>
                <Button onClick={() => handleCard(title)} className="border-text-black text-uppercase">
                    <Typography variant="subtitle6" color="text-interactive">
                        {INTEGRATEDTOOLHEADERS[1]}
                    </Typography>
                </Button>
            </div>
        </Card>
    );
};

export default CategoryCard;
