import './styles.scss';
import { useMemo, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import _ from 'lodash';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import IntegrationsFilterList from '../../molecules/IntegrationsFilter';
import VendorCard from '../../molecules/VendorCard';
import SearchBar from '../../molecules/SearchBar';
import { INTEGRATIONS_FILTER_LIST } from '../../../core-utils/constants/constants';
import { escapeRegexCharacters } from '../../../core-utils/helpers/helpers';
import '../../../theme/scroll.scss';

export type IntegrationDataProps = {
    id: number;
    vendor: string;
    imageUrl: string;
    tile: string;
    integrationCopy: string;
    intallationInstructions: string;
    configurations: string[];
    tags: string[];
    isIntegrated: boolean;
};
export interface IntegrationGridProps {
    data: IntegrationDataProps[];
    variant?: 'all' | 'selected';
    handleCardClick: (_id: number) => void;
}
export interface IntegrationsProps {
    title: string;
    data: IntegrationDataProps[];
    variant?: 'all' | 'selected';
    show: boolean;
    handleClose: () => void;
    handleCardClick: (_id: number) => void;
}
const IntegrationsGrid = ({ data, variant, handleCardClick }: IntegrationGridProps) => {
    // Making sure that for 'all' variant, there are only 3 elements in each row, else 4.
    const gridColClassName = classNames({
        'col-4': variant === 'all',
        'col-3': variant === 'selected',
    });
    const maxRowElements = variant === 'all' ? 3 : 4;

    const rows = [];

    for (let i = 0; i < data.length; i += maxRowElements) {
        const rowItems = data.slice(i, i + maxRowElements).map((ele) => (
            <div className={gridColClassName} key={ele.id}>
                <VendorCard
                    imageUrl={ele.imageUrl}
                    title={ele.vendor}
                    subText={ele.tile}
                    isIntegrated={ele.isIntegrated}
                    handleCard={() => handleCardClick(ele.id)}
                    id={ele.id}
                />
            </div>
        ));

        rows.push(
            <div className="row" key={i}>
                {rowItems}
            </div>,
        );
    }

    return <div className="integrations-grid d-grid gap-3 w-100 overflow-auto custom-scrollbar">{rows}</div>;
};

const getFiltersData = (data: IntegrationDataProps[]) => {
    // Grouping data by the tags to get the count of each tag.
    const groupedData: Record<string, number> = data.reduce((prev: Record<string, number>, curr) => {
        const temp = prev;
        curr.tags.forEach((tag) => {
            temp[tag] = (temp[tag] ?? 0) + 1;
        });
        return temp;
    }, {});

    const filters = [{ text: 'All', count: data.length }];

    // Iterating over a const array to keep the order of the filter unchanged.
    INTEGRATIONS_FILTER_LIST.forEach((option) => {
        filters.push({
            text: option,
            count: groupedData[option] ?? 0,
        });
    });

    return filters;
};

const Integrations = ({ title, data, variant = 'selected', show, handleClose, handleCardClick }: IntegrationsProps) => {
    const [filter, setFilter] = useState('All');
    const [query, setQuery] = useState('');

    const filteredData = useMemo(() => {
        // Filtering the data based on selected filter
        const tempData = filter === 'All' ? [...data] : data.filter((ele) => ele.tags.includes(filter));
        if (!query) {
            return tempData;
        }

        // Filtering the data as per the query input
        const regex = new RegExp(escapeRegexCharacters(query), 'i');
        return tempData.filter((ele) => ele.vendor.match(regex));
    }, [query, filter, data]);

    const filters = getFiltersData(data);
    return (
        <>
            <Modal show={show} className="integrations w-100" centered onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                    <AiOutlineClose onClick={handleClose} className="close-button" />
                </Modal.Header>
                <Modal.Body className="p-3 d-flex flex-column gap-3 m-0 overflow-hidden">
                    <SearchBar value={''} handleChange={(event) => setQuery(event.target.value)} />
                    <div className="d-flex gap-3 overflow-hidden custom-scrollbar">
                        {variant === 'all' && (
                            <IntegrationsFilterList
                                onClickHandler={(filter) => setFilter(filter)}
                                data={filters}
                                activeFilter={filter}
                            />
                        )}
                        <IntegrationsGrid
                            data={filteredData}
                            variant={variant}
                            handleCardClick={(id) => handleCardClick(id)}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Integrations;
