import React from 'react';
import { Button } from 'react-bootstrap';
import { IoArrowBackOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { BACK } from '../../../core-utils/constants/constants';
import Typography from '../../atoms/Typography';

const BackButton = () => {
    const navigate = useNavigate();
    const handleClick = () => navigate(-1);

    return (
        <Button
            variant="outline-primary"
            className="engagement-flow__button engagement-flow__btn d-flex text-text-high-emphasis"
            onClick={handleClick}
            data-testid="back-button"
        >
            <IoArrowBackOutline />
            <Typography variant="body2">{BACK}</Typography>
        </Button>
    );
};

export default BackButton;
