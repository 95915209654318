import UserDetailContent from '../../organisms/UserDetailContent';
import './styles.scss';
import BackButton from '../../molecules/BackButton';

const UserDetailPage = () => {
    const UserSpecificPage = () => {
        return (
            <div className="user-specific-page pb-3">
                <div className="user-specific-page__content d-flex flex-column g-3 justify-content-center">
                    <BackButton />
                    <UserDetailContent />
                </div>
            </div>
        );
    };
    return <UserSpecificPage />;
};

export default UserDetailPage;
