import { createModel } from '@rematch/core';
import type { RootModel } from './models';

const ssoModel = createModel<RootModel>()({
    state: {
        ssoOption: null,
    },
    reducers: {
        setSsoOption(state, payload) {
            return { ...state, ssoOption: payload };
        },
    },
});

export default ssoModel;
