import { getApiData } from '../../../apis/library';
import { HEALTH_TRENDS_TEXT } from '../../../core-utils/constants/constants';
import { getLineGraphUrl } from '../../../core-utils/endPoints';
import CustomCard from '../../molecules/CustomCard';
import LineGraph from '../../molecules/LineGraph';
import { useApiData } from '../../../hooks/useApiData';
import { DataProp } from '../../../core-utils/types/types';
import ErrorHandlerAndLoaderWrapper from '../../../wrappers/ErrorHandlerandLoaderWrapper';
import IconWithTypography from '../../molecules/IconWithTypography';

const LineGraphCard = () => {
    const { data, error, isLoading, refresh } = useApiData(getLineGraphUrl, getApiData, (dataItem: DataProp) => {
        return { ...dataItem };
    });
    const seriesData = data?.map((item: any) => item.value) || [];

    return (
        <CustomCard
            cardType="oneThird"
            title={<IconWithTypography isRow={true} label={HEALTH_TRENDS_TEXT} labelColor="primary" />}
            content={
                <ErrorHandlerAndLoaderWrapper
                    error={error}
                    isLoading={isLoading}
                    render={() => <LineGraph series={seriesData} />}
                    handleRefresh={refresh}
                />
            }
        />
    );
};

export default LineGraphCard;
