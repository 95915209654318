import React from 'react';
import { Badge } from 'react-bootstrap';
import './styles.scss';
import {
    chipRiskClassMap,
    chipStatusClassMap,
    severityMap,
    severityMapForResolvedIssue,
} from '../../../core-utils/helpers/helpers';

interface ChipProps {
    value: string;
    variant:
        | 'user_score'
        | 'top_risk_issue'
        | 'severity'
        | 'employee_risk_variant'
        | 'status'
        | 'resolved_issue_variant'
        | 'integrated_card_variant';
    image?: string;
}

const getVariantClassName = (variant: ChipProps['variant'], value: string) => {
    switch (variant) {
        case 'user_score': {
            const score = parseInt(value);
            return chipRiskClassMap(score);
        }
        case 'top_risk_issue':
            return 'align-items-center justify-content-center text-uppercase chip__top-risk-issue-variant overline1';
        case 'severity':
            return severityMap(value);
        case 'employee_risk_variant':
            return 'd-flex align-items-center justify-content-center text-uppercase chip__employee-risk caption4';
        case 'status':
            return chipStatusClassMap(value);
        case 'resolved_issue_variant': {
            return severityMapForResolvedIssue(value);
        }
        case 'integrated_card_variant': {
            return 'd-flex align-items-center justify-content-center text-uppercase chip__integrated-card-variant caption4 ';
        }
        default:
            return '';
    }
};

const Chip = (props: ChipProps) => {
    const { value, image, variant } = props;
    const variantClassName = getVariantClassName(variant, value);

    const renderChipContent = () => {
        if (image) {
            return (
                <Badge className="d-flex align-items-center justify-content-center text-uppercase chip__with-icon overline2">
                    <img src={image} className="chip__image" alt="Badge Icon" />
                    {value}
                </Badge>
            );
        }
        return <Badge className={`chip ${variantClassName}`}>{value}</Badge>;
    };

    return <>{renderChipContent()}</>;
};

export default Chip;
