export const SIGN_IN = '/signin';
export const DASHBOARD_ROUTE = '/dashboard/*';
export const USER_SPECIFIC_ROUTE = '/dashboard/users/:id';
export const HOME_ROUTE = '/dashboard';
export const WORKFLOW_ROUTE = '/workflow/*';
export const WORKFLOW_SPECIFIC_ROUTE = '/workflow/active-flows/:id';
export const POLICIES_ROUTE = '/policies';
export const DEFAULT_WORKFLOW_ROUTE = '/workflow';
export const ORG_DASHBOARD_ROUTE = `${HOME_ROUTE}/org`;
export const DEPT_DASHBOARD_ROUTE = `${HOME_ROUTE}/dept`;
export const USERS_DASHBOARD_ROUTE = `${HOME_ROUTE}/users`;
export const ACTIVE_WORKFLOWS_ROUTE = `${DEFAULT_WORKFLOW_ROUTE}/active-flows`;
export const TEMPLATES_WORKFLOWS_ROUTE = `${DEFAULT_WORKFLOW_ROUTE}/templates`;
export const DEFAULT_ROUTE = '/';
export const ORG_ROUTE = 'org';
export const DEPT_ROUTE = 'dept';
export const USERS_ROUTE = 'users';
export const ACTIVE_FLOWS_ROUTE = 'active-flows';
export const TEMPLATES_ROUTE = 'templates';
export const PAGE_ROUTE = '/*';
export const SECURITY_ROUTE = '/security-findings';
export const PRODUCTION_ACCESS = '/production-access';
export const PRIVILEGE_ACCESS = '/privilege-access';
export const DEFINE_EXECUTIVES = '/define-executives';
export const SETTINGS_SPECIFIC_ROUTE = '/settings';
