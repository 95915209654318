import { Row, Col } from 'react-bootstrap';
import FindingsTable from '../FindingsTable';
import './styles.scss';
import UserHeader from '../UserHeader';
import UserSecurityHealthCard from '../UserSecurityHealthCard';
import UserLineGraphCard from '../UserLineGraphCard';
import UserTopRiskContributors from '../UserTopRiskContributors';
import { getApiData } from '../../../apis/library';
import { useApiObjectdata } from '../../../hooks/useApiObjectdata';
import { useParams } from 'react-router-dom';
import { getSecurityHealthUrl, getUsersListUrl } from '../../../core-utils/endPoints';
import ErrorHandlerAndLoaderWrapper from '../../../wrappers/ErrorHandlerandLoaderWrapper';
import PageErrorHandlerAndLoaderWrapper from '../../../wrappers/PageErrorHandlerAndLoaderWrapper';
import { useApiData } from '../../../hooks/useApiData';
import { securityHealth } from '../../../core-utils/types/types';

//TODO: Integrate with respective cards
interface metricsProps {
    score: number;
    scoreDelta: string;
    rank: string;
}

interface UserDetailContentProps {
    id: string;
    firstName: string;
    lastName: string;
    department: string;
    position: string;
    startDate: string;
    metrics: metricsProps;
    riskContributors: string;
}

const UserDetailContent = () => {
    const { id: userId } = useParams();
    const { data: userData, error, isLoading, refresh } = useApiObjectdata(
        `${getUsersListUrl}/${userId}`,
        getApiData,
        (dataItem: UserDetailContentProps) => {
            return { ...dataItem };
        },
    );

    const { data: orgData } = useApiData(getSecurityHealthUrl, getApiData, (dataItem: securityHealth) => {
        return { ...dataItem };
    });

    if (!userData) {
        return <p>Error loading user data.</p>;
    }

    const UserHeaderComponent = () => (
        <UserHeader
            userId={userData?.id}
            firstName={userData?.firstName}
            lastName={userData?.lastName}
            department={userData?.department}
            position={userData?.position}
            startDate={userData?.startDate}
        />
    );

    const UserDetailsHeader = () => (
        <div className="user-detail-page__content">
            <div className="user-detail-page__error-container d-flex align-center justify-content-center">
                <ErrorHandlerAndLoaderWrapper
                    error={error}
                    isLoading={isLoading}
                    render={UserHeaderComponent}
                    handleRefresh={refresh}
                />
            </div>
        </div>
    );

    const UserDetailsMetrics = () => (
        <div className="d-grid gap-3 overflow-hidden">
            <Row className="user-detail-page__row">
                <Col md="4" lg="4" xl="4" xxl="4">
                    <UserSecurityHealthCard
                        criticalRisk={userData?.metrics?.score}
                        orgScore={orgData[0]?.value || 0}
                        deptScore={87}
                    />
                </Col>
                <Col md="4" lg="4" xl="4" xxl="4">
                    <UserLineGraphCard
                        series={[
                            {
                                name: 'Edwin',
                                data: [30, 45, 50, 65, 70, 86],
                            },

                            {
                                name: 'Organization',
                                data: [10, 30, 42, 50, 70, 75],
                            },
                            {
                                name: 'Department',
                                data: [10, 30, 40, 62, 75, 45],
                            },
                        ]}
                    />
                </Col>
                <Col md="4" lg="4" xl="4" xxl="4">
                    <UserTopRiskContributors />
                </Col>
            </Row>
        </div>
    );

    const UserDetailContentHelper = () => {
        return (
            <div className="d-flex flex-column gap-3 overflow-hidden">
                <UserDetailsHeader />
                <UserDetailsMetrics />
                <div className="user-detail-page__finding-table-container d-grid bg-text-black">
                    <FindingsTable />
                </div>
            </div>
        );
    };

    return (
        <div className="user-detail-page custom-scrollbar">
            <PageErrorHandlerAndLoaderWrapper
                error={error}
                isLoading={isLoading}
                render={UserDetailContentHelper}
                handleRefresh={refresh}
            />
        </div>
    );
};

export default UserDetailContent;
