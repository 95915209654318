import { useEffect } from 'react';
import useQuery from 'Hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'Rdx/store';

export const AuthRedirectPage = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const dispatch = useDispatch<Dispatch>();

    useEffect(() => {
        const token = query.get('token');

        if (!token) {
            navigate('/signin');
        }

        dispatch.auth.setAuthToken(token);
        fetch(`${process.env.PLATFORM_API_URL}/api/v1/me`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                dispatch.auth.setUser(data);
                navigate('/dashboard/org');
            });
    }, []);

    return <></>;
};

export default AuthRedirectPage;
