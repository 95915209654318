import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Typography from '../../atoms/Typography';
import { Color } from '../../../core-utils/types/color';
import { TypographyVariant } from '../../../core-utils/types/Variant';
import './styles.scss';

interface IconWithTypographyProps {
    isRow: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    label: string;
    labelColor: Color;
    labelVariant?: TypographyVariant;
}

const IconWithTypography = (props: IconWithTypographyProps) => {
    const { isRow, startIcon, endIcon, label, labelColor, labelVariant = 'subtitle1' } = props;

    return (
        <div className="icon-with-typography">
            {isRow ? (
                <div className="icon-with-typography__row-container" data-testid="row-alignment">
                    {startIcon}
                    <Row>
                        <Col>
                            <Typography variant={labelVariant} children={label} color={labelColor} />
                        </Col>
                    </Row>
                    {endIcon}
                </div>
            ) : (
                <div className="icon-with-typography__col-container" data-testid="column-alignment">
                    <Row>{startIcon}</Row>
                    <Row>
                        <Col xs={12}>
                            <Typography variant="body1" children={label} color={labelColor} />
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
};

export default IconWithTypography;
