import { CgArrowsExpandRight } from 'react-icons/cg';
import { useState } from 'react';
import CustomCard from '../../molecules/CustomCard';
import HealthMetrics from '../../molecules/HealthMetrics';
import IconWithTypography from '../../molecules/IconWithTypography';
import IconWithTypograhy from '../../molecules/IconWithTypography';
import { HiOutlineTrendingUp, HiOutlineTrendingDown } from 'react-icons/hi';
import {
    PHISHING,
    SECURITY_TRAINING_DEFICIENCIES,
    TRAINING,
    TrainingDeficiencyLabelMap,
} from '../../../core-utils/constants/constants';
import { getApiData } from '../../../apis/library';
import { getTrainingDeficienciesUrl } from '../../../core-utils/endPoints';
import { useApiData } from '../../../hooks/useApiData';
import ErrorHandlerAndLoaderWrapper from '../../../wrappers/ErrorHandlerandLoaderWrapper';
import { Color } from '../../../core-utils/types/color';
import './styles.scss';
import DataGridModal from '../DataGridModal';

interface SecurityTrainingDeficiencyData {
    label: string;
    value: number;
    delta: string;
}

const SecurityTrainingDeficiencies = () => {
    const [show, setShow] = useState(false);
    const { data, error, isLoading, refresh } = useApiData(
        getTrainingDeficienciesUrl,
        getApiData,
        (dataItem: SecurityTrainingDeficiencyData) => {
            const updatedLabel = TrainingDeficiencyLabelMap[dataItem.label] || dataItem.label;
            return { ...dataItem, label: updatedLabel };
        },
    );

    // The mock API returns 4 records, but we only need the first 2 records
    const responseData = data.filter((item) => item.label === PHISHING || item.label === TRAINING);

    const buildHealthMetric = (healthMetric: SecurityTrainingDeficiencyData, index: number) => {
        const isNegativeDelta = healthMetric?.delta?.toString().startsWith('-');
        const valueIcon = isNegativeDelta ? (
            <HiOutlineTrendingDown className="security-coverage-tooling__icon-color-red" />
        ) : (
            <HiOutlineTrendingUp className="security-coverage-tooling__icon-color-green" />
        );
        const labelColor: Color = 'primary-100';
        const label =
            healthMetric.value === null
                ? '-'
                : index === 0 || index === 1
                ? healthMetric.value.toString()
                : healthMetric.value.toString() + ' Days';
        const value = (
            <IconWithTypograhy
                isRow={true}
                startIcon={valueIcon}
                label="10%"
                labelColor={isNegativeDelta ? 'secondary-accent-red-2' : 'secondary-accent-green-2'}
            />
        );
        const header = <IconWithTypograhy isRow={false} label={healthMetric.label} labelColor="text-medium-emphasis" />;
        return { header, label, labelColor, value };
    };
    const handleClose = () => {
        setShow(false);
    };
    const handleClick = () => {
        setShow(!show);
    };
    const SecurityMetrics = () => {
        return (
            <div className="security-coverage-tooling__styled-container container">
                {responseData.map((healthMetric: SecurityTrainingDeficiencyData, index: number) => {
                    const metric = buildHealthMetric(healthMetric, index);
                    return (
                        <div key={index} data-testid="security-testing-coverage">
                            <HealthMetrics metric={metric} />
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="security-coverage-tooling h-100" data-testid="security-testid">
            <CustomCard
                cardType="oneThird"
                title={
                    <>
                        <IconWithTypography isRow={true} label={SECURITY_TRAINING_DEFICIENCIES} labelColor="primary" />
                        <CgArrowsExpandRight
                            size={22}
                            className="security-coverage-tooling__icon"
                            onClick={handleClick}
                        />
                    </>
                }
                content={
                    <div className="security-coverage-tooling__error-container">
                        <ErrorHandlerAndLoaderWrapper
                            error={error}
                            isLoading={isLoading}
                            render={SecurityMetrics}
                            handleRefresh={refresh}
                        />
                    </div>
                }
            />
            <DataGridModal title={SECURITY_TRAINING_DEFICIENCIES} handleClose={handleClose} show={show} />
        </div>
    );
};

export default SecurityTrainingDeficiencies;
